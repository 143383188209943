import React, { useState } from "react"
import DJP from "../../Form/DJP"



const Manuscript = () =>{
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
  return (
    setShowModal (false)
  )
}
    return(
        <>
            
              <section className="mb-5">
        <div className="container">
          <h1 className="journal-papers">CALL FOR PAPERS:</h1>
          <p className="journal-para">All submissions undergo a rigorous peer review process conducted by experts in the relevant field. Our commitment to academic excellence ensures that only manuscripts of the highest quality are selected for publication. Authors receive constructive feedback from reviewers to help strengthen their work and contribute to the advancement of scholarship in literature and fiction.

          </p>
          <p className="journal-para">Keep up to date with the latest news, articles, and events from DfreeNovelish Journal
            Publication (DJP) by subscribing to our newsletter and following us on social media. Join our community of
            scholars and enthusiasts as we explore the vast and intricate world of literature together.
          </p>
          <button className="whitebutton" onClick={() => setShowModal(true)}>Submit your paper</button>             
                       {showModal && <DJP closeModal={closeModal}/>}
        </div>

      </section>
        </>
    )
}
export default Manuscript;