import React from "react"
import Back from "../comon/back/Back"
import BlogCard from "./JournalCard"
import "./journal.css"
import Image from "../../../privateImages/journal/why-us-website-design.png"
import { FaItalic, FaWhatsapp } from "react-icons/fa"
import CompanyList from "./CompanyList"

import JournalTabs from "./JournalTabs"
import { Link } from "react-router-dom"
import { CTabContent, CTabList, CTabs, CTab, CTabPanel } from '@coreui/react';
import Manuscript from "./Manuscript"
import JournalResearch from "./JournalResearch"
import Guide from "./Guide"
import Editorial from "./Editorial"
import CurrentIssue from "./CurrentIssue/CurrentIsuue"






const Journal = () => {
 

  return (
    <>
       


    

      <section className='back'>
        <h1 className="research-head
        ">Journal Publication</h1>

      </section>
      <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin'></div>
      <CTabs activeItemKey={1} className="mx-5 ">
        <CTabList variant="tabs" layout="fill">
          <CTab aria-controls="home-tab-pane" className="text-primary" itemKey={1}>Home</CTab>
          <CTab aria-controls="submit-manuscript" className="text-primary" itemKey={2}>Submit Manuscript</CTab>
          <CTab aria-controls="Call-for-Research" className="text-primary" itemKey={3}>Call for Research</CTab>
          <CTab aria-controls="Editorial-Board" className="text-primary" itemKey={4}>Editorial Board</CTab>
          <CTab aria-controls="Current-Isssue" className="text-primary" itemKey={5}>Current Isssue</CTab>
          <CTab aria-controls="Guide-to-Author" className="text-primary" itemKey={6}>Guide to Author</CTab>
        </CTabList>
        <CTabContent>
          <CTabPanel className="py-3" aria-labelledby="home-tab-pane" itemKey={1}>


            <section className='blog padding'>
              {/* ---------------------------Welcome------------------------------ */}
              <div className="container">
                {/* <JournalTabs/> */}
                <h1 className="journal-head">WELCOME TO DFREENOVELISH JOURNAL PUBLICATION</h1>
                <p className="journal-para">At DfreeNovelish, we are dedicated to fostering scholarly discourse and advancing knowledge in the field of literature and fiction. Our journal publication serves as a
                  platform for academics, researchers, and enthusiasts to share their insights, discoveries, and creative endeavors. DfreeNovelish Journal Publications (DJP) is a quality
                  publication of peer-reviewed and refereed international journals from diverse fields in sciences, engineering and technologies, Business, and many more that emphasize new research,
                  development, and their applications.</p>

                <p className="journal-para">  DfreeNovelish Journal Publication (DJP) is a leading academic journal that publishes original related to literature, fiction, and storytelling. Founded with the vision of promoting innovative thinking and interdisciplinary dialogue, we strive to provide a dynamic space for intellectual exchange and exploration.</p>

                <p className="journal-para">  Our mission is to facilitate the dissemination of high-quality research and literary contributions that contribute to the understanding and appreciation of literature in all its forms. We aim to support emerging scholars while also engaging established academics in meaningful discussions that push the boundaries of literary studies.</p>
                {/* <button className="localbutton">Contact Us </button> */}
              </div>
            </section>

            {/* <div className='container grid2'>
          <BlogCard />
        </div> */}
            {/* ----------------------------Why Choose-------------------------------------- */}
            <section>
              <div className="why-choose">
                <img src={Image} className="why-choose-img" />
              </div>

            </section>

            {/* ------------------------------------------What we Publish------------------------------ */}
            <section className="mt-5 mb-5">
              <div className="container journal-publish-container">
                <h1 className="journal-what-publish-head">What We Publish?</h1>
                <p className="journal-what-publish-para"><span className="span-item">DfreeNovelish</span><span className="text-light"> welcomes submissions across a broad spectrum of topics within the realm of literature and fiction, including but not limited to:</span> </p>
                <div className="journal-publish-card container">
                  <div className="row">
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h4>Research Papers</h4>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Comparative literature</h3>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Narrative theory</h3>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Submission Guidelines</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Book reviews and author interviews</h3>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Literary analysis and criticism</h3>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Genre studies (e.g., fantasy, e.t.c)</h3>
                      </div>
                    </div>
                    <div className="col-sm-3 mt-1">
                      <div className="journal-childCard">
                        <h3>Creative writing (short stories, e.t.c)</h3>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="container mt-3">
                  <p className="journal-what-publish-para"><span className="span-item-white">Authors interested in contributing to</span> <span className="span-item">DfreeNovelish Journal Publication (DJP)</span>
                    <span className="span-item-white"> are invited to review our submission guidelines for detailed instructions on formatting, citation style, and manuscript preparation. We welcome submissions
                      from scholars at all stages of their academic careers and encourage diversity in perspectives and methodologies.</span>
                  </p>
                </div>
              </div>

            </section>



            {/* ---------------------------------------CALL FOR PAPERS----------------------------------- */}

            <section className="mt-5">
              <CompanyList />
            </section>



            {/* ---------------------------------------------------Our Detail--------------------------------------- */}

            <section>
              <div className="container-fluid journal-detail">
                <h1 className="journal-detail-head">Our Details</h1>
                <h3 className="journal-detail-para">DfreeNovelish Journal Publication</h3>

                <h3 className="journal-detail-para">Email: info@dfreenovelish.com</h3>

                <h3 className="journal-detail-para">Phone: +91-7302344754, +917618162404</h3>
                <a href="tel:+91 7302344754"><button className="whitebutton">Contact Now</button></a>
              </div>
            </section>
          </CTabPanel>
          <CTabPanel className="py-3" aria-labelledby="submit-manuscript" itemKey={2}>
            <Manuscript />
          </CTabPanel>
          <CTabPanel className="py-3" aria-labelledby="Call-for-Research" itemKey={3}>
            <JournalResearch />
          </CTabPanel>
          <CTabPanel className="py-3" aria-labelledby="Editorial-Board" itemKey={4}>
            <Editorial />
          </CTabPanel>
          <CTabPanel className="py-3" aria-labelledby="Current-Isssue" itemKey={5}>
            <CurrentIssue />
          </CTabPanel>

          <CTabPanel className="py-3" aria-labelledby="Guide-to-Author" itemKey={6}>
            <Guide />
          </CTabPanel>

        </CTabContent>
      </CTabs>
  
    </>
  )
}

export default Journal
