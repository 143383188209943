import React from 'react';
import "../AWS/service.css"; 
import Marquee from "react-fast-marquee";


  const ContentList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Website Content &nbsp;&nbsp;&nbsp;&nbsp;
• Blog Posts &nbsp;&nbsp;&nbsp;&nbsp;
• Articles &nbsp;&nbsp;&nbsp;&nbsp;
• Marketing Copy &nbsp;&nbsp;&nbsp;&nbsp;
• Social Media Content &nbsp;&nbsp;&nbsp;&nbsp;
• Email Newsletters &nbsp;&nbsp;&nbsp;&nbsp;
• Website Content &nbsp;&nbsp;&nbsp;&nbsp;
• Blog Posts &nbsp;&nbsp;&nbsp;&nbsp;
• Articles &nbsp;&nbsp;&nbsp;&nbsp;
• Marketing Copy &nbsp;&nbsp;&nbsp;&nbsp;
          </Marquee>
        </div>
    
    );
  }


export default ContentList;