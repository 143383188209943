import React from "react"
import Logo from "../../../../privateImages/logo.png"
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'> 
          <div className='logo row gx-5'>
          <div className="col-sm-6">
                 <Link to="/"> <img src={Logo} alt="logo" className="mb-2" style={{ height: '70%', width: "60%" }} /></Link>
                  <h6 className="mt-0">Illuminating Minds, Nurturing Future</h6>
                </div>
         

          </div>
          <div className="mt-0 col-sm-6">
            <Link to="/bookpage"> <button className="mt-2 whitebutton book-button">Books</button></Link>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default Head;
