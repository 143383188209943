import React, { useState } from "react";
// import "./service.css"
import "../AWS/service.css"
import Back from "../../comon/back/Back"
import BookList from "./BookList";
import AwsImage from "../../../../privateImages/service-file/book.png"


import MyModal from "../../../Form/SoForm";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";



// import AboutList from './AboutList';


const Book = () => {
 

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
      
            <section className='back'>
            <h1 className="mt-5 text-center">Book Writing and Publication</h1>
      </section>
      <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
            <div className='margin'></div>
            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to our Book Writing and Publication Service</h1>
                    <p className="awspara">We turn your literary dreams into reality. Whether you're an aspiring author with a story to tell or a seasoned writer seeking to publish your next masterpiece, our comprehensive service offers support at every stage of the book writing and publication process.</p>
                </div>
                <div className="assist">
                    <div>
                        <img src={AwsImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Book Writing and Publication Service provides comprehensive support to authors, guiding them through every stage of the writing and publishing process. From concept development to final publication, we ensure your book achieves its full potential and reaches its intended audience effectively.</p>
                    </div>

                </div>
                <div>
                    <h1 className="awssets">What Sets Us Apart</h1>
                    <div className="container awspart">
                        <div className="row">
                        <div className="col-sm-3 awsapartcard">
                            <h3 className="awsaparthead">Professional Guidance</h3>
                            <p className="apartpara">Benefit from our experienced publishing team's expertise, ensuring your book receives the attention, guidance, and support it rightfully deserves.
                            </p>
                        </div>
                        <div className="col-sm-3 awsapartcard">
                            <h3 className="awsaparthead">Tailored Solutions</h3>
                            <p className="apartpara">Receive personalized support for your book, ensuring your unique vision is brought to life with tailored assistance from our dedicated team of experts.</p>
                        </div>
                        <div className="col-sm-3 awsapartcard">
                            <h3 className="awsaparthead">Streamlined Process</h3>
                            <p className="apartpara">Save time and effort by entrusting the book writing and publication process to our dedicated team, allowing you to focus on writing and creativity.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Access to Resources</h3>
                                <p className="apartpara">Gain access to valuable resources, connections, and industry insights to help you navigate the publishing landscape and achieve success as an author.
                                </p>
                                </div>
                        </div>
                    </div>
                    <div>
                        <BookList />
                    </div>
                    <div>
                        <h1 className="awssets">Get Started Today</h1>
                        <p className="awspara">Ready to bring your book to life and share your story with the world? Contact us today to
                         learn more about our Book Writing and Publication Service and take the first step toward becoming a published author.</p>
                         <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}
                           </div>
                </div>
                </div>
             
            </>
            )
}
            export default Book;