import React from 'react';

import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';




function PrivacyPolicy() {


  return (
    
    <section>
       


   
<section className='back'>
<h1>Privacy & Policy</h1>
</section>
<Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
<div className='margin'></div>
    <div className="container text-light">
      <h1>Privacy Policy</h1>
      <p>DfreeNovelish (opc) private limited respects your privacy, and we are committed to protecting it. We offer this privacy policy to inform you of the ways we do this in reference to your interaction with this Website.</p>

      <h2>Refund Policy</h2>
      <p>Our company does not offer refunds for any projects unless the project fails to meet the initial requirements. Refunds will only be considered if the final deliverable does not align with the agreed-upon specifications.</p>

      <h2>Site covered by Website</h2>
      <p>This privacy policy only applies to the web site and not to any linked third-party sites. to find out about the privacy policy of a linked site, please ask the privacy statement of that site...</p>

      <h2>Visiting our website:</h2>
      <p>You can visit our website without giving us any personal information. Some pages on this internet site employ cookies that it transfers to your computer's hard disc to permit the server to recollect information about your session while you're connected. it's possible for you to detect and delete cookies or set your browser to reject or block cookies.</p>

      <h2>Kinds of Information we collect</h2>
      <h3>Personal Information:</h3>
      <p>Any personal information (this could include your name, address, email address, phone number, education and professional background) provided on this website is on a voluntary basis. Our website has reasonable security safeguards to shield the confidentiality of any Personal Information you've got furnished to us.</p>

      <h3>Site Visitor Data Sheet:</h3>
      <p>We can also collect Site Visitor ship data that doesn't contain personal information. This is often done to gather statistics on the number of tourists to the location and analyze how our website is employed to enhance it. Aggregated data from such analysis could also be shared with advertisers on our site.</p>

      <h2>Disclosure Information:</h2>
      <p>We may share the personal Information with Affiliates and with third parties including Agents and vendors that need the information obtained from us to service you. We will not disclose without your consent, any personal information we collect about you to unaffiliated third parties unless if it's necessary to suits applicable legal and regulatory processes or to protect the interests of DfreeNovelish (opc) private limited, its Affiliates, or its members and constituents.</p>

      <h2>Effective Data</h2>
      <p>This Privacy policy is effective as of 30th August 2023. We reserve the proper to change this policy. Any change to the policy is going to be posted on this page and will be effective as of the day they are posted.</p>
    </div>

    </section>
    
  );
}

export default PrivacyPolicy;