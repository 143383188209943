import React from "react";
import "./gallery.css";
import Image1 from "../../../privateImages/Gallery/d-1.jpg"
import Image2 from "../../../privateImages/Gallery/d-2.jpg"
import Image3 from "../../../privateImages/Gallery/d-3.jpg"
import Image4 from "../../../privateImages/Gallery/d-4.jpg"
import Image5 from "../../../privateImages/Gallery/d-5.jpg"
import Image6 from "../../../privateImages/Gallery/d-6.jpg"
import Image7 from "../../../privateImages/Gallery/d-7.jpg"
import Back from "../comon/back/Back";

import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";



const Gallery = () => {
    return (
        <>
            <section className='text-center text-light container mt-5 '>
           
                <h1 style={{fontSize:"50px"}} className="mb-3">Discover Our Creative Gallery</h1>
                <h4 style={{fontSize:"30px"}}>Welcome to our gallery, showcasing moments of inspiration and success from our events and conferences. Here, you'll find snapshots of engaging educational workshops, impactful keynote speeches, dynamic networking sessions, and our innovative hybrid events. Each photo captures the essence of learning, connection, and growth that defines DfreeNovelish.</h4>
                
                 </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    

            <div className='margin' style={{marginTop:"10%"}}></div>

            <div className="gallery  mt-5">


                <div class="scope mt-5">
                    <span className="i1"><img src={Image1} /></span>
                    <span className="i2"><img src={Image2} /></span>
                    <span className="i3"><img src={Image3} /></span>
                    <span className="i4"><img src={Image4} /></span>
                    <span className="i5"><img src={Image5} /></span>
                    <span className="i6"><img src={Image6} /></span>
                    <span className="i7"><img src={Image7} /></span>
                    <span className="i8"><img src={Image7} /></span>
                    {/* <span className="i9"><img src={Image7} /></span> */}
                </div>
                {/* <img src={Image7}/> */}
            </div>
        </>
    )
}




export default Gallery;