import React, { useState } from "react";
import "../AWS/service.css"
import Back from "../../comon/back/Back"
// import AwSList from "./AWSList";
import ResumeImage from "../../../../privateImages/service-file/resume.png"
import ResumeList from "./ResumeList";
import "./resume.css"
import MyModal from "../../../Form/SoForm";


import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


// import AboutList from './AboutList';


const Resume = () => {


    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }

    return (
        <>
     



          
            <section className='back'>
            <h1 className="mt-5 text-center">Resume Writing</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>
            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to Resume Writing Services</h1>
                    <p className="awspara">We specialize in crafting professional resumes that elevate your career prospects. Your resume is often the first impression you make on a potential employer, and it's essential to ensure it effectively showcases your skills, experiences, and accomplishments.
                     Our team of expert writers is here to help you stand out from the crowd and land the job you deserve.</p>
                </div>
                <div className="assist mb-5">
                <div>
                    <img src={ResumeImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%"/>
                </div>
                <div className="awsassistpara ">
                    
                    <h1 className="awsassist">How We Can Assist You</h1>
                    <p>At DfreeNovelish, we believe in a personalized approach to resume writing. We take the time to understand your
                         unique career goals, strengths, and experiences to create a customized resume that aligns with your aspirations</p>
                </div>
               
                </div>
          
                <div className="mt-5">
<h1 className="awssets ">What Sets Us Apart</h1>
<div className="container ">
<div className="row">
    <div className="col-sm-3 awsapartcard" >
        <h3 className="awsaparthead">Experienced Team</h3>
        <p className="apartpara">Our team consists of skilled writers with expertise in various industries and career levels.
        </p>
    </div>
    <div className="col-sm-3 awsapartcard">
        <h3 className="awsaparthead">Quality Assurance</h3>
        <p className="apartpara">We guarantee top-quality, accurate resumes that meet the highest standards.</p>
    </div>
    <div className="col-sm-3 awsapartcard">
        <h3 className="awsaparthead">Timely Delivery</h3>
        <p className="apartpara">We prioritize deadlines, consistently ensuring delivering your resume promptly as agreed.</p>
    </div>
    <div className="col-sm-3 awsapartcard">
        <h3 className="awsaparthead">Client Satisfaction</h3>
        <p className="apartpara">Our ultimate goal is your satisfaction and success in achieving your career objectives.</p>
                
    </div>
</div>
</div>
</div>

                <div>
                 <ResumeList/>
                </div>
                <div>
                    <h1 className="awssets">Get Started Today</h1>
                    <p className="awspara">Ready to take your career to the next level? Contact us today to learn more
                     about our resume writing services and get started on creating a resume that opens doors to exciting new opportunities.</p>
                     <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}
               </div>
            </div>
          
        </>
    )
}
export default Resume;


