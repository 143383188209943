// import React from "react";
// import "./form.css";
// import { useEffect } from "react";

// const DJP = ({ closeModal }) => {
//     useEffect(() => {
//         document.body.style.overflow = "hidden";
//         return () => {
//             document.body.style.overflow = "scroll";
//         }
//     }, []);
//     return (
//         <>
//             <div className="modal-wrapper" onClick={closeModal}></div>
//             <div className="modal-container">
//                 <form>
//                     <div className="row">
//                     <h5>Author Name</h5>
//                         <div className="col-sm-6">

//                             <label>First Name</label>
//                             <input type="text" />
//                         </div>
//                         <div className="col-sm-6">
//                             <label>Last Name</label>
//                             <input type="text"></input>
//                         </div>
//                     </div>
//                     <div className="row">  
//                         <h5>Author Detail</h5>
//                     <textarea placeholder="author detail"/>

//                     </div>
//                     <div className="row">
//                         <div className="col-sm-6">
//                             <label>Phone</label>
//                             <input type="number" className="py-2" />
//                         </div>
//                         <div className="col-sm-6">
//                             <label>Subject</label>
//                             <input type="text" />
//                         </div>
//                     </div>

//                 </form>
//                 <button className="modal-btn" onClick={closeModal}>Send</button>

//             </div>
//         </>
//     )
// }
// export default DJP;










// import React, { useState } from 'react';

// const DJP= () => {
//   const [authors, setAuthors] = useState([{ name: '', coAuthors: '', phone: '', email: '', keywords: '', comments: '' }]);
//   const [manuscript, setManuscript] = useState(null);

//   const handleAddAuthor = () => {
//     setAuthors([...authors, { name: '', coAuthors: '', phone: '', email: '', keywords: '', comments: '' }]);
//   };

//   const handleAuthorChange = (index, event) => {
//     const values = [...authors];
//     values[index][event.target.name] = event.target.value;
//     setAuthors(values);
//   };

//   const handleManuscriptChange = (event) => {
//     setManuscript(event.target.files[0]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <form onSubmit={handleSubmit} className='text-light'>
//       <label htmlFor="authorName">Author Name<span style={{ color: 'red' }}>*</span></label>
//       <div className='row'>
//         <div className='col-sm-6'>
//       <input type="text" id="authorName" name="name" placeholder='First Name' required />
//       </div>
//       <div className='col-sm-6'>
//       <input type="text" id="authorName" name="name" placeholder='Last Name' required />
//       </div>
//       </div>

//       {authors.map((author, index) => (
//         <div key={index}>
//           < label htmlFor={`coAuthor${index}`}>Co-Author</label>
//           <div className='row'>
//             <div className='col-sm-11'>
//             <input type="text" id={`coAuthor${index}`} name={`coAuthors[${index}]`} onChange={(event) => handleAuthorChange(index, event)} placeholder='co-author' />
//             </div>
//             <div className='col-sm-1'>
//             <button type="button" onClick={handleAddAuthor}>+</button>
//                 </div>
//           </div>

//    <div className='row'>
//     <div className='col-sm-6'>

//     <label htmlFor={`phone${index}`}>Phone<span style={{ color: 'red' }}>*</span></label>
//           <input type="tel" id={`phone${index}`} name={`phone[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} />

//     </div>
//     <div className='col-sm-6'>
//     <label htmlFor={`email${index}`}>Email<span style={{ color: 'red' }}>*</span></label>
//           <input type="email" id={`email${index}`} name={`email[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} />

//     </div>
//    </div>


//           <label htmlFor={`keywords${index}`}>Keywords<span style={{ color: 'red' }}>*</span></label>
//           <input type="text" id={`keywords${index}`} name={`keywords[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} />

//           <label htmlFor={`comments${index}`}>Your Comments/Questions<span style={{ color: 'red' }}>*</span></label>
//           <textarea id={`comments${index}`} name={`comments[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} />
//         </div>
//       ))}

//       <label htmlFor="manuscript">Attach Manuscript<span style={{ color: 'red' }}>*</span></label>
//       <input type="file" id="manuscript" name="manuscript" required onChange={handleManuscriptChange} />

//       <button type="submit">Add</button>
//     </form>
//   );
// };

// export default DJP;









import React, { useState } from 'react';

const DJP = () => {
    const [authors, setAuthors] = useState([{ name: '', coAuthors: [{ name: '' }], phone: '', email: '', keywords: '', comments: '' }]);
    const [manuscript, setManuscript] = useState(null);

    const handleAddCoAuthor = (index) => {
        const newCoAuthor = { name: '' };
        const updatedAuthors = [...authors];
        updatedAuthors[index].coAuthors.push(newCoAuthor);
        setAuthors(updatedAuthors);
    };

    const handleRemoveCoAuthor = (authorIndex, coAuthorIndex) => {
        const updatedAuthors = [...authors];
        updatedAuthors[authorIndex].coAuthors.splice(coAuthorIndex, 1);
        setAuthors(updatedAuthors);
    };

    const handleAuthorChange = (index, event) => {
        const values = [...authors];
        values[index][event.target.name] = event.target.value;
        setAuthors(values);
    };

    const handleCoAuthorChange = (authorIndex, coAuthorIndex, event) => {
        const values = [...authors];
        values[authorIndex].coAuthors[coAuthorIndex][event.target.name] = event.target.value;
        setAuthors(values);
    };

    const handleManuscriptChange = (event) => {
        setManuscript(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
    };

    return (
        <form onSubmit={handleSubmit} className='text-light'>
            <label htmlFor="authorName">Author Name<span style={{ color: 'red' }}>*</span></label>
            <div className='row'>
                <div className='col-sm-6'>
                    <input type="text" id="authorName" name="name" placeholder='First Name' required onChange={(event) => handleAuthorChange(0, event)} />
                </div>
                <div className='col-sm-6'>
                    <input type="text" id="authorName" name="name" placeholder='Last Name' required onChange={(event) => handleAuthorChange(0, event)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12'>

                
            <label htmlFor="authorName">Author Detail<span style={{ color: 'red' }}>*</span></label>
            <input type="text" id="authorName" name="name" placeholder='Author Deatil' required onChange={(event) => handleAuthorChange(0, event)} />
            </div>
            </div>
            {authors.map((author, index) => (
                
                <div key={index}>
                                        
          {author.coAuthors.map((coAuthor, coAuthorIndex) => (
  <div key={coAuthorIndex}>
    <label htmlFor={`coAuthor${coAuthorIndex}`}>Name of Co-Author</label>
    <div className='row'>
      <div className='col-sm-11'>
        <input type="text" id={`coAuthor${coAuthorIndex}`} name={`coAuthors[${coAuthorIndex}]`} onChange={(event) => handleCoAuthorChange(index, coAuthorIndex, event)} placeholder='Name of Co-Author' />
      </div>
      <div className='col-sm-1'>
        {coAuthorIndex < author.coAuthors.length - 1? (
          <button type="button" onClick={() => handleRemoveCoAuthor(index, coAuthorIndex)} className=' px-3 fs-4 '>-</button>
        ) : (
          author.coAuthors.length < 5? (
            <button type="button" onClick={() => handleAddCoAuthor(index)} className=' px-3 fs-4 '>+</button>
          ) : (
            <span>Max 5 co-authors reached</span>
          )
        )}
      </div>
    </div>
  </div>
))}
  <div className='row'>
                <div className='col-sm-12'>

                
            <label htmlFor="authorName">Co-Author Detail</label>
            <input type="text" id="authorName" name="name" placeholder='Co-Author Deatil' required onChange={(event) => handleAuthorChange(0, event)} />
            </div>
            </div>
                    <label htmlFor={`phone${index}`}>Phone<span style={{ color: 'red' }}>*</span></label>
                    <input type="tel" id={`phone${index}`} name={`phone[${index}]`} required onChange={(event) => handleAuthorChange(index, event)}  placeholder='Phone No.' />

                    <label htmlFor={`email${index}`}>Email<span style={{ color: 'red' }}>*</span></label>
                    <input type="email" id={`email${index}`} name={`email[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} placeholder='Email Id' />

                    <label htmlFor={`keywords${index}`}>Keywords<span style={{ color: 'red' }}>*</span></label>
                    <input type="text" id={`keywords${index}`} name={`keywords[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} placeholder='Keywords' />

                    <label htmlFor={`comments${index}`}>Your Comments/Questions<span style={{ color: 'red' }}>*</span></label><br/>
                    <textarea id={`comments${index}`} name={`comments[${index}]`} required onChange={(event) => handleAuthorChange(index, event)} cols="20" className=' px-5' placeholder='Enter some comment here....' />


                </div>
            ))}

            <label htmlFor="manuscript">Attach Manuscript<span style={{ color: 'red' }}>*</span></label>
            <input type="file" id="manuscript" name="manuscript" required onChange={handleManuscriptChange} />
<br/>
            <button type="submit" className='mt-2'>Submit</button>
        </form>
    );
};

export default DJP;