import React from 'react';
import '../AWS/service.css';
import Marquee from "react-fast-marquee";


const SmmList = () => {
  return (
    <div className="flatlist-container awsassistpara">
      <h1 className='AWSListHead'>Our Range of Services</h1>
      <Marquee>
        •   Social Media Strategy Development&nbsp;&nbsp;&nbsp;&nbsp;
  • Content Creation and Management&nbsp;&nbsp;&nbsp;&nbsp;
  • Social Media Advertising&nbsp;&nbsp;&nbsp;&nbsp;
  • Community Engagement and Management&nbsp;&nbsp;&nbsp;&nbsp;
  • Performance Analytics and Reporting&nbsp;&nbsp;&nbsp;&nbsp;
      </Marquee>
    </div>

  );
}


export default SmmList;