import React, { useState } from "react";
import "../Research_Writting/research.css"
import Back from "../../comon/back/Back"
import ContentList from "./ContentList";
import ResearchImage from "../../../../privateImages/service-file/aws.png"
import MyModal from "../../../Form/SoForm";

import { Link } from "react-router-dom";

import { FaWhatsapp } from "react-icons/fa";





const Content = () => {


    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
      


          
            <section className=' back'>
            <h1 className="mt-5 text-center">Content Writing </h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
            <div className='margin'></div>
            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to our Content Writing Service</h1>
                    <p className="awspara">We specialize in crafting compelling and engaging content that captivates audiences and drives results. Whether you need website
                        content, blog posts, articles, or marketing copy, our team of experienced writers is here to help you communicate your message effectively and achieve your content goals.</p>
                </div>
                <div className="assist">

                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Content Writing Service offers tailored solutions for diverse needs, delivering high-quality content across various formats and industries. From web content to blog posts, articles, and more, we provide engaging and informative writing that resonates with your audience and achieves your communication goals effectively.</p>
                    </div>
                    <div>
                        <img src={ResearchImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                </div>



                {/* ----------------------------------------------------What Sets Us Apar--------------------------------------- */}
                <section>
                    <div>
                        <h1 className="awssets mb-5">What Sets Us Apart</h1>
                        <div className="researchcards">

                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Quality Content</h3>
                                    <p> Receive high-quality content that is well-researched, well-written, and tailored to your specific needs and objectives.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Expertise</h3>
                                    <p>Benefit from the expertise of our team of experienced writers who are skilled in crafting content for a wide range of industries and niches.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Consistency</h3>
                                    <p>Maintain a consistent brand voice and messaging across all your content channels, helping to reinforce your brand identity and build trust with your audience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="researchcards secondresearch">

                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Results-Driven</h3>
                                    <p>Achieve your content goals and objectives, whether it's increasing website traffic, generating leads, or boosting sales, with our results-driven approach to content writing.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Time Savings</h3>
                                    <p>Save time and resources by outsourcing your content writing needs to our team, allowing you to focus on other aspects of your business.
                                    </p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>24/7 Support</h3>
                                    <p>Our support team is available 24/7 to assist with orders, writer communication, and research progress tracking, ensuring all needs are met.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* -----------------------------------------------What Sets Us Apar ENDED----------------------------------- */}
                <div>
                    <ContentList />
                </div>
                <div>
                    <h1 className="awssets">Get Started Today</h1>
                    <p className="awspara">Ready to elevate your content and take your business to the next level? Contact us today to learn more about our Content Writing Service and discuss how we can help you achieve your content goals..</p>
                    <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}
            </div>
            </div>
         
        </>
    )
}
export default Content;