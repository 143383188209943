import React from 'react';
import '../AWS/service.css';
import Marquee from "react-fast-marquee";


const ResumeList = () => {
  return (
    <div className="flatlist-container awsassistpara">
      <h1 className='AWSListHead'>Our Range of Services</h1>
      <Marquee>
        •   Professional Resume Writing &nbsp;&nbsp;&nbsp;&nbsp;
        • Executive Resume Writing &nbsp;&nbsp;&nbsp;&nbsp;
        • Entry-Level Resume Writing &nbsp;&nbsp;&nbsp;&nbsp;
        • Career Change Resume Writing &nbsp;&nbsp;&nbsp;&nbsp;
        • Industry-Specific Resume Writing (e.g., IT, Finance, Healthcare, Marketing, etc.) &nbsp;&nbsp;&nbsp;&nbsp;
        • Cover Letter Writing &nbsp;&nbsp;&nbsp;&nbsp;
        • LinkedIn Profile Optimization &nbsp;&nbsp;&nbsp;&nbsp;
      </Marquee>
    </div>

  );
}


export default ResumeList;