import React from 'react';
import "../AWS/service.css"; 
import Marquee from "react-fast-marquee";


  const ResearchWrittingList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Subject Coverage&nbsp;&nbsp;&nbsp;&nbsp;
• Skill Development&nbsp;&nbsp;&nbsp;&nbsp;
• Test Preparation&nbsp;&nbsp;&nbsp;&nbsp;
• Professional Development&nbsp;&nbsp;&nbsp;&nbsp;
• Customized Learning Paths&nbsp;&nbsp;&nbsp;&nbsp;
• Subject Coverage&nbsp;&nbsp;&nbsp;&nbsp;
• Skill Development&nbsp;&nbsp;&nbsp;&nbsp;
• Test Preparation&nbsp;&nbsp;&nbsp;&nbsp;

          </Marquee>
        </div>
    
    );
  }


export default ResearchWrittingList;