import React, { useState } from "react"
import Heading from "../comon/heading/Heading"
import "./home.css"
import { Link } from "react-router-dom"
import MyModal from "../../Form/SoForm"

const Homes = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    return (
      setShowModal(false)
    )
  }
  return (
    <>

      <section>
        <div className='container'>
          <div className='row mt-5 mb-0'>
            <Heading subtitle='WELCOME TO DFREENOVELISH' title='Unlock, Elevate, Ignite: Academic & Digital Mastery' />
            <p className="text-light text-center">Empowering Your Academic and Professional Success with Comprehensive Web and Academic Services.</p>
            <div className='button text-center'>

              <button className='primary-btn localbutton text-light '   onClick={() => setShowModal(true)}>
                GET STARTED NOW
              </button>          {showModal && <MyModal closeModal={closeModal} />}

              <button className="whitebutton">
                <Link to="./services">
                  VIEW SERVICES <i className='fa fa-long-arrow-alt-right'></i>
                </Link>

              </button>
            </div>
          </div>
        </div>
      </section>
      <div className='home-margin'></div>
    </>
  )
}

export default Homes;