import React, { useState } from "react";
import MyModal from "./SoForm";
import "./form.css";

const MainForm = () => {
	const [showModal, setShowModal] = useState(false);
	const closeModal = () => {
		return (
			setShowModal (false)
		)
	}

	return(
		<>
		<button onClick={() => setShowModal(true)}>Get Started</button>
		{showModal && <MyModal closeModal={closeModal}/>}
		</>
	)
}
export default MainForm;