import React from "react"
import TopicCovered from "../../../privateImages/journal/topic.png"




const JournalResearch = () => {
  return (
    <>
      {/* -----------------------------------Topic Covered----------------------------------------- */}
      
      <section className="mb-5">

        <div className="journal-topic-covered">
          <div className="container-fluid  topic-cover">
            <h1 className="journal-publish-topic">Topic Covered....</h1>
            <div className="row mt-3">
              <div className="col-sm-8">
                <div className="row">
                  <div className="col-sm-3">

                    <li className="journal-list">- Engineering</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Earth Science</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Food Science</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Applied Science</li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">

                    <li className="journal-list">- Mathematics & Statistics</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Chemistry & Chemical</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">


                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Materials Science</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Education Science</li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <li className="journal-list">- Engineering & Technology</li>
                  </div>
                  <div className="col-sm-3">

                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Engineering Management</li>
                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Multi-Disiplinary</li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    {/* <li className="journal-list">- Engineering & Technology</li> */}
                  </div>
                  <div className="col-sm-3">

                    <li className="journal-list">- Computer Science & Electrical, e.t.c</li>
                  </div>
                  <div className="col-sm-3">
                 
                  </div>
                  <div className="col-sm-3">
                    <li className="journal-list">- Earth, Energy & Environment</li>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                  <li className="journal-list">- Multi-Disiplinary</li>
                  </div>
                  <div className="col-sm-3">
                
                   
                  </div>
                  <div className="col-sm-3">
                
                  </div>
                  <div className="col-sm-3">
                  
                  </div>
                </div>
                {/* <div className="row">
          <div className="col-sm-3">
          <li className="journal-list">- Engineering & Technology</li>    
          </div>
          <div className="col-sm-3">
                       
          
            </div>
            <div className="col-sm-3">
            <li className="journal-list">- Earth, Energy & Environment</li>          
            </div>
            <div className="col-sm-3">

            </div>
         </div> */}
              </div>
              <div className="col-sm-4">
                <div className="topic-covered-image">
                  <img src={TopicCovered} alt="image" height="100%" width="100%" />
                </div>
              </div>
            </div>

          </div>

        </div>

      </section>

    </>
  )
}
export default JournalResearch;