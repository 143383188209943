import React, { useState } from "react";
import "./service.css"
import Back from "../../comon/back/Back"
import AwSList from "./AWSList";
import AwsImage from "../../../../privateImages/service-file/aws.png"

import MyModal from "../../../Form/SoForm";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


import  { lazy, Suspense } from 'react';



	


const AWS = () => {
 

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }

    return (
        <>
   

  
          
            <section className='back'>
                <h1 className="mt-5 text-center">Academic writing services</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
            <div className='margin'></div>
            <div className="container-fluid our-container mb-5">
                <div>
                    <h1 className="awshead text-light">Welcome to Our Assignment Help Services</h1>
                    <p className="awspara text-light">At DfreeNovelish, we understand the challenges faced by both undergraduate (UG) and postgraduate (PG) students in today's competitive academic landscape.
                        That's why we offer comprehensive assignment help services tailored to meet your specific needs and requirements.</p>
                </div>
                <div className="assist">
                    <div>
                        <img src={AwsImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                    <div className="awsassistpara">

                        <h1 className="awsassist text-light">How We Can Assist You</h1>
                        <p>Our team of experienced academic writers and subject matter experts is dedicated to helping you excel in your studies. Whether you're struggling with a complex assignment, facing
                            tight deadlines, or seeking guidance on a particular topic, we're here to provide the support you need to succeed.</p>
                    </div>
                    </div>
                </div>
                <div>
                    <h1 className="awssets text-light">What Sets Us Apart</h1>
                    <div className="container  mt-5 mb-5">
                        <div className="row">
                        <div className="col-sm-3 mt-1   awsapartcard ">
                            <h3 className="awsaparthead">Personalized<br/> Approach</h3>
                            <p className="apartpara">We carefully consider your needs to deliver tailored solutions that meet your requirements. Our goal is to provide services that perfectly align with your preferences, ensuring satisfaction and exceeding expectations consistently and reliably.
                            </p>
                        </div>
                        <div className="col-sm-3  mt-1   awsapartcard ">
                            <h3 className="awsaparthead">Quality Assurance</h3>
                            <p className="apartpara">Our team is committed to delivering high-quality work that meets the highest academic standards. Every assignment is thoroughly researched, meticulously written, and carefully edited to ensure accuracy, clarity, and excellence in every aspect.</p>
                        </div>
                        <div className="col-sm-3  mt-1  awsapartcard ">
                            <h3 className="awsaparthead">Timely Delivery</h3>
                            <p className="apartpara">We value deadlines, ensuring prompt delivery. Count on us for timely completion, whether urgent or long-term. Our dedication guarantees on-time submissions while maintaining high standards consistently and reliably, providing exceptional service every time.</p>
                        </div>
                        <div className="col-sm-3  mt-1 awsapartcard">
                            <h3 className="awsaparthead">24/7 Support</h3>
                            <p className="apartpara">Our support team is available 24/7 to assist with any questions. Whether you need help placing an order, tracking your assignment progress, or communicating with your writer, we’re here to support you every step of the way, ensuring your satisfaction.
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-5 mb-5">
                    <AwSList />
                </div>
                <div>
                    <h1 className="awssets text-light">Get Started Today</h1>
                    <p className="awspara text-light">Don't let academic challenges hold you back from achieving your goals. Whether you're a UG or PG student, our assignment help services are designed to help you succeed.
                        Contact us today to discuss your requirements and take the first step towards academic excellence.</p>
                     
                      <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}
		
                </div>
             
         
        </>
    )
}
export default AWS;