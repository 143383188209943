import React from "react"
import Back from "../comon/back/Back"
import "./contact.css"
import { FaFacebookF, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

import { Link } from "react-router-dom";




const Contact = () => {

  const mapHtml = `<div style="overflow:hidden;resize:none;max-width:100%;width:500px;height:1000px; margin-top:5%"><div id="google-maps-canvas" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=dfreenovelish,+kisangarh,+Amroha,+Uttar+Pradesh,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="our-googlemap-code" href="https://www.bootstrapskins.com/themes" id="authmaps-data">premium bootstrap themes</a><style>#google-maps-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}</style></div>`;
  return (
    <>
     
    
      <section className='back'>
        <h1>Contact Us</h1>
      </section>
      <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin'></div>
      <section className='contacts padding'>
        <div className='container flexSB'>
          <div className='left row'>
            <div className="col-sm-6">
              <div dangerouslySetInnerHTML={{ __html: mapHtml }} />
            </div>

            <div className='right col-sm-6'>
              <h1>Contact us</h1>
              <p>We're open for any suggestion or just to have a chat</p>
              <div className='box'>
                <h4 className="contact-address">ADDRESS:</h4>
                <p>M-2 Bhavan3 Kisangarh Amroha Moradabad, Uttar Pradesh -244221</p>
              </div>
              <div className='items grid2'>

                <div className='box'>
                  <h4>EMAIL:</h4>
                  <p>info@dfreenovelish.com<br />
                    career@dfreenovelish.com</p>
                </div>
                <div className='box'>
                  <h4>PHONE:</h4>
                  <p>+917302344754<br />+917618162404
                  </p>
                </div>

              </div>

              <form action=''>
                <div className='flexSB'>
                  <input type='text' placeholder='Name' />
                  <input type='email' placeholder='Email' />
                  
                </div>
                <div className='flexSB'>
                  
                <select   style={{height:"7.5vh", width:"38vh"}}>
                                <option value="volvo">Academic Assignment Help</option>
                                <option value="saab">Video Lectures – E-learning Service</option>
                                <option value="opel">Resume, Cover Page and SOP Writing</option>
                                <option value="audi">Social Media Marketing</option>
                                <option value="audi">Turnitin Plagiarism Report</option>
                                <option value="audi">Website Development</option>
                                <option value="audi">Other</option>
                            </select> 
                  <input type='text' className="mx-2" placeholder='Location' />
                  
                </div>
                <input type='number' placeholder='Mobile Number' />
                <textarea cols='30' rows='10'>
                  Create a message here...
                </textarea>
                <button className='primary-btn localbutton'>SEND MESSAGE</button>
              </form>

              <h3 className='contact-social-media'>Follow us here</h3>
              {/* <span>FACEBOOK TWITTER INSTAGRAM DRIBBBLE</span> */}
              <Link to="https://www.facebook.com/dfreenovelish?mibextid=ZbWKwL" style={{textDecoration:"none"}}><FaFacebookF className="facebook social-media-icon" /></Link>
              <Link to="https://www.instagram.com/dfreenovelish?igsh=MWVzdzZqdDMxcXJsNQ==" style={{textDecoration:"none"}}>  <FaInstagram className="insta social-media-icon" /></Link>
              <Link to="https://in.pinterest.com/dfreenovelish/" style={{textDecoration:"none"}}>   <FaPinterestP className="pinterest social-media-icon" /></Link>
              <Link to="https://www.linkedin.com/company/dfreenovelish/" style={{textDecoration:"none"}}>  <FaLinkedinIn className="linkedin social-media-icon" /></Link>
              <Link to="https://twitter.com/Dfreenovelish" style={{textDecoration:"none"}}>   <FaTwitter className="twitter social-media-icon" /></Link>
              <Link to="https://www.youtube.com/@DfreeNovelish" style={{textDecoration:"none"}}>      <FaYoutube name="youtube" size={24} color="red" className=" social-media-icon" /></Link>
         
            
            </div>
          </div>
        </div>
   
      </section>
 
    </>
  )
}

export default Contact




