import React, { useState } from "react";
import "../Research_Writting/research.css"
import Back from "../../comon/back/Back"
// import ResearchWrittingList from "./ResearchWrittingList";
import SmmImage from "../../../../privateImages/service-file/social-media.png"
import SmmList from "./SmmList";
// import AboutList from './AboutList';
import MyModal from "../../../Form/SoForm";


import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


const Smm = () => {
 

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }

    return (
        <>
   

          
            <section className=' back'>
            <h1 className="mt-5 text-center">Social Media Marketing</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>


            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Social Media Marketing Services:</h1>
                    <p className="awspara">Welcome to DfreeNovelish's Social Media Marketing Services, where we help businesses like yours leverage the power of social media to build brand awareness, engage audiences, and drive growth. In today's digital age, social media platforms offer unparalleled
                        opportunities to connect with your target audience, and our team of experts is here to help you make the most of them.</p>
                </div>
                <div className="assist">

                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>At DfreeNovelish, we understand that social media marketing is more than just posting content. It's about crafting a strategic approach that aligns with your business goals and resonates with your audience. Whether you're looking to increase brand
                            awareness, drive website traffic, or boost sales, our tailored strategies are designed to deliver measurable results.</p>
                    </div>
                    <div>
                        <img src={SmmImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                </div>
                <div>
                    <h1 className="awssets mb-5">What Sets Us Apart</h1>
                    <div className="researchcards">

                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>Strategic Planning:</h3>
                                <p>We start by conducting a comprehensive analysis of your business, industry,
                                    and target audience to develop a customized social media marketing strategy that aligns with your objectives</p>
                            </div>
                        </div>
                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>Audience Targeting:</h3>
                                <p>We leverage advanced targeting techniques to reach your ideal
                                    customers on social media platforms, ensuring that your message is seen by the right people at the right time.</p>
                            </div>
                        </div>
                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>Data-Driven Insights:</h3>
                                <p>We continuously monitor and analyze the
                                    performance of your social media campaigns, using data-driven insights to optimize strategies and maximize ROI.</p>
                            </div>
                        </div>
                    </div>

                    <div className="researchcards secondresearch">

                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>Attention to Detail</h3>
                                <p>We pay meticulous attention to grammar, formatting,
                                    and tone to ensure that your cover letter and SOP are polished and professional.</p>
                            </div>
                        </div>
                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>Transparent Reporting:</h3>
                                <p>We provide regular reports that outline key performance metrics and demonstrate the impact of our social media marketing efforts, keeping you informed every step of the way.
                                </p>
                            </div>
                        </div>
                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner">
                                <h3>24/7 Support</h3>
                                <p>Our support team is available 24/7 to assist with orders, writer communication, and research progress tracking, ensuring all needs are met.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SmmList />
                    </div>
                    <div>
                        <h1 className="awssets">Get Started Today</h1>
                        <p className="awspara">Ready to elevate your social media presence? Contact us today to learn more about our social media marketing services and
                            discover how we can help you achieve your business goals through strategic and effective social media campaigns.</p>
                            <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />} </div>
                </div>
            </div>
       
        </>
    )
}
export default Smm;