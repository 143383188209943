import React, { useState } from "react";
import "./form.css";
import { useEffect } from "react";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'

const MyModal = ({ closeModal }) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        }
    }, []);
    const [value, setValue] = useState()
    return (
        <>
            <div className="modal-wrapper" onClick={closeModal}></div>
            <div className="modal-container">
                <h1>Request a Quote</h1>
                <form>
                    <div className="row">
                        <div className="col-sm-6">
                            <label>Name</label>
                            <input type="text" />
                        </div>
                        <div className="col-sm-6">
                            <label>Your email</label>
                            <input type="email"></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                          
                         {/* <PhoneInput
                                placeholder="Enter phone number"
                                value={value}
                                onChange={setValue}
                                PhoneInputCountryFla-height="10px" */}
                                        {/* //  flagclassName="h-25 w-25"
            
// flagStyle={{ height: '2rem', width: '3rem',fontSize: 16, lineHeight: '16px' }} */}
{/* /> */}
                     
                            <label>Phone</label>
                            <input type="text" />
                        </div>
                       
                        <div className="col-sm-6">
                            <label>Services</label>
                            <select style={{ height: "7.5vh", width: "38vh" }}>
                                <option value="volvo">Academic Assignment Help</option>
                                <option value="saab">Video Lectures – E-learning Service</option>
                                <option value="opel">Resume, Cover Page and SOP Writing</option>
                                <option value="audi">Social Media Marketing</option>
                                <option value="audi">Turnitin Plagiarism Report</option>
                                <option value="audi">Website Development</option>
                                <option value="audi">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label>Your Budget</label>
                            <input type="text" />
                        </div>

                    </div>
                    <div className="row">
                        <label>Please provide us further into for your quote</label>
                        <textarea cols='10' rows='3' >
                            Create a Message....
                        </textarea>
                    </div>


                </form>
                <button className="modal-btn mt-3" onClick={closeModal}>Send</button>
            </div>
        </>
    )
}
export default MyModal;