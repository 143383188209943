import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Create the script element for the gtag.js library
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-B1JFB4FPM4';
    document.head.appendChild(script);

    // Function to initialize the Google Analytics
    const initializeGA = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-B1JFB4FPM4');
    };

    // Create a script to run the initialization code
    const scriptInit = document.createElement('script');
    scriptInit.textContent = `(${initializeGA.toString()})();`;
    document.head.appendChild(scriptInit);

    // Clean up the script elements when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptInit);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
