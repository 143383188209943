// import React from 'react';
// import About from './Abouts';

// const AboutsList = ({ Abouts }) => {
//   return (
//     <div className="Abouts-list">
//       {About.map((About, index) => (
//         <About key={index} title={About.title} description={About.description} imageUrl={About.imageUrl} />
//       ))}
//     </div>
//   );
// };

// export default AboutsList;

// import React from 'react';
// import About from './About';

// const AboutsList = ({ abouts }) => {
//   return (
//     <div className="abouts-list">
//       {/* {abouts.map((about, index) => (
//         <About key={index} title={about.title} description={about.description} imageUrl={about.imageUrl} />
//       ))} */}
//     </div>
//   );
// };

// export default AboutsList;


import React from 'react';
import { motion } from "framer-motion";

const AboutList = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y:0 }}
      viewport={{once:true}}
      transition={{
        ease: "linear",
        duration: 1,
        y: { duration: 0.5 }
      }}
    >
      <h1>Scroll Down!</h1>
    </motion.div>
  )
}

export default AboutList();


