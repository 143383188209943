import React from "react"
import AboutCard from "../about/Aboutcard"
import Hblog from "../Home/Hblog"
import HAbout from "./HAbout"
import Homes from "./Home"
import Hpricing from "./Hpricing"
import MainServices from "../allServices/MainServices"
import Testimonial from "./Testimonial"

import { Link } from "react-router-dom"
import { FaWhatsapp } from "react-icons/fa"


const Home = () => {


  return (
    <>
       


 
<Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
  

      <Homes />
      <AboutCard />
      <HAbout />
      {/* <Testimonal /> */}
      <MainServices/>
      <Hblog />
      <Hpricing />
      <Testimonial/>
    
    </>
  )
}

export default Home