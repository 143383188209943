import React, { useState } from "react";
import { Link } from "react-router-dom";
import Head from "./Head";
import "./header.css";
// import { EvilIcons } from '@expo/vector-icons';
import { FaBars } from "react-icons/fa";

const Header = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setDropdown(!dropdown);

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB ournav"} onClick={() => setClick(false)} >
            <li  style={{textDecoration:"none"}}>
              <Link to='/' style={{textDecoration:"none"}}>Home</Link>
            </li>

            <li className=" dropdown">
              <li class="dropbtn  about-us-drop" >
              <Link to='/about'  style={{textDecoration:"none"}}>About Us</Link>
                </li>
              <ul className=" dropdown-content about-dropdown  ">
              <li className="about-drop my-0">
                  <Link to='/gallery'  style={{textDecoration:"none"}}>Gallery</Link>
                </li>
                <li className="about-drop my-0">
                  <Link to='/chairman'  style={{textDecoration:"none"}}>Chairman Message</Link>
                </li>  
                <li className="about-drop  my-0" >
                  <Link to='/team'  style={{textDecoration:"none"}}>Our Team</Link>
                </li>
                <li className="about-drop  my-0">
                  <Link to='/advisor'  style={{textDecoration:"none"}}>Our Advisors</Link>
                </li>
                <li className="about-drop  my-0">
                  <Link to='/client'  style={{textDecoration:"none"}}>Our Clients</Link>
                </li> 
                <li className="about-drop  my-0">
                  <Link to='/certificate'   style={{textDecoration:"none"}}>Awards & Certificates</Link>
                </li>
                </ul>
                </li>
           
            <li className=" dropdown">
              <li class="dropbtn ">
                <Link to='/services'  style={{textDecoration:"none"}}> Our Services</Link>
                </li>
      
            </li>

            <li className=" dropdown">
              <li class="dropbtn  about-us-dro">
              <Link to='/resource'  style={{textDecoration:"none"}}>Resources</Link>
                </li>
       
              <ul className=" dropdown-content about-us-drop">
                <li className="about-drop  my-0">
                  <Link to='/project'  style={{textDecoration:"none"}}>Projects</Link>
                </li>
                </ul>
                </li>

                <li>
              <Link to='/journal'  style={{textDecoration:"none"}}>Journal Publication</Link>
            </li>

            <li>
              <Link to='/events-conferences'  style={{textDecoration:"none"}}>Events and Conferences</Link>
            </li>
            <li>
              <Link to='/contact'  style={{textDecoration:"none"}}>Contact Us</Link>
            </li>
            {/* <li>
              <Link to='/form'>Form</Link>
            </li> */}
          </ul>
          <div className='start'>
            <div><Link className="get-certificate" to='/get-certificate'>GET CERTIFICATE</Link></div>
          </div>
          <button className='toggle localbutton' style={{color:"white",marginTop:"0" }} onClick={() => setClick(!click)}>
           
          <FaBars />
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
