import React, { useState } from "react";
// import './research.css'
import "../Research_Writting/research.css"
import Back from "../../comon/back/Back"
import VideoLectureList from "./VideoLectureList";
import ResearchImage from "../../../../privateImages/service-file/video-lecture.png"

import MyModal from "../../../Form/SoForm";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

// import AboutList from './AboutList';



const VideoLecture = () => {


    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
      


         
            <section className='back'>
            <h1 className="mt-5 text-center">Video Lectures (E-Tutorials service)</h1>
      </section>
      <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>
            <div className="container-fluid our-container mt-5">
                <div>
                    <h1 className="awshead mt-5">Video Lectures (E-Tutorials service)</h1>
                    <p className="awspara">Welcome to our Video Lectures page, where we offer a comprehensive etutorial
                        service designed to enhance your learning experience. Our video lectures cover a
                        wide range of subjects and topics, delivered by expert educators to help you master key
                        concepts and excel in your studies.</p>
                </div>
                <div className="assist" height="50%" width="100%">

                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Video Lectures (E-Tutorials service) offers engaging content for in-depth understanding of complex subjects. Clear explanations and demonstrations ensure mastery of key concepts, empowering learners to excel academically and tackle challenges confidently.
                        </p>
                    </div>
                    <div>
                        <img src={ResearchImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                </div>



                {/* ----------------------------------------------------What Sets Us Apar--------------------------------------- */}
                <section>
                    <div>
                        <h1 className="awssets mb-5">What Sets Us Apart</h1>
                        <div className="researchcards">

                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Access to Expert Knowledge</h3>
                                    <p>Learn from the best in the industry and gain valuable
                                        insights.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Tailored Support</h3>
                                    <p>We take a personalized approach to each project,
                                        working closely with you to understand your specific research interests and requirements.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Convenience</h3>
                                    <p>Study at your own pace and schedule, fitting learning around your
                                        busy lifestyle.</p>
                                </div>
                            </div>
                        </div>

                        <div className="researchcards secondresearch">

                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Cost-Effective</h3>
                                    <p>Save on traditional classroom costs with our affordable e-tutorial
                                        service.</p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>Improved Learning Outcomes:</h3>
                                    <p>Master key concepts and skills with our engaging and
                                        effective video lectures.
                                    </p>
                                </div>
                            </div>
                            <div class="researchcard">
                                <div class="circle"></div>
                                <div class="circle"></div>
                                <div class="researchcard-inner">
                                    <h3>24/7 Support</h3>
                                    <p>Our support team is available 24/7 to assist with orders, writer communication, and research progress tracking, ensuring all needs are met.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* -----------------------------------------------What Sets Us Apar ENDED----------------------------------- */}
                <div>
                    <VideoLectureList />
                </div>
                <div>
                    <h1 className="awssets">Get Started Today</h1>
                    <p className="awspara">Ready to take your learning to the next level? Browse our collection of video
                        lectures and start your journey toward academic and professional success today.</p>
                        <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}  </div>
            </div>
      
        </>
    )
}
export default VideoLecture;