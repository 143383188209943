import React from 'react';
// import './service.css'; 
import "../AWS/service.css"
import Marquee from "react-fast-marquee";


  const BookList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Manuscript Development&nbsp;&nbsp;&nbsp;&nbsp;
• Book Design&nbsp;&nbsp;&nbsp;&nbsp;
• Publication Assistance&nbsp;&nbsp;&nbsp;&nbsp;
• Marketing and Promotion&nbsp;&nbsp;&nbsp;&nbsp;
• Distribution Support&nbsp;&nbsp;&nbsp;&nbsp;
• Author Support&nbsp;&nbsp;&nbsp;&nbsp;
• Manuscript Development&nbsp;&nbsp;&nbsp;&nbsp;
• Book Design&nbsp;&nbsp;&nbsp;&nbsp;
• Publication Assistance&nbsp;&nbsp;&nbsp;&nbsp;
• Marketing and Promotion&nbsp;&nbsp;&nbsp;&nbsp;
• Distribution Support&nbsp;&nbsp;&nbsp;&nbsp;
 </Marquee>
        </div>
    
    );
  }


export default BookList;