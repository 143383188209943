import React from 'react';
// import './service.css'; 
import "../AWS/service.css"
import Marquee from "react-fast-marquee";


  const AWSList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Video Interviews&nbsp;&nbsp;&nbsp;&nbsp;
• Blog Podcast Interviews&nbsp;&nbsp;&nbsp;&nbsp;
• Interview Setup and Coordination&nbsp;&nbsp;&nbsp;&nbsp;
• Professional Production&nbsp;&nbsp;&nbsp;&nbsp;
• Content Distribution&nbsp;&nbsp;&nbsp;&nbsp;
• Transcription Services&nbsp;&nbsp;&nbsp;&nbsp;
• Performance Analytics&nbsp;&nbsp;&nbsp;&nbsp;
• Customized Packages&nbsp;&nbsp;&nbsp;&nbsp;
          </Marquee>
        </div>
    
    );
  }


export default AWSList;