const Issuedata = [
  // {
  //   pdflink: "https://www.dfreenovelish.com/wp-content/uploads/2023/07/Dfree-2.pdf",

  {
    pdflinkA: "./IssuePdf/development-and-evalution-of-reversible-logic-gate.pdf",
   title: "Development and Evaluation of Reversible Logic Gate-Based Neuromorphic Computing Architectures",
   Name: "Divya Bhardwaj",
   Position: "Phd Scholar",
   Email: "divyabhardwaj205@gmail.com",
  },
  {
    pdflinkA: "./IssuePdf/end-to-end-speech-to-speech-translator.pdf",
   title: "End to End Speech to Speech Translator ,which translate any speech to any other language in real time",
   Name: " Swapndeep Nandi",
   Position: "Student",
   Email: "swapnadip34@gmail.com",
 },
  {

    pdflinkA: "./IssuePdf/data-privacy-in-the-age-of-big-data-analytics.pdf",
    title: "Data privacy in the age of Big Data Analytics",
    Name: "Anushka Agarwal",
    Position: "Student",
    Email: "agarwal30.0.20.03@gmail.com",
  },
  {
     pdflinkA: "./IssuePdf/importance-of-AI.pdf",
    title: "Importance of AI in new technologies",
    Name: "Paras Khola",
    Position: "Student",
    Email: "Paraskhola1608@gmail.com",
  },


 {
  pdflinkA: "./IssuePdf/the-evalution-of-nationalism.pdf",
 title: "The Evolution of Nationalism in a Globalized World: Trends and Implications",
 Name: "Akshay Kaushik",
 Position: "Advisor of Dfree",
 Email: "koushikji111@gmail.com",
},
{
  pdflinkA: "./IssuePdf/the-societal-implication-of-AI-in-heealthcare.pdf",
 title: "The societal implications of AI in healthcare, finance, and autonomous vehicles",
 Name: "Ankit Jaiswal ",
 Position: "Student",
 Email: "ankitchandjaiswal10@gmail.com",
},

];
export default Issuedata;
