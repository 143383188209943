import React, { useState } from "react";
// import "./service.css"
import "../AWS/service.css"
import Back from "../../comon/back/Back"
import VideoList from "./VideoList";
import AwsImage from "../../../../privateImages/service-file/aws.png"
import MyModal from "../../../Form/SoForm";

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";




const Video = () => {


    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>


      
          
            <section className='back'>
                <h1 className="mt-5 text-center">Video and Blog Podcast Interview</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
                style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
                <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>
            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to our Video and Blog Podcast (Interview )</h1>
                    <p className="awspara">Your all-in-one solution for amplifying your voice and reaching a wider audience. With our comprehensive service, you can share your expertise, insights, and stories through engaging video and podcast
                        interviews. Whether you're looking to increase your visibility, connect with industry peers, or promote your brand, we've got you covered.</p>
                </div>
                <div className="assist">
                    <div>
                        <img src={AwsImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Video and Blog Podcast Interview Service provides dynamic interviews tailored to your audience, amplifying your message and expanding your reach across diverse platforms, fostering engagement and connection with your target audience effectively.</p>
                    </div>

                </div>
                <div>
                    <h1 className="awssets mt-5">What Sets Us Apart</h1>
                    <div className="container awspart">
                        <div className="row">
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Increased Visibility</h3>
                                <p className="apartpara">Elevate industry presence and credibility with professionally produced video and podcast interviews, and insights effectively.
                                </p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Networking Opportunities</h3>
                                <p className="apartpara"> Connect with industry peers through engaging conversations and shared experiences.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Content Marketing</h3>
                                <p className="apartpara">Leverage video and podcast interviews as part of your content marketing strategy to attract and engage your target audience.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Brand Building</h3>
                                <p className="apartpara">Build trust and authority in your niche by sharing valuable insights and stories with your audience through video and podcast interviews.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <VideoList />
                </div>
                <div>
                    <h1 className="awssets">Get Started Today</h1>
                    <p className="awspara">Ready to share your expertise and stories with the world? Contact us today to learn more about our Video and Blog Podcast Interview Service and take the first step toward expanding your reach and growing your influence.</p>
                    <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}</div>
            </div>

        </>
    )
}
export default Video;