import React from "react";
import "./about.css";
import Back from "../comon/back/Back";
import AboutList from './AboutList';

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";


const About = () => {


  return (
    <>
     

     
        <section className='back'>
          <h1 className="mt-5">About Us</h1>
        </section>
        <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
          style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
          <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" />
        </Link>

        <div className='margin'></div>

        <div className="container mb-0 pb-5">
          <div className="row">
            <h2 className="text-light mb-5 text-center">"Empowering students with personalized learning and enhancing business growth through innovative web solutions and strategic social media marketing."</h2>
            <p className="about-company">“We are a dynamic team dedicated to empowering individuals and businesses alike. With a passion for excellence and innovation, we strive to provide top-notch services tailored to meet your unique needs. Our commitment to quality, integrity, and customer satisfaction drives everything we do. Discover how we can help you unlock your full potential and achieve your goals today."</p>
          </div>
          <h1 className="about-head mt-5"> OUR COMPANY USP</h1>

          <div className="allaboutcards my-5 pb-5">
            <div className="about">
              <div class="aboutcard">
                <p style={{ marginLeft: "20%" }}>Offering budget-friendly solutions without compromising on quality on L1 category (lowest pricing in the industry).</p>
                <div class="cover">
                  <p className="aboutfront">Affordable Pricing</p>
                </div>
              </div>
            </div>
            <div className="about">
              <div class="aboutcard">
                <p style={{ marginLeft: "20%" }}>Customizing our offerings to suit the specific needs of each client.</p>
                <div class="cover">
                  <p className="aboutfront">Tailored Services</p>
                </div>
              </div>
            </div>
            <div className="about">
              <div class="aboutcard">
                <p style={{ marginLeft: "20%" }}>Providing a diverse range of services under one roof, catering to various needs.</p>
                <div class="cover">
                  <p className="aboutfront">Expertise Across Categories</p>
                </div>
              </div>
            </div>
            <div className="about">
              <div class="aboutcard">
                <p style={{ marginLeft: "20%" }}>Ensuring the highest standards of excellence in every project we undertake.</p>
                <div class="cover">
                  <p className="aboutfront">Quality Assurance</p>
                </div>
              </div>
            </div>
          </div>

         
        </div>

      
    </>
  );
};

export default About;