import React, { useState } from "react";
// import "./service.css"
import "../AWS/service.css"
import Back from "../../comon/back/Back"
import TurtinList from "./TurtinList";
import AwsImage from "../../../../privateImages/service-file/turnitin.png"
import MyModal from "../../../Form/SoForm";

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";




const Turtin = () => {
  

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
  


         
            <section className='back'>
                <h1 className="mt-5 text-center">Turnitin Plagiarism Report</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
                style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
                <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>
            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to our Turnitin Plagiarism Report Service</h1>
                    <p className="awspara">Your trusted partner in ensuring
                        academic integrity and originality. Our service provides comprehensive plagiarism reports
                        generated by Turnitin, the leading plagiarism detection software, to help you verify the authenticity of your work and uphold academic standards.</p>
                </div>
                <div className="assist">
                    <div>
                        <img src={AwsImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Turnitin Plagiarism Report Service ensures the authenticity of your work by providing comprehensive assistance, including thorough analysis and detailed reports to identify and address any instances of plagiarism effectively.</p>
                    </div>

                </div>
                <div>
                    <h1 className="awssets">What Sets Us Apart</h1>
                    <div className="container awspart">
                        <div className="row">
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Peace of Mind</h3>
                                <p className="apartpara">Ensure originality and avoid unintentional plagiarism by using Turnitin to verify the authenticity of your work effectively.
                                </p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Academic Integrity</h3>
                                <p className="apartpara">Demonstrate academic honesty with Turnitin plagiarism reports, ensuring the authenticity and integrity of your work effectively.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Professionalism</h3>
                                <p className="apartpara">Present your work with professionalism and credibility, knowing that it has been thoroughly checked for plagiarism.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Time Savings</h3>
                                <p className="apartpara">Save time by outsourcing plagiarism detection to our experienced team, freeing you to focus on academic or professional tasks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <TurtinList />
                    </div>
                    <div>
                        <h1 className="awssets">Get Started Today</h1>
                        <p className="awspara">Ready to ensure the originality of your work and maintain academic integrity? Contact us today to learn more about our Turnitin Plagiarism Report Service and take the first step toward achieving academic excellence.</p>
                        <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />} </div>
                </div>
            </div>
          
        </>
    )
}
export default Turtin;