// // import React from "react";
// // import "./getcertificate.css"
// // import Back from "../back/Back";


// // const GetCertificate = () =>{
// //     return(
// //     <>
// //    <Back title='Get- Certificates' />
// //     <div className="container">

// //     </div>
// //     </>

// //     )
// // }
// // export default GetCertificate;


// import React, { useState } from 'react';
// import "./getcertificate.css"
// import Back from "../back/Back";
// import Image1 from "../../../../privateImages/Gallery/d-1.jpg"
// import Image2 from "../../../../privateImages/Gallery/d-2.jpg"
// import Image3 from "../../../../privateImages/Gallery/d-3.jpg"
// import Image4 from "../../../../privateImages/Gallery/d-4.jpg"

// const Card = ({ imageUrl, title }) => {
//   return (
//     <>
   
//     <div className="my-card mt-5">
//         <div className='container '>
//             <div className='row '>
//                 <div className='col-sm-8'>
//                 <h3>{title}</h3>
//                 </div>
//                 <div className='col-sm-4'>
//                 <img src={imageUrl} className='card-image' alt={title} height="100%" width="100%" />
//                 </div>
//             </div>
//         </div>
     
   
//     </div>
//     </>

//   );
// };

// const GetCertificate = () => {
//   const [cards, setCards] = useState([
//     { id: 1, imageUrl: Image1, title: 'Card 1' },
//     { id: 2, imageUrl: Image2, title: 'Card 2' },
//     { id: 3, imageUrl: Image3, title: 'Card 3' },
//     // Add more cards as needed
//   ]);

//   const [searchQuery, setSearchQuery] = useState('');

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const filteredCards = cards.filter(card =>
//     card.title.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
    
//     <div>
//          <Back title='Get- Certificates' />
//       <input
//         type="text"
//         placeholder="Search cards..."
//         value={searchQuery}
//         onChange={handleSearch}
//         className='search-bar text-center'
//       />
//       <div className="card-container">
//         {filteredCards.map(card => (
//           <Card key={card.id} imageUrl={card.imageUrl} title={card.title} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default GetCertificate;


import React from "react";

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";



const GetCertificate = () =>{
  return(
    <>

 
            <section className='back text-light mb-5'>
                <h1>Get Certificate</h1>
               
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    

            <div className='margin'></div>

    <h1 className="text-center text-light">Coming Soon.......</h1>
    </>
  )
}

export default GetCertificate;