import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Google Tag Manager script
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-54HT6XS4');
  }, []);

  return (
    <>
      {/* Google Tag Manager (noscript) */}
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-54HT6XS4"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
      {/* End Google Tag Manager (noscript) */}
    </>
  );
};

export default GoogleTagManager;