import React from "react";
import Shivom from "../../../privateImages/imgs/dr.shivom.png"
import Kanta from "../../../privateImages/imgs/dr.kanta.png"
import { CTab, CTabContent, CTabList, CTabPanel, CTabs } from "@coreui/react";
import Pankaj from "../../../privateImages/imgs/dr.pankaj.png"


const Editorial = () => {
    return (
        <>
            <h4 className="text-light mt-5" style={{ textAlign: "justify" }}>
                The DfreeNovelish journal publication is proud to have an esteemed editorial board composed of leading experts and distinguished scholars across various scientific disciplines. Our editorial board members bring a wealth of knowledge and experience from their respective fields, including biology, chemistry, physics, and engineering & many more. They are committed to maintaining the journal's high standards of academic integrity and scientific excellence. The board plays a crucial role in the peer review process, ensuring that every published article meets rigorous quality and ethical standards. Their dedication and expertise
                are pivotal in guiding the journal's editorial policies and advancing our mission to disseminate groundbreaking research.
            </h4>

            <CTabs activeItemKey={1} className="mx-5 ">
                <CTabList variant="tabs" layout="fill">
                    <CTab aria-controls="editor-in-chief" className="text-primary" itemKey={1}>Editor-in-Chief</CTab>
                    <CTab aria-controls="associate-editors" className="text-primary" itemKey={2}>Associate Editors</CTab>    
                </CTabList>
                <CTabContent>
                <CTabPanel className="py-3" aria-labelledby="editor-in-chief" itemKey={1}>
                        <div className="assist">
                            <div>
                                <img src={Pankaj} alt="image" className="editorialimage mt-4" style={{ color: "rgba(255, 255, 255, 0.058)", border: "solid" }} />
                            </div>
                            <div className="editorialpara">
                                <p className="mt-3">Prof. (Dr) Pankaj Kumar Mishra is a highly skilled academic professional with a Ph.D. in Mathematics. He has extensive experience teaching mathematics and working in academic administration. Prof. Mishra is dedicated to enhancing student learning experiences and promoting academic excellence in mathematics education.

                                </p>
                                <h6>Ph.D. in Mathematics</h6>
                                <h6>From: Sahibzada Ajit Singh Nagar, Punjab</h6>
                            </div>
                        </div>
                    </CTabPanel>
                <CTabPanel className="py-3" aria-labelledby="associate-editors" itemKey={2}>
                        <div className="assist">
                            <div>
                                <img src={Shivom} alt="image" className="editorialimage mt-4" style={{ color: "rgba(255, 255, 255, 0.058)", border: "solid" }} />
                            </div>
                            <div className="editorialpara">
                                <p className="mt-3">Dr. Shivom Sharma, Ph.D. in Mathematics, is an Assistant Professor and subject expert with a strong background in teaching and research. He is known for his significant contributions to higher education in India and active participation in seminars and conferences. With published works in prestigious journals and a commitment to student welfare, Dr. Sharma is a valuable addition to our editorial board..</p>
                                <h6>Ph.D. in Mathematics</h6>
                                <h6>From: Rampur, UP</h6>
                            </div>
                        </div>
                        <div className="assist">
                            <div>
                                <img src={Kanta} alt="image" className="editorialimage mt-4" style={{ color: "rgba(255, 255, 255, 0.058)", border: "solid" }} />
                            </div>
                            <div className="editorialpara">
                                <p className="mt-3">Dr. Nalini Kanta Sahoo, a Ph.D. in Pharmaceutical Analysis from Jagatsinghpur, is a valuable addition to your editorial board. With his expertise in the field and proven academic leadership, he will bring a wealth of knowledge and experience to your team. Dr. Sahoo's research background and dedication to pharmaceutical analysis make him a standout candidate for contributing to your publication.

                                </p>
                                <h6>Ph.D in Pharmaceutical Analysis</h6>
                                <h6 >From: Jagtsinghpur</h6>
                            </div>
                        </div>
                    </CTabPanel>
 
                </CTabContent>
            </CTabs>

        </>
    )
}
export default Editorial