

import React from 'react';
import projectdata from './projectdata';

import "./project.css"
import { FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import  { lazy, Suspense } from 'react';


const Project = () => {
 

  return (
    <>
     

  

  <section className='back'>
    <h1 className="research-head
    ">Project</h1>
  </section>
  <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsappSquare name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
  <div className='margin'></div>
    <section className="wrapper">
      <div className="container">
        <div className="row">
          {projectdata.map((project, index) => (
            <div key={index} className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4  w-100 mt-3 project-card project-1 text-center">
                    <h5 className="card-meta mb-2 text-light">{project.topic}</h5>
                    <h4 className=" mt-0 text-start text-light text-center">{project.title}</h4>
                
                  <div className="card-footer d-flex justify-content-center">
                    <a 
                      href={project.pdflink} 
                      className="btn btn-primary" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      download
                    >
                      Download file
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    </section>

    </>
  );
};

export default Project;
