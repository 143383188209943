import React, { useState } from "react";
import "../AWS/service.css"
import Back from "../../comon/back/Back"
// import AwSList from "./AWSList";
import WebImage from "../../../../privateImages/service-file/website.png"
import WebList from "./WebList";
import MyModal from "../../../Form/SoForm";

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

// import AboutList from './AboutList';



const Web = () => {
 

    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
      

  
         
            <section className='back'>
                <h1 className="mt-5 text-center">Website Designing & Maintenance</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
                style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
                <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
            <div className='margin'></div>

            <div className="container-fluid our-container">
                <div>
                    <h1 className="awshead">Welcome to Our Website Designing and Maintenance Services</h1>
                    <p className="awspara">We combine creativity and technical expertise to create stunning websites that captivate audiences and drive results. Your website is the digital face of your brand, and it's essential to ensure it reflects your identity and engages your target audience effectively. Our team of experienced designers and
                        developers is here to help you achieve your online goals with innovative designs and reliable maintenance services.</p>
                </div>
                <div className="assist">
                    <div>
                        <img src={WebImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our Website Designing and Maintenance Services offer comprehensive solutions tailored to your needs. From initial design to ongoing maintenance, we ensure your website reflects your brand's identity and values while optimizing user experience and functionality for seamless navigation and engagement.
                        </p>
                    </div>
                </div>
                <div>
                    <h1 className="awssets">What Sets Us Apart</h1>
                    <div className="container awspart">
                        <div className="row">
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Technical Expertise</h3>
                                <p className="apartpara">Our experienced developers implement advanced features for optimal user experience on all devices and platforms, leveraging their technical skills and knowledge.
                                </p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Custom Solutions</h3>
                                <p className="apartpara">We tailor our website design and maintenance services to suit your brand identity, industry, and target audience, ensuring a seamless and cohesive online presence.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Responsive Support</h3>
                                <p className="apartpara">We offer ongoing maintenance and support services to ensure your website remains up-to-date, secure, and performing at its best, providing peace of mind and reliability.</p>
                            </div>
                            <div className="col-sm-3 awsapartcard">
                                <h3 className="awsaparthead">Affordable Pricing</h3>
                                <p className="apartpara">We offer competitive pricing for our website design and maintenance services, making professional web development accessible to businesses of all sizes.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <WebList />
                </div>
                <div>
                    <h1 className="awssets">Get Started Today</h1>
                    <p className="awspara">Ready to elevate your online presence? Contact us today to learn more about our website designing
                        and maintenance services and take the   first step toward creating a website that sets you apart from the competition.</p>
                        <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />} </div>
            </div>
          
        </>
    )
}
export default Web;