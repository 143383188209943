import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import About from "./components/Home/about/About"
import ServicesHome from "./components/Home/allServices/ServicesHome"
import Team from "./components/Home/Our Team/Team"
import Resource from "./components/Home/resource/Resource"
import Journal from "./components/Home/Journal/Journal"
import Contact from "./components/Home/contact/Contact"
import Footer from "./components/Home/comon/footer/Footer"
import Home from "./components/Home/Home/HomePage"
import Header from "./components/Home/comon/header/Header"
import Certificates from "./components/Home/Certificates/Certificates"
import AWS from "./components/Home/Services/AWS/AWS"
import ResearchWritting from "./components/Home/Services/Research_Writting/ResearchWrtting";
import Smm from "./components/Home/Services/SMM/Smm"
import Web from "./components/Home/Services/Web designing/Web"
import SOP from "./components/Home/Services/SOP Writting/SOP"
import Resume from "./components/Home/Services/Resume Writting/Resume"
import Gallery from "./components/Home/Gallery/Gallery"
import GetCertificate from "./components/Home/comon/Get Certificate/GetCertificate"
import Video from "./components/Home/Services/Video-Blog/Video"
import Book from "./components/Home/Services/Book-Writting/Book"
import Turtin from "./components/Home/Services/Turtin/Turtin"
import Content from "./components/Home/Services/Content-Writting/Content"
import VideoLecture from "./components/Home/Services/Video-Lecture/VideoLecture"
import Advisor from "./components/Home/Advisor_pannel/Advisor"
import MainForm from "./components/Form/MainForm"
import Manuscript from "./components/Home/Journal/Manuscript"
import JournalResearch from "./components/Home/Journal/JournalResearch"
import My_articles from "./components/Home/Journal/My_articles"
import Event from "./components/Home/Events/Event"
import Project from "./components/Home/Project/Project"
import Terms from "./components/Home/Terms&Services/Term"
import Career from "./components/Home/Carrers/Career"
import PrivacyPolicy from "./components/Home/Policy/PrivacyPolicy"
import BookPage from "./components/Home/Books/BookPage"
import Client from "./components/Home/Our Client/Client"
import IssueOne from "./components/Home/Journal/CurrentIssue/Issue1"
import Chairman from "./components/Home/Chairman-Message/Chairman"
import Videos from "./privateImages/Videos/video2.mp4"
import GoogleAnalytics from "./components/Home/GoggleAnalytics"
import GoogleTagManager from "./components/Home/GoogleTagManager"

// import Book from "./components/Home/Books/Book"
function App() {

  return (
    <>
       
      <Router>

      <video autoPlay loop muted className="video ">
          <source src={Videos} type="video/mp4" height="100%" width="100%" />
        </video>
        <GoogleTagManager/>
        <Header />
     
        <GoogleAnalytics />
     
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/advisor' exact element={<Advisor />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<ServicesHome />} />
          <Route path='/team' element={<Team />} />
          <Route path='/resource' element={<Resource />} />
          <Route path='/journal' element={<Journal />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/aws' element={<AWS />} />
          <Route path='research' element={<ResearchWritting />} />
          <Route path='/certificate' element={<Certificates />} />
          <Route path='/smm' element={<Smm />} />
          <Route path='/web' element={<Web />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/sop' element={<SOP />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/get-certificate' element={<GetCertificate />} />
          <Route path='/videoblog' element={<Video/>} />
          <Route path='/book' element={<Book />} />
          <Route path='/turtin' element={<Turtin />} />
          <Route path='/content' element={<Content />} />
          <Route path='/videolecture' element={<VideoLecture />} />
          <Route path='/form' element={<MainForm />} />
          <Route path='/manuscript' element={<Manuscript />} />
          <Route path='/journalResearch' element={<JournalResearch  />} />
          <Route path='/article' element={<My_articles />} />
          <Route path='/events-conferences' element={<Event/>} />
          <Route path='/project' element={<Project/>} />
          <Route path='/term' element={<Terms/>} />
          <Route path='/career' element={<Career/>} />
          <Route path='/privacy' element={<PrivacyPolicy/>} />
          <Route path='/bookpage' element={<BookPage/>} />
          <Route path='/client' element={<Client/>} />
          <Route path='/issueone' element={<IssueOne/>} />
          <Route path='/chairman' element={<Chairman/>} />
        </Routes>
     
        <Footer />
      </Router>



      

    </>
  )
}

export default App;