const projectdata = [
  // {
  //   pdflink: "https://www.dfreenovelish.com/wp-content/uploads/2023/07/Dfree-2.pdf",

  {
 
    pdflink: "./assets/analysis.pdf",
    topic: "Analysis Report",
    title: "Click here to View our Analysis Report",

  },
  {
    pdflink: "./assets/r-software-files.zip",
    topic: "R-software-Work",
    title: "Click here to View our R-Software Work",

  },
  {
    pdflink: "./assets/HRM-Solutions.pdf",
    topic: "HRM Report",
    title: "Click here to View our HRM Report",

  },
  {
    pdflink: "./assets/finance-research-paper.pdf",
    topic: "Finance Research Paper",
    title: "Click here to View our Finance Research Paper",

  },
  {
    pdflink: "./assets/digitalyug.pdf",
    topic: "Hindi Research Paper",
    title: "Click here to View Hindi Research Paper ",

  },
  {
    pdflink: "./assets/dissertation-final.pdf",
    topic: "Dissertation-final-report",
    title: "Click here to View our Dissertation Final Report",

  },


  {
    pdflink: "./assets/education-research-paper.pdf",
    topic: " Education Research Paper",
    title: "Click here to View our Education Research Paper",

  },


  {
    pdflink: "./assets/santulan-adhiniyam.pdf",
    topic: "Hindi Research Proposal",
    title: "Click here to View our Hindi Research Proposal",

  },
  {
    pdflink: "./assets/Public-Healt.pdf",
    topic: "Public Health Research Paper",
    title: " Click here to View Public Health Research Paper ",

  },
 
 
  
];
export default projectdata;
