import React from "react";
import Marquee from "react-fast-marquee";

import { MarqueeSlider } from 'react-marquee-slider';
import Image1 from "../../../privateImages/indexing/i-1.png"
import Image2 from "../../../privateImages/indexing/i-2.png"
import Image3 from "../../../privateImages/indexing/i-3.gif"
import Image4 from "../../../privateImages/indexing/i-4.png"
import Image5 from "../../../privateImages/indexing/i-5.png"
import Image6 from "../../../privateImages/indexing/i-6.png"
import Image7 from "../../../privateImages/indexing/i-7.png"
import Image8 from "../../../privateImages/indexing/i-8.jpg"





const CompanyList = () => {
return(
<>
<h1 className='AWSListHead text-center mt-5'>INDEXING</h1>
<Marquee>
    <img src={Image1} alt="Image1" className="bg-light pt-1 pb-1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image2} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image3} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image4} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image5} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image6} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image7} alt="Image1" className="bg-light pt-1 pb-1"/>&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={Image8} alt="Image1"/>&nbsp;&nbsp;&nbsp;&nbsp;
</Marquee>
</>
   
);
}
export default CompanyList;