export const homeAbout = [
  {
    id: 1,
    cover: "",
    title: "Academic Excellence:",
    l1: "o Tailored writing solutions",
    l2: "o Expert guidance",
    l3: "o Turnitin reports for authenticity"
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Digital Advancement:",
    l1: "o Customized web development",
    l2: "o Innovative e-tutorials",
    l3: "o Strategic social media marketing",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Professional Enhancement:",
    l1: "o SOP and resume writing",
    l2: "o Elevating academic journey",
    l3: "o Igniting digital presence",


  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "SERVICES",
  },
]
export const servicesCard = [
  {
    id: 1,
    cover: "../images/services/c5.png",
    servicesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    projectAll: "$300 All Course",
    projectPer: "$30 per month",
  },
  {
    id: 2,
    cover: "../images/services/c5.png",
    servicesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    projectAll: "$300 All Course",
    projectPer: "$30 per month",
  },
  {
    id: 3,
    cover: "../images/services/c5.png",
    servicesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    projectAll: "$300 All Course",
    projectPer: "$30 per month",
  },
  {
    id: 4,
    cover: "../images/services/c5.png",
    servicesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    projectAll: "$300 All Course",
    projectPer: "$30 per month",
  },
  {
    id: 5,
    cover: "../images/services/c5.png",
    servicesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    projectAll: "$300 All Course",
    projectPer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/services/c6.png",
    servicesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    projectAll: "$500 All Course",
    projectPer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/services/c7.png",
    servicesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Project",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    projectAll: "$20 All Course",
    projectPer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/services/c8.png",
    servicesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    projectAll: "$200 All Course",
    projectPer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/services/c9.png",
    servicesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    projectAll: "$20 All Course",
    projectPer: "$3 per month",
  },
]
export const online = [
  {
    cover: "./images/services/online/assignment.png",
    // hoverCover: "./images/services/online/o9.1.png",
    courseName: "Assignment help (for Graduation & Post-Graduation)",
    // course: "25 Services",
  },
  {
    cover: "./images/services/online/research.png",
    // hoverCover: "./images/services/online/o4.1.png",
    courseName: "Research writing help (For Ph.D students)",
    // course: "25 services",
  },
  {
    cover: "./images/services/online/resume.png",
    // hoverCover: "./images/services/online/o3.1.png",
    courseName: "Resume Writing",
    // course: "10 services",
  },
  {
    cover: "./images/services/online/sop.png",
    // hoverCover: "./images/services/online/o2.1.png",
    courseName: "Cover page and SOP writing",
    // course: "15 services",
  },
  {
    cover: "./images/services/online/web-design.png",
    // hoverCover: "./images/services/online/o5.1.png",
    courseName: "Website Designing & Maintenance",
    // course: "30 Services",
  },
  {
    cover: "./images/services/online/smm.png",
    // hoverCover: "./images/services/online/o8.1.png",
    courseName: "Social Media Marketing",
    // course: "60 Services",
  },
  {
    cover: "./images/services/online/e-tutorial.png",
    // hoverCover: "./images/services/online/o7.1.png",
    courseName: "Video Lectures (E-Tutorials service)",
    // course: "10 Services",
  },
  {
    cover: "./images/services/online/turtin.png",
    // hoverCover: "./images/services/online/o6.1.png",
    courseName: "Turnitin Plagiarism Report Service",
    // course: "30 Services",
  },
  {
    cover: "./images/services/online/journal.png",
    // hoverCover: "./images/services/online/o1.1.png",
    courseName: "Journal Publication",
    // course: "80 Services",
  },
  {
    cover: "./images/services/online/bookpublish.png",
    
    // hoverCover: "./images/services/online/o10.1.png",
    courseName: "Book writing & Publication",
  },
  {
    cover: "./images/services/online/content.png",
    // hoverCover: "./images/services/online/o11.1.png",
    courseName: "Content Writing",
    // course: "17 Services",
  },
  {
    cover: "./images/services/online/video.png",
    // hoverCover: "./images/services/online/o12.1.png",
    courseName: "Video and blog podcast (Interview)",
    // course: "17 Services",
  },
]
export const team = [
  {
    cover: "./images/team/t1.png",
    name: "Divya Bhardwaj",
    work: "CEO & Founder",
  },
  {
    cover: "./images/team/t2.png",
    name: "Akshay Koushik",
    work: "Advisor",
  },
  {
    cover: "./images/team/t3.png",
    name: "Megha Agarwal",
    work: "Research Associate",
  },
  {
    cover: "./images/team/t4.png",
    name: "Kritika",
    work: "Research Associate",
  },
  {
    cover: "./images/team/t5.png",
    name: "Ashish Sharma",
    work: "Chartered Accountant",
  },
  {
    cover: "./images/team/t6.png",
    name: "Anushka Agrawal",
    work: "Web Developer",
  },
  {
    cover: "./images/team/t7.png",
    name: "Mukul Bansal",
    work: "Company Secretary",
  },
  {
    cover: "./images/team/t8.png",
    name: "Sumedha Jaiswal",
    work: "Academic Counselor",
  },
  {
    cover: "./images/team/t9.png",
    name: "Sandeep Kumar Maurya",
    work: "Graphics Designer",
  },
  {
    cover: "./images/team/t10.png",
    name: "Swapnadip Nandi",
    work: "Web Developer",
  },
  {
    cover: "./images/team/t11.png",
    name: "NL Swathi",
    work: "Research Associate",
  },
  {
    cover: "./images/team/t12.png",
    name: "Paras Khola",
    work: "Web Developer",
  },
  {
    cover: "./images/team/t13.png",
    name: "Nirmala",
    work: "Research Associate",
  },
  {
    cover: "./images/team/t14.png",
    name: "Ayesha",
    work: "Research Associate",
  },
  {
    cover: "./images/team/t15.png",
    name: "Shilpi Verma",
    work: "Research Associate",
  },
]
export const service = [
  {
    name: "Academic Assignment Writing",
    service1: "o Unlock Your Potential with Tailored Solutions of DfreeNovelish",
    service2: "o Original Content for Every Assignment",
    service3: "o Expert Writers Across Various Subjects with higher experience",
    service4: "o Guaranteed Authenticity with AI & Plagiarism Reports",
   


  },
  {
    name: "E-Tutorials and Web Development",
    service1: "o Elevate Your Learning Experience with Interactive E-Tutorial",
    service2: "o Customized Web Development for Your Specific Needs",
    service3: "o Engaging User Interfaces for Seamless Navigation",
    service4: "o Transform Your Ideas into Digital Reality",
   
  },
  {
    name: "Web and SMM (Social Media Marketing)",
    service1: "o Expand Your Online Reach with Custom Web Solutions of DfreeNovelish",
    service2: "  o Strategic Social Media Marketing to Amplify Your Digital Presence",
    service3: "o Targeted Campaigns to Connect with Your Audience",
    service4: "o Enhance Your Brand Visibility and Engagement",
  },
  {
    name: "Content Writing (SOP and Resume Writing)",
    service1: "o Craft Your Success Story with Compelling SOPs",
    service2: "o Professionally Written Resumes to Showcase Your Skills",
    service3: "o Personalized Assistance for Your Career Advancement",
    service4: "o Stand Out from the Crowd with Expertly Crafted Content",
  },
]
export const faq = [
  {
    title: "What services does your company offer for academic writing?",
    desc: "Our company offers comprehensive Academic Writing Services that cover a wide range of subjects and academic levels. We can assist you with Essays, Research Papers, Term Papers, Theses, Dissertations and more. Our team of experienced writers ensures high-quality and plagiarism-free content tailored to your requirements.",
  },
  {
    title: "How can your e-tutorial services benefit me?",
    desc: "Our e-tutorial services provide a convenient and effective way to enhance your learning experience. We offer online tutorials and courses on various subjects, delivered by qualified instructors With flexible schedules and interactive sessions, you can learn at your own pace and ask questions directly to the instructors. Our e-tutorials are designed to help you excel academically or gain new knowledge and skills.",
  },
  {
    title: "What makes your content and SOP writing services stand out?",
    desc: "Our Content and SOP writing services are distinguished by their Quality, Precision and Personalized approach. Whether you need engaging Website Content, Compelling Articles or well-crafted Statement of Purpose (SOP) for university applications. Our expert writers are skilled at capturing your unique voice and presenting your ideas effectively. We prioritize Originality, Clarity and adherence to guidelines to ensure your content stands out.",
  },
  {
    title: "How can your resume writing services help me in my job search?",
    desc: "Our professional Resume Writing Services are designed to maximize your chances of securing job interviews. We have a team of experienced resume writers who are well-versed in industry-specific requirements and current trends. They will create a tailored resume that highlights your skills, achievements and qualifications ensuring your application stands out from the competition. We provide resumes that are visually appealing, keyword-optimized, and targeted towards your desired job roles.",
  },
  {
    title: "How can your social media marketing and website development services benefit my business?",
    desc: "Our social media marketing and website development services are aimed at helping your business establish a strong online presence. We develop customized websites that are visually appealing, user-friendly and optimized for search engines. Our social media marketing strategies can help increase your brand visibility, engage with your target audience and drive traffic to your website. We tailor our services to your specific business goals, ensuring a comprehensive digital marketing solution.",
  },

]
export const blog = [
  {
    id: 1,
    type: "dfreenovelish",
    com: "Mar. 09, 2024",
    // : "3 COMMENTS ",
    title: "Celebrating Women Professors in Our E-Magazine",
    desc: "Dive into a world of wisdom, innovation, and inspiration with our groundbreaking e-magazine spotlighting the brilliance of women professors.",
    cover: "../images/blog/b1.png",
  },
  // {
  //   id: 2,
  //   type: "admin",
  //   date: "API. 25, 2022",
  //   com: "5 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b2.webp",
  // },
  // {
  //   id: 3,
  //   type: "user",
  //   date: "MAY. 15, 2022",
  //   com: "10 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b3.webp",
  // },
  // {
  //   id: 4,
  //   type: "admin",
  //   date: "JAN. 02, 2022",
  //   com: "3 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b4.webp",
  // },
  // {
  //   id: 5,
  //   type: "admin",
  //   date: "DEC. 14, 2022",
  //   com: "3 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b5.webp",
  // },
  // {
  //   id: 6,
  //   type: "user",
  //   date: "JAN. 18, 2021",
  //   com: "12 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b6.webp",
  // },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
