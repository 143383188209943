const Issues = [

  {
    pdflinkA: "./IssuePdf/high-frequency-tradding-and-AI.pdf",
   title: "High-Frequency Trading and AI: Implications for Market Volatility",
   Name: "Shubham Singh",
   Position: "Student",
   Email: "shubhamsinghkvp33@gmail.com",
  },
  {
    pdflinkA: "./IssuePdf/enhancing-virtual-reaality-gaming-with-AI-powered-immersive.pdf",
   title: "Enhancing Virtual Reality Gaming with AI-Powered Immersive Experiences",
   Name: "Chandan",
   Position: "Mern full stack developer",
   Email: "devchandan.kushwaha@gmail.com",
  },
  {
    pdflinkA: "./IssuePdf/urbanization-and-sustainabl-development.pdf",
   title: "Image’s text and voice converter into other languages",
   Name: "Vikas",
   Position: "Student",
   Email: "offivikas@gmail.com",
  },
  {
    pdflinkA: "./IssuePdf/the-potentiaal-of-blockchain.pdf",
   title: "The potential of blockchain technology to revolutionize cybersecurity and voting systems",
   Name: "Himanshu Sharma",
   Position: "Student",
   Email: "himanshusharma7593@gmail.com",
  },


]

export  default Issues;