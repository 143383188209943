import React from 'react';

import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';




const Career = () => {

  return (
    <>
    

  
    <section>
 
<section className='back'>
<h1>Career</h1>
</section>
<Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
<div className='margin'></div>
    <div className="container text-light">
      <div className='row'>
       
        <h1>Career @ Dfree Novelish</h1>
      <p>Whether you're a fresh graduate beginning your career or an experienced professional looking to accelerate your career growth, Dfree Novelish can be your platform to complement your career. Join one of India's Leading companies and work in an environment for an exciting career of learning, development and value driven performance linked growth.</p>

      <h2>Find Your Match</h2>
      <p>Find the perfect match for your skills, experience and aspirations. Discover career opportunities with Dfree Novelish Pvt. Ltd.</p>

      <h2>Important Notice</h2>
      <p>Dfree Novelish does not ask for any money from our potential aspirants and / or applicants for recruitment. You or anyone you know should be cautious about any correspondence or person or agency who offers a job/ job interview in return for money.
To read about the Fraud Alert</p>
       
          <p>If that sounds like you, apply directly for a suitable opening by filling in the form below. 
            Also, you can directly mail us - career@dfreenovelish.com</p>
       
      </div>


     
    </div>
    </section>

    </>
  );
};

export default Career;