import React from 'react';
import './service.css'; 
import Marquee from "react-fast-marquee";


  const AWSList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Essay Writing &nbsp;&nbsp;&nbsp;&nbsp;
          • Research Papers  &nbsp;&nbsp;&nbsp;&nbsp;
             • Assignment help &nbsp;&nbsp;&nbsp;&nbsp;
               • Software and technical projects &nbsp;&nbsp;&nbsp;&nbsp;
                • Coursework Assistance  &nbsp;&nbsp;&nbsp;&nbsp;
                 • Proofreading and Editing &nbsp;&nbsp;&nbsp;&nbsp;
                 • Essay Writing &nbsp;&nbsp;&nbsp;&nbsp;
          • Research Papers  &nbsp;&nbsp;&nbsp;&nbsp;
             • Assignment help &nbsp;&nbsp;&nbsp;&nbsp;
          </Marquee>
        </div>
    
    );
  }


export default AWSList;