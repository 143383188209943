import React from "react"
import Heading from "../../Home/comon/heading/Heading"
import "./about.css"
import { homeAbout } from "../dummydata"
import Awrapper from "./Awrapper"
import Image1 from "../../../privateImages/service-file/about1.png"
import Image2 from "../../../privateImages/service-file/about2.png"
import Image3 from "../../../privateImages/service-file/about3.png"
import Image from "../../../privateImages/home-img.png"
import Video from "../../../privateImages/Videos/home-side2.mp4"

const AboutCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className="row">
            <div className='left col-sm-6'>
              {/* <img src={Image} alt='' height="100%" width="50%" /> */}

              <video autoPlay loop muted height="100%" width="80%" >
                <source src={Video} type="video/mp4" />
            </video>
            </div>
            {/* <div className="col-sm-2"></div> */}
            <div className='right col-sm-6'>
              <Heading subtitle='Empowering Hub' title='Experience the Advantages' />
              <div className='items row'>
                <div className='item flexSB row'>
                  <div className='img col-sm-6'>
                    <img src={Image1} alt='image-1' height="100%" width="100%" />
                  </div>
                  <div className='text col-sm-6 ' style={{ textAlign: "left" }} >
                    <h2 className="abouthead">Academic Excellence:</h2>
                    <p>
                      <ul>
                        <li>o Tailored writing solutions</li>
                        <li>o Expert guidance</li>
                        <li> o Turnitin reports for authenticity</li>

                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div className='items row'>
                <div className='item flexSB row'>
                  <div className='img col-sm-6'>
                    <img src={Image2} alt='image-1' height="100%" width="100%" />
                  </div>
                  <div className='text col-sm-6' style={{ textAlign: "left" }} >
                    <h2 className="abouthead">Digital Advancement:</h2>
                    <p>
                      <ul>
                        <li>o Customized web development</li>
                        <li>o Innovative e-tutorials</li>
                        <li>o Strategic social media marketing</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>


              <div className='items row'>
                <div className='item flexSB row'>
                  <div className='img col-sm-6'>
                    <img src={Image3} alt='image-1' height="80%" width="70%" />
                  </div>
                  <div className='text col-sm-6' style={{ textAlign: "left" }} >
                    <h2 className="abouthead">Professional Enhancement:</h2>
                    <p>
                      <ul>
                        <li>o SOP and resume writing</li>
                        <li>o Elevating academic journey</li>
                        <li>o Igniting digital presence</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* <Awrapper /> */}
    </>
  )
}

export default AboutCard
