import React from "react"
import OnlineServices from "../allServices/OnlineServices"
import Heading from "../comon/heading/Heading"
import "../allServices/services.css"
import { servicesCard } from "../dummydata"
import ProjectCard from "../allServices/ServicesCard"

const HAbout = () => {
  return (
    <>
      <section className='homeAbout'>
        <div className='container' style={{marginTop:'5%'}}>
          <Heading subtitle='our services' title='explore our popular online services' />
          <div className='price container grid mb-5'>
        {/* <ProjectCard />   */}
       </div>
          {/* <div className='servicesCard'>
            {/* copy code form  servicesCard 
            <div className='grid2'>
              {servicesCard.slice(0, 3).map((val) => (
                <div className='items'>
                  <div className='content flex'>
                    <div className='left'>
                      <div className='img'>
                        <img src={val.cover} alt='' />
                      </div>
                    </div>
                    <div className='text'>
                      <h1>{val.servicesName}</h1>
                      <div className='rate'>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <label htmlFor=''>(5.0)</label>
                      </div>
                      <div className='details'>
                        {val.courTeacher.map((details) => (
                          <>
                            <div className='box'>
                              <div className='dimg'>
                                <img src={details.dcover} alt='' />
                              </div>
                              <div className='para'>
                                <h4>{details.name}</h4>
                              </div>
                            </div>
                            <span>{details.totalTime}</span>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='price'>
                    <h3>
                      {val.priceAll} / {val.pricePer}
                    </h3>
                  </div>
                  <button className='outline-btn'>ENROLL NOW !</button>
                </div>
              ))}
            </div> */}
          </div>
        {/* </div> */}
        {/* <OnlineServices /> */}
       
 
      </section>
    </>
  )
}

export default HAbout;