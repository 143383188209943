// import React, { useState } from 'react';
// import Issuedata from './IssueData';
// import "./current.css"
// import { Link } from 'react-router-dom';
// import Issues from "./Issues"


// const CurrentIssue = () => {

//   const [showIssue1, setShowIssue1] = useState(false); // State to keep track of whether Issue1 is clicked or not
//   const [showIssue2, setShowIssue2] = useState(false); // second issue


//   const handleIssue1Click = () => {
//     setShowIssue1(true);
//     setShowIssue2(false);
//   };


//   const handleIssue2Click = () => {
//     setShowIssue2(true);
//     setShowIssue1(false);
//   };

//   return (
//     <>
//       <section className="wrapper">
//         <div className="container">
//           <div className="container">
//             <div className="row">
//               <div className="col-sm-4  w-100 mt-3 project-card project-1 text-center">
//                 <li className=" dropdown text-light">
//                   <li class="dropbtn fs-1 text-center" >
//                     Volume 1
//                   </li>

//                   <ul className=" dropdown-content about-dropdown text-center ">
//                     <li className="about-drop fs-3 issue-one-main">
//                       <Link onClick={handleIssue1Click}>Issue1</Link>
//                     </li>

//                     <li className="about-drop fs-3" >
//                       <Link onClick={handleIssue2Click}>Issue2</Link>
//                     </li>
//                   </ul>
//                 </li>
//               </div>
//             </div>

//             <div className='row issue1' style={{ display: showIssue1 ? 'block' : 'none' }}>


//               {Issuedata.map((issue, indexs) => (
//                 <div key={indexs} className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                   <div className="container">
//                     <div className="row">
//                       <div className="col-sm-4  w-100 mt-3 project-card project-1 text-center">
//                         <h2 className='text-light'>{issue.title}</h2>
//                         <h4 className="card-meta mb-2 text-light">{issue.Name}</h4>
//                         <h4 className=" mt-0 text-start text-light text-center">{issue.Position}</h4>
//                         <h6 className='text-light text-center'>{issue.Email}</h6>

//                         <div className="card-footer d-flex justify-content-center">
//                           <a
//                             href={issue.pdflinkA}
//                             className="btn btn-primary"
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             download
//                           >
//                             Download file
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}

//             </div>



//             <div className='row issue2' style={{ display: showIssue2 ? 'block' : 'none' }}>


//               {Issues.map((issues, indexes) => (
//                 <div key={indexes} className="col-sm-12 col-md-6 col-lg-4 mb-4">
//                   <div className="container">
//                     <div className="row">
//                       <div className="col-sm-4  w-100 mt-3 project-card project-1 text-center">
//                         <h2 className='text-light'>{issues.title}</h2>
//                         <h4 className="card-meta mb-2 text-light">{issues.Name}</h4>
//                         <h4 className=" mt-0 text-start text-light text-center">{issues.Position}</h4>
//                         <h6 className='text-light text-center'>{issues.Email}</h6>

//                         <div className="card-footer d-flex justify-content-center">
//                           <a
//                             href={issues.pdflinkA}
//                             className="btn btn-primary"
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             download
//                           >
//                             Download file
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}

//             </div>




//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default CurrentIssue;






import React, { useState } from 'react';
import IssueData from './IssueData';
import Issues from "./Issues"
import { Link } from 'react-router-dom';

const CurrentIssue = () => {
  const [showIssue1, setShowIssue1] = useState(false);
  const [showIssue2, setShowIssue2] = useState(false);

  const handleShowIssue1 = () => {
    setShowIssue1(true);
    setShowIssue2(false);
  };

  const handleShowIssue2 = () => {
    setShowIssue1(false);
    setShowIssue2(true);
  };

  return (
    <>

      <section className="wrapper">

        <div className="container">


          <div className="row">
            <div className="col-sm-4 mt-3  text-center">
              <li className=" dropdown text-light">
                <li class="dropbtn fs-4 " >
                  Volume 1
                </li>

                <ul className=" dropdown-content about-dropdown text-center  ">
                  <li className="about-drop fs-5 issue-one-main">
                    <Link onClick={handleShowIssue1}>Issue1</Link>
                  </li>

                  <li className="about-drop fs-5" >
                    <Link onClick={handleShowIssue2}>Issue2</Link>
                  </li>
                </ul>
              </li>
            </div>

            <section id="wrapper mt-5">
              <div className="container">
            

                  {/* Issue 1 */}
                  <div className=' issue1' style={{ display: showIssue1 ? 'block' : 'none' }}>

                    {/* <div className="container"> */}
                    <div className="row d-flex justify-content-center justify-items-center gap-3">
                      {IssueData.map((issue) => (

                        <div className="col-sm-4 mt-3 project-card project-1 text-center">
                          <h2 className='text-light'>{issue.title}</h2>
                          <h4 className="card-meta mb-2 text-light">{issue.Name}</h4>
                          <h4 className="mt-0 text-start text-light text-center">{issue.Position}</h4>
                          <h6 className='text-light text-center'>{issue.Email}</h6>

                          <div className="card-footer d-flex justify-content-center">
                            <a
                              href={issue.pdflinkA}
                              className="btn btn-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              Download file
                            </a>
                          </div>
                        </div>

                      ))}
                    </div>
                    {/* </div> */}
              

                </div>
              </div>
            </section>
            {/* Issue 2 */}
            <div className=' issue2' style={{ display: showIssue2 ? 'block' : 'none' }}>
           
                  <div className="container">
                    <div className="row d-flex justify-content-center justify-items-center gap-3">
              {Issues.map((issues) => (
           
                      <div className="col-sm-4  mt-3 project-card project-1 text-center">
                        <h2 className='text-light'>{issues.title}</h2>
                        <h4 className="card-meta mb-2 text-light">{issues.Name}</h4>
                        <h4 className="mt-0 text-start text-light text-center">{issues.Position}</h4>
                        <h6 className='text-light text-center'>{issues.Email}</h6>

                        <div className="card-footer  justify-content-center">
                          <a
                            href={issues.pdflinkA}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            Download file
                          </a>
                        </div>
                      </div>
                  
              ))}

            </div>
            </div>
                  </div>
               
          </div>
        </div>
      </section>

    </>
  );
};

export default CurrentIssue;