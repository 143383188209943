import React from 'react';
import '../AWS/service.css';
import Marquee from "react-fast-marquee";


const WebList = () => {
  return (
    <div className="flatlist-container awsassistpara">
      <h1 className='AWSListHead'>Our Range of Services</h1>
      <Marquee>
  •       Custom Website Design&nbsp;&nbsp;&nbsp;&nbsp;
 • E- commerce Website Development&nbsp;&nbsp;&nbsp;&nbsp;
• Responsive Website Development&nbsp;&nbsp;&nbsp;&nbsp;
• Website Redesign and Updates&nbsp;&nbsp;&nbsp;&nbsp;
• Ongoing Website Maintenance&nbsp;&nbsp;&nbsp;&nbsp;
• E- commerce Website Development&nbsp;&nbsp;&nbsp;&nbsp;
      </Marquee>
    </div>

  );
}


export default WebList;