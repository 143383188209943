import React from "react";
import Back from "../comon/back/Back";
import Certificate1 from "../../../privateImages/Certificates/certificate1.jpg"
import Certificate2 from "../../../privateImages/Certificates/certificate2.jpg"
import Certificate3 from "../../../privateImages/Certificates/certificate3.jpg"
import Certificate4 from "../../../privateImages/Certificates/certificate4.jpg"
import "./certificate.css"

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";







const Certificates = () => {

    return (
        <>

            <section className='text-light text-center mt-5 mx-5'>
            <h1 style={{fontSize:"100px"}}>Awards & Certificates</h1>
            <h4>At DfreeNovelish, we celebrate excellence and achievement through our awards and certificates. From recognizing academic milestones to honoring outstanding digital marketing campaigns and innovative contributions to web development, our awards highlight dedication and hard work. Whether it's academic excellence, digital marketing success, 
                or web development innovation, we aim to inspire and empower individuals and businesses to reach new heights of success.</h4>
           
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin' style={{marginTop:"20%"}} ></div>
            <div className="container-fluid our-container  py-5 text-light">
          
                <div className="row mb-5">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4 mt-2">
                        <img src={Certificate1} className="certificate-img" />
                    </div>
                    <div className="col-sm-4 mt-2">
                        <img src={Certificate2} className="certificate-img" />
                        </div>
                        <div className="col-sm-2"></div>
                    
                </div>
                <div className="row">

                <div className="col-sm-2"></div>
                <div className="col-sm-4 mt-2">
                <img src={Certificate3} className="certificate-img" />
            </div>
            <div className="col-sm-4 mt-2">
                <img src={Certificate4} className="certificate-img" />
            </div>
            <div className="col-sm-2"></div>
            </div>
        
            </div>
        
          
        </>
    )
}
export default Certificates;