import React from 'react';

import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';


const Terms = () => {
  return (
    <>
  
    <section>
 
<section className='back'>
<h1>Terms & Conditions</h1>
</section>
<Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
<div className='margin'></div>
    <div className="container text-light">

      <h1>Introduction</h1>
      <p> Welcome to DFreeNovelish! These Terms & Conditions ("Terms") are designed to govern your use of our website. By accessing and using our website, you agree to comply with these Terms. Please read them carefully.</p>

      <h2>User Eligibility</h2>
      <p>You must be of a certain age or have the legal capacity to use our website. Additionally, access to our website might be restricted based on your location or jurisdiction. It's your responsibility to ensure compliance with these requirements.</p>

      <h2>User Obligations</h2>
      <p>You are allowed to access and use our website for personal, non-commercial purposes. However, you are not allowed to engage in activities such as unauthorized access, data scraping, spamming, or any other activities that may disrupt or harm our website's functionality. If the website allows user-generated content, you agree to follow the guidelines set forth for such content.</p>

      <h2>Intellectual Property</h2>
      <p>We own the content on our website, including text, images, videos, and trademarks, unless otherwise indicated. You may not use, reproduce, modify, or distribute our content without our explicit permission.</p>

      <h2>Privacy Policy</h2>
      <p>Please refer to our Privacy Policy, which outlines how we collect, use, and protect user data. Your use of our website indicates your acceptance of our Privacy Policy.</p>

      <h2>Third-Party Links</h2>
      <p>Our website may contain links to third-party websites beyond our control. Interactions with these third-party sites are not governed by our Terms. We do not endorse or take responsibility for the content or practices of these external sites.</p>

      <h2>Disclaimer of Liability</h2>
      <p>Your use of our website is at your own risk. We do not guarantee the accuracy, completeness, or reliability of the content on our website. We are not liable for any damage arising from your use of our website.</p>

      <h2>Limitation of Liability</h2>
      <p>To the extent permitted by law, we limit our liability for any direct, indirect, incidental, or consequential damages arising from your use of our website. This includes any losses, damage, or expenses incurred.</p>

      <h2>Indemnification</h2>
      <p>By using our website, you agree to indemnify us from any claims, damages, or liabilities that may arise because of your use of the website or your violation of these Terms.</p>

      <h2>Termination</h2>
      <p>We reserve the right to terminate your access to our website if you violate these Terms or engage in activities that harm our website. If your access is terminated, you must cease using our website immediately.</p>

      <h2>Changes to Terms</h2>
      <p>We may update or change these Terms at any time. Changes will be posted on our website, and it is your responsibility to review them periodically. Your continued use of our website after changes are posted indicates your acceptance of the modified Terms.</p>

      <h2>Governing Law and Jurisdiction</h2>
      <p>Any legal disputes will be handled in the jurisdiction specified in our Terms. The interpretation of these Terms will be governed by the laws of the chosen jurisdiction.</p>

      <h2>Contact Information</h2>
      <p>If you have any questions, concerns, or inquiries regarding these Terms or our website, you can contact us at <a href="mailto:info@dfreenovelish.com">info@dfreenovelish.com</a>.</p>
    </div>
    </section>
    </>
  );
};

export default Terms;