import React from "react";
import { Link } from "react-router-dom";




const MainServices = () => {
  
    return (
        <>
      
            <div className="MainServices container">
                <div class="mainservicecard">
                    <p class="mainservicecard-title">Assignment Help Services</p>
                    <p class="mainservicecard-des" >
                    Ace your assignments with our tailored solutions crafted by subject-matter experts, ensuring top grades and academic success.
                    </p>
               
                </div>
                <div class="mainservicecard ">
                    <p class="mainservicecard-title">Resume, SOP Writing, etc</p>
                    <p class="mainservicecard-des">
                    Secure your dream job or academic opportunity with compelling resumes and statement of purpose crafted by industry professionals.
                    </p>
               
                </div>


                <div class="mainservicecard">
                    <p class="mainservicecard-title">Social Media Marketing</p>
                    <p class="mainservicecard-des">
                    Amplify your brand's online presence and engagement with our strategic social media marketing solutions, driving growth and visibility.
                    </p>
            
                </div>

            </div>

            <div className="MainServices container">
                <div class="mainservicecard">
                    <p class="mainservicecard-title">Turnitin Plagiarism Report</p>
                    <p class="mainservicecard-des">
                    Ensure originality and academic integrity with our Turnitin plagiarism reports, providing comprehensive analysis and peace of mind.
                    </p>
               
                </div>
                <div class="mainservicecard">
                    <p class="mainservicecard-title">E-learning Service</p>
                    <p class="mainservicecard-des">
                    Embrace the future of learning with our innovative e-learning services, offering interactive and engaging courses tailored to your needs.
                    </p>
                
                </div>

                <div class="mainservicecard">
                    <p class="mainservicecard-title">Website Development</p>
                    <p class="mainservicecard-des">
                    Make a lasting impression online with our bespoke website development services, combining creativity and functionality for a standout digital presence.
                    </p>
                </div>

            </div>
          
        </>
    );
}
export default MainServices;