import React from "react";

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

import Image from "../../../privateImages/Team/divya2.png"
import "./chairman.css"




const Chairman = () => {
   

    return (
        <>


        
            <section className='text-light text-center mx-5' style={{ marginTop: "15%" }}>
                <h1 style={{ fontSize: "100px" }} className="mt-5">Chairman's Message</h1>

            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
                style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
                <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>

            <div className='margin' style={{marginTop:"20%"}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-7 mt-1 text-light ">
                        {/* <h1 style={{ fontFamily: "revert", fontSize:"50px", textAlign:"end" }}>CHAIRMAN'S</h1>
                        <h3 style={{ fontFamily: "cursive", rotate: "revert", fontSize:"40px", textAlign:"end" }}>Message</h3> */}
                        <h2 className=" mb-4" style={{ fontSize: "50px" }}>Welcome to DfreeNovelish</h2>
                        <p>As the Chairman of DfreeNovelish, I am honored to extend a warm welcome to all our visitors. Our organization,
                            dedicated to providing comprehensive academic assistance
                            and business maintenance solutions, stands on the pillars of integrity, innovation, and excellence</p>
                        <p className="mt-2">In the ever-evolving landscape of academia and business, we recognize the challenges that students, educators,
                            and businesses face daily. Our mission is to support and empower our clients by offering tailored services that not only
                            meet their needs but exceed their expectations. Whether it's through our academic help, which ensures students achieve their
                            educational goals, or our business maintenance services,      designed to keep operations running smoothly and efficiently, we are committed to your success.</p>
                    </div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-4" style={{ alignItems: "end" }}>
                        <div className="image-double-border">
                            <img src={Image} alt="image" className="chairman-image" height="90%" width="90%" />
                        </div>
                    </div>
                    {/* <div className="col-sm-2"></div>
                    <div className="col-sm-6  message-block">
                        <h2>Welcome to DfreeNovelish</h2>
                        <p>As the Chairman of DfreeNovelish, I am honored to extend a warm welcome to all our visitors. Our organization,
                            dedicated to providing comprehensive academic assistance
                            and business maintenance solutions, stands on the pillars of integrity, innovation, and excellence</p>
                            <p>In the ever-evolving landscape of academia and business, we recognize the challenges that students, educators,
                                 and businesses face daily. Our mission is to support and empower our clients by offering tailored services that not only
                                  meet their needs but exceed their expectations. Whether it's through our academic help, which ensures students achieve their
                                   educational goals, or our business maintenance services, 
                                designed to keep operations running smoothly and efficiently, we are committed to your success.</p>
                    </div> */}
                </div>
                <div className="row">
                    <div className="text-light mt-2">



                        <p>Our team of professionals brings a wealth of knowledge and experience to the table, ensuring that each service we provide is of the highest standard. We believe
                            in continuous improvement and are constantly seeking new ways to enhance our offerings and deliver greater value to our clients.</p>

                        <p>AtDfreeNovelish, we understand the importance of trust and reliability. These core values drive our interactions and form the foundation
                            of our long-term relationships with clients. We are proud of the positive
                            impact we have made in the lives of individuals and businesses alike, and we look forward to continuing this journey with you.</p>

                        <p>Thank you for choosing DfreeNovelish, We are here to support you every step of the way.</p>
                        <div className="chairman-bottom" >

                            <p style={{ fontFamily: "cursive" }}>Warm regards,</p>
                            <p style={{ fontFamily: "cursive" }}> Divya Bhardwaj</p>
                            <p style={{ fontFamily: "cursive" }}>  Chairman (CEO & Founder, Current Director)</p>
                            <p style={{ fontFamily: "cursive" }}> DfreeNovelish (opc) pvt. Ltd. </p>
                        </div>


                    </div>
                </div>
            </div>
   
        </>
    )
}

export default Chairman