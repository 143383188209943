import React from "react"
import { blog } from "../../dummydata"
import "./footer.css"
import Logo from "../../../../privateImages/logo.png"
import BottomFooter from "../../../../../src/footer/footer.png"
import { Link } from "react-router-dom"
import FooterImg from "../../../../privateImages/footer-img.png"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa"

// import FooterImage from "../../"

const Footer = () => {
  const handleFooterClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <section className="footer mt-5">
        <div className="container mt-0 ">
          <div className="row">
            <div className="col-sm-3">
              <div>
                  <img src={Logo} alt="logo" className="mb-2" style={{ height: '80%', width: "80%" }} />
                  <h6 className="mt-0">Illuminating Minds, Nurturing Future</h6>
                  <img src={BottomFooter} alt="footer" className="footer-bottom-img mt-3" style={{ height: '80%', width: "80%" }} />
                </div>
              <div>
           
              </div>
              <div className="mt-3">
              <Link to="https://www.facebook.com/dfreenovelish?mibextid=ZbWKwL" style={{textDecoration:"none"}}><FaFacebookF className="facebook social-media-icon" /></Link>
              <Link to="https://www.instagram.com/dfreenovelish?igsh=MWVzdzZqdDMxcXJsNQ==" style={{textDecoration:"none"}}>  <FaInstagram className="insta social-media-icon" /></Link>
              <Link to="https://in.pinterest.com/dfreenovelish/" style={{textDecoration:"none"}}>   <FaPinterestP className="pinterest social-media-icon" /></Link>
              <Link to="https://www.linkedin.com/company/dfreenovelish/" style={{textDecoration:"none"}}>  <FaLinkedinIn className="linkedin social-media-icon" /></Link>
              <Link to="https://twitter.com/Dfreenovelish" style={{textDecoration:"none"}}>   <FaTwitter className="twitter social-media-icon" /></Link>
              <Link to="https://www.youtube.com/@DfreeNovelish" style={{textDecoration:"none"}}>      <FaYoutube name="youtube" size={24} color="red" className=" social-media-icon" /></Link>

              
              </div>
              
             

            </div>
            <div className="col-sm-9">
              <div className="container">
                <div className="row">
                  {/* <div className="col-sm-1"></div>  */}

                  <div className="col-sm-4">
                    <ul className="footer-list">
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/" className="child-footer">Home</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/about" className="child-footer">About Us</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/services" className="child-footer">Services</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/resource" className="child-footer">Resources</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/project" className="child-footer">Projects</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/journal" className="child-footer">Journal Publication</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/events-conferences" className="child-footer">Events & Conferences</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/contact" className="child-footer">Contact Us</Link>
                      </li>
                   
                    </ul>
                  </div>
                  <div className="col-sm-4 ">
                    <ul className="footer-list">
                    <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/aws" className="child-footer"> Academic Assignment Help</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/research" className="child-footer">Research Writing Help</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/resume" className="child-footer">Resume Writing</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/sop" className="child-footer">Cover Page and SOP Writing</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/web" className="child-footer">Website Development</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/smm" className="child-footer">Social Media Marketing</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/videolecture" className="child-footer">Video Lecture</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/turtin" className="child-footer">Turnitin Plagiarism Report</Link>
                      </li>
                    
      

                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <ul className="footer-list" >
                    <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/book" className="child-footer">Book Writing</Link>
                      </li>
                    <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/content" className="child-footer">Content Writing</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/videoblog" className="child-footer">Video & Blog</Link>
                      </li>
                     
                    <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/team" className="child-footer">Our Team</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/advisor" className="child-footer">Our Advisor</Link>
                      </li>
                 
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/career" className="child-footer">Career</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/privacy" className="child-footer" >Privacy & Policy</Link>
                      </li>
                      <li className="my-2" onClick={handleFooterClick}>
                        <Link to="/term" className="child-footer">Terms & Conditions</Link>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <h5 className="text-center  " style={{marginTop:"5%"}}>Copyright©️2024 DfreeNovelish®️</h5>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer

