import React from "react";
import "./guide.css"
import Line from "../../../privateImages/Back-Images/line.png"
import Line2 from "../../../privateImages/Back-Images/line2.png"
import Line3 from "../../../privateImages/Back-Images/line3.png"
const Guide = () => {
    return (
        <>
            <section className="text-light mt-5">
                <div className="container">
                    <h1 className="journal-head">WELCOME TO DFREENOVELISH GUIDE TO AUTHOR</h1>
                    <p className="mt-5 journal-para">
                        This guide aims to assist authors in preparing and submitting manuscripts for publication. Following
                        these guidelines will help streamline the review process and improve the chances of
                        acceptance.
                    </p>
                    <p className="mt-3 journal-para">
                        The DfreeNovelish Journal publication publishes cutting-edge research across various
                        scientific disciplines, including but not limited to biology, chemistry, physics, and
                        engineering, multidisciplinary etc. Our target audience includes researchers, academics,
                        and professionals interested in the latest advancements and discoveries.
                    </p>
                    <p className="mt-3 journal-para">
                        Our editorial board comprises leading experts in various fields of science. The board is
                        dedicated to maintaining the highest standards of academic integrity and scientific rigor.


                    </p>

                </div>


                <div className="container mt-5">
                    <h1 className="journal-head guide-head">Submission Process</h1>
                    <h4 className="text-center">Manuscripts should be submitted through our online submission system. Before submissioon you must check.... </h4>
                    <div className="row mt-5">
                        <div className="col-sm-3 mt-1">
                            <div className="journal-childCard">
                                <h4> Original and unpublished work</h4>
                            </div>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <div className="journal-childCard">
                                <h3>Adherence to formatting guidelines</h3>
                            </div>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <div className="journal-childCard">
                                <h3>Conflict of interest disclosure</h3>
                            </div>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <div className="journal-childCard">
                                <h3>Ethical compliance statements</h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="containe guide-head">
                    <h1 className="journal-head">MANUSCRIPT PREPRATION</h1>
                    <h3 className="text-center mt-5">Formatting Guidelines</h3>
                    <div className="row mt-5">



                        <div className="col-sm-3">
                            <div class="guide-card guide-card8">File Format</div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card1">Text Formatting</div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card2">Title Page</div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card3">Abstract </div>
                        </div>

                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-3">
                            <div class="guide-card guide-card4">Main Text </div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card5">References</div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card6">Figures & Tables</div>
                        </div>
                        <div className="col-sm-3">
                            <div class="guide-card guide-card7">Supplementary </div>
                        </div>

                    </div>
                </div>


                <div className="container">
                    <h1 className="journal-head guide-head"> Ethical Considerations</h1>
                    <div className="row left">
                        <h2>Authorship Criteria</h2>
                        <h4>All authors should have made significant contributions to the study. Include a contribution
                            statement for each author.
                        </h4>
                    </div>
                    <div className="row right-guide">
                        <h2>Conflict of Interest</h2>
                        <h4>Authors must disclose any financial or personal relationships that could influence their
                            work.</h4>
                    </div>
                    <div className="row left">
                        <h2>Plagiarism</h2>
                        <h4>The journal uses plagiarism detection software. Manuscripts with a similarity index above
                            10% will be rejected.</h4>
                    </div>
                    <div className="row right-guide">
                        <h2>Human and Animal Rights</h2>
                        <h4>Research involving human subjects must adhere to the Declaration of Helsinki. Animal
                            studies should comply with institutional and national guidelines.</h4>
                    </div>

                    <div className="row">
                        <h2>Informed Consent</h2>
                        <h4>For studies involving human participants, a statement of informed consent must be
                            included.</h4>
                    </div>
                </div>


                <div className="container">
                    <h1 className="journal-head guide-head">Peer Review Process</h1>
                    <div className="row mt-5">
                        <div className="col-sm-4 guide-peer">
                            <h3>
                                Review Policy
                            </h3>
                            <p>We follow a double-blind peer review process to ensure unbiased evaluations.</p>
                        </div>
                        <div className="col-sm-4 guide-peer">
                            <h3>Review Criteria</h3>
                            <p>Reviewers will assess the manuscript’s originality, methodology, significance, and clarity.
                            </p>
                        </div>
                        <div className="col-sm-4 guide-peer">
                            <h3>Revision Process</h3>
                            <p>Authors will receive reviewer comments and have the opportunity to revise and resubmit
                                their manuscripts.</p>
                        </div>
                    </div>
                </div>



                <div className="container">
                    <h1 className="journal-head guide-head">Publication Process</h1>
                    <div className="row text-center mt-5">


                        <div className="row">
                            <div className="col-sm-4">
                                <h3>Acceptance Notification</h3>
                                <p>Upon acceptance, authors will be notified via email. The manuscript will proceed to the
                                    proofing stage.</p>
                            </div>
                            <div className="col-sm-4 text-center">
                                <img src={Line} alt="line" width="60%" />
                            </div>
                            <div className="col-sm-4">
                                <h3>Proofs</h3>
                                <p>Authors are responsible for reviewing the proofs and making any necessary corrections
                                    within 48 hours.</p>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-4">

                            </div>
                            <div className="col-sm-4 text-center">
                             
                            </div>
                            <div className="col-sm-4">
                                <img src={Line2} alt="line" width="60px" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                            <h3>Copyright and Licensing</h3>
                        <p>Authors must sign a copyright transfer agreement. We offer options for Creative Commons
                            licensing.</p>
                            </div>
                            <div className="col-sm-4 text-center">
                            <img src={Line3} alt="line" width="60%" />
                            </div>
                            <div className="col-sm-4">
                            <h3>Publication Fees</h3>
                        <p>There are no submission fees. However, an article processing charge (APC) of INR1000
                            applies to accepted manuscripts.</p>
                            </div>
                        </div>
                    
                    </div>
                </div>



                <div className="container">
                <h1 className="journal-head guide-head">Post-Publication</h1>
                <div className="row">
                    <h3 className="mt-5">Corrections and Retractions</h3>
                    <h5>Post-publication corrections can be requested by contacting the editorial office. 
Retractions will be issued for serious ethical violations.
</h5>
<h3 className="mt-5">Promotion</h3>
<h5>Authors are encouraged to share their work on social media and institutional repositories. 
Our journal will also promote articles through press releases and newsletters.</h5>
                </div>
                </div>

                <div className="container">
                <h1 className="journal-head guide-head">Contact Information</h1>
                    <div className="row">
                        <h3 className="text-center">For assistance, please contact:</h3>
                        <h3 className="text-center">General Assistance: info@dfreenovelish.com</h3>
                    </div>
                </div>
            </section>



        </>
    )
}
export default Guide;