import React from "react";

import Marquee from "react-fast-marquee";
import Carousel from 'react-bootstrap/Carousel';
import Image from '../../../privateImages/carousel.jpg';
import Client1 from "../../../privateImages/Clients/Chouhan-builders.png"
import Client2 from "../../../privateImages/Clients/paint.png"
import Client3 from "../../../privateImages/Clients/institute.png"
import "./client.css"
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";






const Client = () => {
  

    return (
        <>
      

        
            <section className='text-center back'>
                <h1 className=" client-head text-light mt-4">Our Client</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
            <div className='margin'></div>


            <section>
                <div className="container px-5">
                    <div className="row ">

                        <div className="col-sm-3 text-light client-card mx-2">
                            <img src={Client1} alt="client" className="px-3" width="100%" />
                            <h3 className="px-3">Chouhan Builders & Midlands</h3>
                            <p className="px-3">"Explore our UK client's construction website showcasing quality building projects."</p>
                            <a href="https://chouhanbuilders.co.uk/" className="view mx-3 px-3" target="_blank">View Website</a>
                        </div>
                        <div className="col-sm-1 mb-3"></div>
                        <div className="col-sm-3 mt-5 text-light client-card">
                            <img src={Client2} alt="client" className="px-3 mt-5" width="100%" />
                            <h3 className="mt-4 px-3">Krishna Paints</h3>
                            <p className="px-3">"Discover our project highlighting quality paint products from Krishna Paints on their website."</p>

                            <a href="https://krishnapaints.online/" className="view mx-3 px-3" target="_blank">View Website</a>
                        </div>
                        <div className="col-sm-1 mt-1"></div>
                        <div className="col-sm-3 text-light client-card">
                            <img src={Client3} alt="client" className="px-3 mt-5" width="100%"  />
                            <h3 className="mt-4 px-3">IUIRCPSDTI Insitute</h3>
                            <p className="px-3">"Browse our project featuring an esteemed institute on this website for University IU UIRCPSDTI."</p>

                            <a href="https://universityiuircpsdti.com/" className="view mx-3 px-3" target="_blank">View Website</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-5">
                <div className="container mt-5">

            
                <h1 className="text-center text-light">Reviews</h1>

<div className="client-scroll-main">
                <Carousel className="text-center text-light">
                    <Carousel.Item interval={2000} className="my-5 client-scroll">

                        <h3>Nikita Thakur</h3>
                        <p>The team is really supportive, sincere and dedicated towards work accomplishment. Recommendable for the people who want authentic work on time</p>

                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="my-5 client-scroll ">

                        <h3>Chandan</h3>
                        <p>"D-Free exceeded my expectations with their impeccable service. From start to finish, their team demonstrated professionalism and efficiency.<br /> Their innovative solutions solved my problems seamlessly, and their attention to detail was unparalleled. I highly recommend<br /> D-Free to anyone seeking top-notch quality and reliability."
                            .</p>

                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="my-5 client-scroll">

                        <h3>Raees Ahsan Wani</h3>
                        <p>
                            A great place to learn and grow! I'm impressed by the wide coverage and high professionalism of Dfreenovelish. I recommend all the related audience <br /> to avail the great expertise, vast experience and field knowledge of this vibrant platform.
                        </p>

                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="my-5 client-scroll">

                        <h3>Divyanshu Bhardwaj</h3>
                        <p>
                            Great service.. highly recommended 😃
                        </p>

                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="my-5 client-scroll">

<h3>Devender Shastri</h3>
<p>
मुझे आपका लेखन कार्य बहुत बहुत अच्छा लगा
🕉️ ❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️🕉️
दिव्या भारद्वाज जी आपका बहुत बहुत धन्यवाद
</p>

</Carousel.Item>
                </Carousel>
                </div>
                </div>
            </section>
       
        </>
    )
}
export default Client