import React from "react";
import Marquee from "react-fast-marquee";

const Testimonial = () =>{
    return(
        <>
        <Marquee>

</Marquee>
        </>
    )
}

export default Testimonial;