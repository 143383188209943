import React from 'react';
import "../AWS/service.css"; 
import Marquee from "react-fast-marquee";


  const ResearchWrittingList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
    • Research Proposal Writing&nbsp;&nbsp;&nbsp;&nbsp;
                 • Dissertation Writing&nbsp;&nbsp;&nbsp;&nbsp;
             • Thesis Writing&nbsp;&nbsp;&nbsp;&nbsp;
              • Literature Review Writing&nbsp;&nbsp;&nbsp;&nbsp;
               • Data Analysis&nbsp;&nbsp;&nbsp;&nbsp;
     • Proofreading and Editing&nbsp;&nbsp;&nbsp;&nbsp;
        • Research Proposal Writing&nbsp;&nbsp;&nbsp;&nbsp;
              • Dissertation Writing&nbsp;&nbsp;&nbsp;&nbsp;
             • Thesis Writing&nbsp;&nbsp;&nbsp;&nbsp;
               • Literature Review Writing&nbsp;&nbsp;&nbsp;&nbsp;
             • Data Analysis&nbsp;&nbsp;&nbsp;&nbsp;
                 • Proofreading and Editing&nbsp;&nbsp;&nbsp;&nbsp;
          </Marquee>
        </div>
    
    );
  }


export default ResearchWrittingList;