import React from "react"
import "../Journal/journal.css"
import { blog } from "../dummydata"
import Heading from "../comon/heading/Heading"
// import Image from "../../../../public/images/blog/b1.png"
import Event1 from "../../../privateImages/Event/event-1.jpg"
import Event2 from "../../../privateImages/Event/event-2.png"
import Event3 from "../../../privateImages/Event/event-3.jpg"
import { FaCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import Marquee from "react-fast-marquee";

import { Link } from "react-router-dom"









const Event = () => {
 

  const redirectToGoogleEvent = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLScCGNcSeyWW3dDI-O3rJsCxyiKVEqYXsqGLpx_gbqo90viRUw/viewform?usp=sf_link ", "_blank");
  };
  const secondEvent = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSca2Rane5wVkL_ElvfevsBwU89xLAgcY0J8invQPIATu49Aig/viewform?usp=sf_link", "_blank");
  };
  const thirdEvent = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSe6rN2yZ5pot4IYaxeBrxdyl4JW3RxEzs48jRFJusRVEJV73w/viewform?usp=sf_link ", "_blank");
  };
  return (
    <>
    
    

      <section className='back'>

        <h1 className="research-head">Events & Conferences</h1>
      </section>
      <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin'></div>
      <section className="mb-5 mt-5">
        <h4 className="text-light mb-5 text-center  mt-5">At DfreeNovelish, our events and conferences are designed to inspire and connect individuals across academia and business, offering educational workshops led by expert tutors, business growth and digital marketing conferences featuring industry leaders, and hybrid learning events for greater accessibility. Attendees gain valuable insights, practical strategies, and networking opportunities to enhance their academic performance and digital presence, staying ahead of the latest trends and f
          fostering connections with peers, mentors, and industry professionals. Join us to empower your mind and elevate your business through knowledge, innovation, and growth.</h4>
        <Heading subtitle='Upcoming Webinars' />
        {/* <Marquee > */}
        <div className=' container mb-5'>
          <div className="row mb-5 pb-5">

     
           
            <div className="col-sm-4 our-events" >
              <img src={Event3} alt="image" height="50%" width="100%" />

              <h3 style={{ color: "gold" }}>How Cloud-Based AI Infrastructure is Shaping Tommorow’s Educcation</h3>
              <h4 className="text-light">
                Speaker: Prof.(Dr.). Pankaj Kumar Mishra</h4>
              <h4 className="text-light">
                Hosted-By: Ms. Anushka Agarwal</h4>
              <h4 className="text-light"> (Web Developer @ DfreeNovelish)</h4>
              <h4 className="text-light"><FaCalendarAlt /> 01-July-2024
              </h4>
              <h4 className="text-light"><MdAccessTimeFilled /> 03:00 PM- 05:00 PM</h4>
              <button onClick={thirdEvent} className="localbutton mt-0">Register Now</button>
            </div>

          </div>


        </div>

        <div className=' container mb-5'>
          <div className="row mb-5 pb-5">
          <Heading subtitle='Successfully Completed Events' />
            <div className="col-sm-4 our-events">
              <img src={Event1} alt="image" height="50%" width="100%" />

              <h3 style={{ color: "gold" }} >Changing Scenario of Education in the Field of Research</h3>
              <h4 className="text-light">
                Speaker: Dr. Shivom Sharma</h4>
              <h4 className="text-light">
                Hosted-By: Ms. Divya Bhardwaj </h4>
              <h4 className="text-light">(Director of DfreeNovelish)</h4>
              <h4 className="text-light"><FaCalendarAlt /> 01-June-2024</h4>
              <h4 className="text-light"><MdAccessTimeFilled /> 03:00 PM- 04:00 PM</h4>

            </div>
            <div className="col-sm-4 our-events">
              <img src={Event2} alt="image" height="50%" width="100%" />

              <h3 style={{ color: "gold" }}>Preventive Strategies and Treatment Options for Hepatotoxicity</h3>
              <h4 className="text-light">
                Speaker: Prof.(Dr.). Kalini Kanta Sahoo </h4>
              <h4 className="text-light">
                Hosted-By: Mr. Vikar Kumar </h4>
              <h4 className="text-light">(Research Associate @ DfreeNovelish)</h4>
              <h4 className="text-light"><FaCalendarAlt /> 22-June-2024
              </h4>
              <h4 className="text-light"><MdAccessTimeFilled /> 11:00 AM- 12:00 PM</h4>

            </div>
            </div>
            </div>
        {/* </Marquee> */}
      </section>

    </>
  )
}

export default Event