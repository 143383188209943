import React, { useState } from "react";
import ResourceForm from "../../Form/ResourceForm";



const ResourceCard = () =>{
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
  return (
    setShowModal (false)
  )
}
  return(
    <>
<div className="container text-light">
<h4 style={{textAlign:"justify"}}>Welcome to our Resources Section, your go-to place for the latest and most impactful research topics in your field. Here, we meticulously curate and provide five top-tier research topics, each selected for its significance and potential to be published in esteemed journals. Our commitment to quality over quantity
     ensures that every research topic is not only relevant but also supported by a minimal yet robust set of references.</h4>
<button className="h-50 w-25 get-started py-2 mb-5" onClick={() => setShowModal(true)}  style={{marginLeft:"37%"}}>Register Now</button>
                       
                       {showModal && <ResourceForm closeModal={closeModal}/>}
</div>
  
    </>
  )
}
export default ResourceCard