import  React from "react"



const My_articles = () =>{
    return (
        <>
        <h1>Journal Papers </h1>
        </>
    )
}
export default My_articles;