import React, { useState } from "react";
import "./advisor.css"
import Belay_Tafa from "../../../privateImages/imgs/Belay_Tafa.png"
import Sunayana_agarwal from "../../../privateImages/imgs/Sunayana_agarwal.png"
import Amit_Kumar from "../../../privateImages/imgs/amit_Kumar.png"
import Utsab_Banerjee from "../../../privateImages/imgs/Dr. Utsab Banerjee.png"
import Kanta from "../../../privateImages/imgs/dr.kanta.png"
import Nikhil_Rastogi from "../../../privateImages/imgs/Dr.Nikhil Rastogi.png"
import Usha from "../../../privateImages/imgs/usha.png"
import HIMANI_GREWAL from "../../../privateImages/imgs/Dr. HIMANI GREWAL.png"

import DrReddy from "../../../privateImages/imgs/dr.reddy.png"
import Karpakalli from "../../../privateImages/imgs/dr.karpakalli.png"
import Firoj from "../../../privateImages/imgs/dr.firoj.png"
import Shaikh from "../../../privateImages/imgs/dr.shaikh.png"
import Srinivas from "../../../privateImages/imgs/dr.srinnivas.png"
import Harsh from "../../../privateImages/imgs/dr.harsh.png"
import Preeti from "../../../privateImages/imgs/dr.preeti.png"
import Arvind from "../../../privateImages/imgs/dr.arvind.png"
import Lalit from "../../../privateImages/imgs/dr.lalit.png"
import Iyer from "../../../privateImages/imgs/dr.iyer.png"
import Umesh from "../../../privateImages/imgs/dr.umesh.png"
import Manju from "../../../privateImages/imgs/dr.manju.png"
import Madupalli from "../../../privateImages/imgs/dr.madupalli.png"
import Sandeep from "../../../privateImages/imgs/dr.sandeep.png"
import Jagan from "../../../privateImages/imgs/dr.jagan.png"
import Bandari from "../../../privateImages/imgs/dr.bandari.png"
import Laxami from "../../../privateImages/imgs/dr.laxami.png"
import Thana from "../../../privateImages/imgs/dr.thana.png"
import Anita from "../../../privateImages/imgs/dr.anita.png"
import Bonthu from "../../../privateImages/imgs/dr.bonthu.png"
import Parul from "../../../privateImages/imgs/dr.parul.png"
import Krishna from "../../../privateImages/imgs/dr.krishna.png"
import Anirudh from "../../../privateImages/imgs/dr.kumar.png"
import Shivom from "../../../privateImages/imgs/dr.shivom.png"
import Kumari from "../../../privateImages/imgs/dr.chaitaniya.png"
import Ipilli from "../../../privateImages/imgs/dr.rao.png"
import Syed from "../../../privateImages/imgs/dr.syed.png"
import Garg from "../../../privateImages/imgs/dr.garg.png"
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";



// import React, {useState} from 'react'

const Advisor = () => {

  
    const search = () => {
        const searchbox = document.getElementById("searchInput").value.toUpperCase();
        const advisorlist = document.getElementById("advisor-list");
        const advisors = document.querySelectorAll(".row");
        const advisorname = document.getElementsByTagName("h5");
        const advisorsubject = document.getElementsByTagName("h6");

        for (var i = 0; i < advisorname.length; i++) {
            let match = advisors[i].getElementsByTagName('h5')[0];

            if (match) {
                let textvalue = match.textContent || match.innerHTML

                if (textvalue.toUpperCase().indexOf(searchbox) > -1) {
                    advisors[i].style.display = "";
                } else {
                    advisors[i].style.display = "none";
                }
            }
        }
    }



    return (
        <>
           

        
        
            <section className='text-light text-center'>
                <h1 style={{fontSize:"100px"}} className="mt-5">Our Advisors</h1>
                <h4>Meet the advisors at DfreeNovelish, a team of seasoned professionals with expertise in academia, technology, and business. Our academic mentors provide tailored guidance for academic excellence, while our technology experts offer insights on leveraging digital tools effectively. With a keen understanding of market trends, our business strategists provide strategic direction for sustainable growth. Together, our advisors are committed to guiding your journey with expertise and insight.</h4>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
                style={{ textDecoration: "none", position: "fixed", backgroundColor: "#25D366", paddingRight: "10px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "50%", marginTop: "20%", float: "right" }}>
                <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>

            {/* <div className='margin'></div> */}
            <section className="mt-5">
            <div class="container mt-5">
                <div class="row">
                    <div class="d-flex">

                        {/* <input class="form-control" onkeyup="search()" type="text" id="searchInput" placeholder="Search"
                        aria-label="Search" /> */}
                        <input class="form-control mt-5" onChange={search} type="text" id="searchInput" placeholder="Search" aria-label="Search" />
                    </div>
                </div>
            </div>
            <div class="container my-5" id="resultsContainer"></div>
            <div class="container" id="advisor-list">

                {/* <!-- First box --> */}
                <div class="row mt-5" id="Dr._Belay_Tafa" subject="Environmental science and engineering">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Belay_Tafa} alt="image-1" class="image image-box mt-5" />
                        <h5 class="text-light mt-2 text-center">Dr. Belay Tafa Oba</h5>
                        <h6 class="text-light text-center">(Ph.D in Environmental science and engineering)</h6>
                        <h6 class="text-light text-center">From: Ethiopia </h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}


                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <h5>Professional Profile</h5>

                            <h6>Education:</h6>
                            <ul>
                                <li>
                                    <strong>2018/09 - 2022/06:</strong> PhD in Environmental Science and Engineering
                                    <br />
                                    <span>Tianjin University, China</span>
                                </li>

                                <li>
                                    <strong>2008/02 - 2010/01:</strong> M.Sc. Chemistry
                                    <br />
                                    <span>Haramaya University, Ethiopia</span>
                                </li>

                                <li>
                                    <strong>2001/09/11 - 2004/07/01:</strong> B.Ed. Chemistry
                                    <br />
                                    <span>Alemaya University, Ethiopia</span>
                                </li>
                            </ul>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <section>
                                <h6>Assistant Professor in Chemistry and Environmental Science and Engineering</h6>
                                <p><strong>Arba Minch University, Arba Minch, Ethiopia</strong></p>
                                <p><strong>April 2010 - Current</strong></p>
                                <ul>
                                    <li>Lecture courses: Environmental chemistry and toxicology, Industrial pollution control,
                                        Solid waste management, Research and academic writing skill</li>
                                    <li>Advise graduate students' research and undergraduate students' senior projects</li>
                                    <li>Examine M.Sc. theses</li>
                                    <li>Conduct problem-solving and demand-driven research</li>
                                    <li>Active participation in volunteer works and community services</li>
                                </ul>
                            </section>

                            <section>
                                <h6>Institutional Quality Assurance Coordinator</h6>
                                <p><strong>Abaya campus, Arba Minch University, Arba Minch, Ethiopia</strong></p>
                                <p><strong>November 2017 - September 2018</strong></p>
                                <ul>
                                    <li>Supervise the quality of education in the college</li>
                                    <li>Supervise and control service delivery in the college</li>
                                    <li>Train various teaching modalities and support teachers and students with materials</li>
                                </ul>
                            </section>

                            <section>
                                <h6>Postgraduate Program Coordinator</h6>
                                <p><strong>Department of Chemistry, Arba Minch University, Arba Minch, Ethiopia</strong></p>
                                <p><strong>January 2010 - Current</strong></p>
                                <ul>
                                    <li>Assign teachers to graduate students</li>
                                    <li>Organize seminars and workshops for graduate students</li>
                                    <li>Supervise research status of graduate students</li>
                                </ul>
                            </section>
                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}


                </div>
                {/* <!--  First box ended --> */}




                {/* <!-- Second box --> */}
                <div class="row mt-5" id="Dr._Sunayna_Agarwal" subject="hindi">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 py-1 image text-center Card-1">
                        <img src={Sunayana_agarwal} alt="image-1" class="image mt-5 first_box" />
                        <h5 class="text-light mt-2 text-center">Dr. Sunayna Agarwal</h5>
                        <h6 class="text-light text-center">(Ph.D in Hindi)</h6>
                        <h6 class="text-light text-center">From: Moradabad</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <ul class="mx-4 colour">
                            <li><strong>U. P Board Allahabad </strong><br />
                                Class X </li>
                            <li><strong>U. P Board Allahabad </strong><br />
                                Class XII </li>
                            <li> <strong>M. J. P. R. U Bareilly </strong><br />
                                B. A </li>
                            <li> <strong>M. J. P. R. U Bareilly </strong> <br />
                                M. A in hindi </li>
                            <li><strong>M. J. P. R. U Bareilly </strong><br />
                                P. H. D in hindi</li>
                            <li> <strong>Allah Abad University </strong><br />
                                Prabhakar in music</li>
                            <li> <strong>C. C. S University</strong><br />
                                B. Ed </li>

                        </ul>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <ul>
                                <li>
                                    <strong>Choudary Revat Singh Degree College, Sambhal</strong>
                                    <ul>
                                        <li><strong>Principal (2022 - 2023)</strong></li>
                                        <li>General administration and overall supervision of teaching programs.</li>
                                        <li>Supervision of students' welfare, health, and security services.</li>
                                        <li>Supervision and guidance of teaching staff, including organizing in-service
                                            education.</li>
                                        <li>Organizing workload of staff, including teaching assignments.</li>
                                        <li>Guidance and counseling of students.</li>
                                        <li>Administrative arrangements for students’ clinical experience and teaching in the
                                            field.</li>
                                        <li>Recruitment of staff and admission of students.</li>
                                        <li>Conducting examinations.</li>
                                        <li>Supervision of living conditions of students in the hostel.</li>
                                        <li>Maintenance of permanent records of students.</li>
                                        <li>Preparation of reports, including school/college reports and progress reports.</li>
                                        <li>Review and revision of policies, rules, regulations & philosophy of the college.
                                        </li>
                                        <li>Performing public relations duties.</li>
                                        <li>Procurement of college equipment, supplies, and stationery.</li>
                                        <li>Preparation of budgetary proposals.</li>
                                        <li>Supervision of hostel and office staff.</li>
                                        <li>Participation in committee work.</li>
                                        <li>Participation in teaching programs.</li>
                                        <li>Supervision of library services.</li>
                                        <li>Planning for the development of the college.</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>A. H. M. A Degree College, Sambhal</strong>
                                    <ul>
                                        <li><strong>Principal (2016 - 2022)</strong></li>
                                        <li>(Include similar responsibilities and achievements as mentioned above)</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>A. R. M Degree College, Sambhal</strong>
                                    <ul>
                                        <li><strong>Principal (2015 - 2016)</strong></li>
                                        <li>(Include similar responsibilities and achievements as mentioned above)</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>S. K. D. M Degree College, Manpur, Moradabad</strong>
                                    <ul>
                                        <li><strong>Lecturer (2008 - 2015)</strong></li>
                                        <li>(Include teaching responsibilities and any notable achievements)</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Hindu College, Moradabad</strong>
                                    <ul>
                                        <li><strong>Lecturer (2004 - 2008)</strong></li>
                                        <li>(Include teaching responsibilities and any notable achievements)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}


                </div>
                {/* <!--  second box ended --> */}

                {/* <!-- Third Box --> */}
                <div class="row mt-5" id="Dr._Amit_Kumar" subject="tourism">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 py-1 image text-center Card-1">
                        <img src={Amit_Kumar} alt="image-1" class="image mt-5 mx-2 first_box" />
                        <h5 class="text-light mt-2 text-center">Dr. Amit Kumar</h5>
                        <h6 class="text-light text-center">(Ph.D in Tourism )</h6>
                        <h6 class="text-light text-center">From: Mahendragarh Haryana</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <ul class="mx-4 colour">
                            <li>Harvard Business School Online Certificate Program on <strong>“Sustainable Business
                                Strategy”</strong> awarded
                                on 16-03-2022.</li>
                            <li> Ph.D in Tourism on the topic of <strong>“HRM PRACTICES IN HOSPITALITY ENTERPRISES”</strong>
                                Awarded on 26-07-2012.</li>
                            <li> MBA in International Hospitality Management from International Hotel Management Institute
                                (IMI), <em>Switzerland</em> in 2007.</li>
                            <li> Masters in Business Administration (MBA) from K.U.K with 1st Division in 2009.</li>
                            <li> UGC – NET qualified. </li>
                            <li> Master of Tourism Management from Department of Tourism and Hotel Management, K.U.K
                                with 1st Division in 2005.</li>
                            <li> B.A – Honours (Economics) from University College, K.U.K, with 1st Division in 2003.</li>
                            <li> L.L.B from Department of Law, K.U.K in 2017 with 1st Division</li>
                        </ul>
                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>
                                Dr. Amit Kumar is an accomplished academician with over 14 years of experience in the field and
                                an additional 2 years in the industry. He currently serves as an Assistant Professor in the
                                Department of Tourism and Hotel Management at the Central University of Haryana, Mahendragarh.
                                Dr. Kumar is highly skilled in delivering creative and innovative curriculum content, resulting
                                in successful student learning outcomes.
                            </p>
                            <p> His teaching style involves fostering engaging class debates to stimulate student participation
                                and enhance the learning experience. Dr. Kumar has an extensive academic background, having
                                presented research papers in various International and National Seminars, Conferences, and
                                Workshops. Additionally, he has contributed to reputed journals and books through research
                                publications.
                            </p>
                            <p> Dr. Kumar holds a Ph.D. in Tourism, specializing in "HRM Practices in Hospitality Enterprises,"
                                awarded in 2012. He also possesses an MBA in International Hospitality Management from the
                                International Hotel Management Institute (IMI), Switzerland (2007), and an MBA from K.U.K with a
                                1st Division in 2009. Furthermore, he is UGC-NET qualified and holds a Master's degree in
                                Tourism Management from the Department of Tourism and Hotel Management, K.U.K.
                            </p>
                            <p> His educational achievements also include a B.A. (Honours) in Economics, an LL.B. from the
                                Department of Law (2017), both with 1st Divisions, and a Harvard Business School Online
                                Certificate in "Sustainable Business Strategy" awarded in 2022.
                            </p>
                            <p> Dr. Kumar actively contributes to academic bodies, serving as a visiting faculty and Guest
                                Member for UG/PG board of studies at Dev Sanskriti University, Haridwar. He is also an Expert
                                Panel Member for paper setting/evaluation at various reputed universities and holds positions
                                such as a book reviewer for Oxford University Press, India.
                            </p>
                            <p> In terms of research, Dr. Kumar has an impressive profile with seven Ph.D. candidates and two
                                M.Phil. candidates awarded under his supervision. He has published 47 research papers in
                                international/national journals, 23 papers in books, and holds three patents. Additionally, he
                                has authored two books, edited two others, and has a substantial number of citations and an
                                H-index of 5. His work is indexed in Scopus six times.
                            </p>
                            <p> Membership-wise, Dr. Kumar is associated with renowned professional bodies such as Elsevier's
                                Tourism Management Perspectives, where he serves as a Reviewer Board Member. He holds editorial
                                positions in journals like Academic Social Research Journal, Nepalese Journal of Hospitality and
                                Tourism Management, Journal of Sustainable Tourism and Entrepreneurship, International Journal
                                of Hospitality, Management and Sciences, International Journal of Gastronomy Research, and
                                others.
                            </p>
                            <p> His involvement extends to being a life member of organizations like the Indian Tourism and
                                Hospitality Congress (ITHC), Indian Hospitality Congress (IHC), Indian Culinary Forum (ICF), and
                                Chef's Association of Five Rivers (CAFR). Dr. Kumar is also an allied member of the India Food
                                Tourism Organization (IFTO).
                            </p>
                            <p> In summary, Dr. Amit Kumar brings a wealth of academic, industry, and research experience,
                                contributing significantly to the field of Tourism and Hotel Management. His diverse skills,
                                extensive publications, and active participation in academic and professional bodies make him a
                                valuable asset to the educational community.
                            </p>
                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}
                </div>
                {/* <!--  third box ended --> */}


                {/* <!-- Fourth Box --> */}
                <div class="row mt-5" id="Dr. Utsab Banerjee" subject="Antenna Design and Wireless Communication">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1 py-1 mt-5 ">
                        <img src={Utsab_Banerjee} alt="image-1" class="image image-box mt-5" />
                        <h5 class="text-light mt-2 text-center">Dr. Utsab Banerjee</h5>
                        <h6 class="text-light text-center f-6">(Ph.D in Antenna Design and Wireless Communication)</h6>
                        <h6 class="text-light text-center">From: Mahendragarh Haryana</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <h5>Ph.D.</h5>
                            <p><strong>Title of Thesis:</strong> Study on Circularly Polarized Antennas for Modern Wireless
                                Communication Systems</p>
                            <ul>
                                <li>Developed and presented analytical knowledge on Polarization.</li>
                                <li>Performed and published a comprehensive review on Circularly Polarized Antennas.</li>
                                <li>Designed and fabricated a combined triple-band CP and compact UWB monopole antenna.</li>
                                <li>Designed and fabricated a CPW fed wideband CP antenna. Demonstrated the effects of employing
                                    DGS and modified Hilbert fractal structures.</li>
                                <li>Simulated and fabricated a cross-slotted UWB CP antenna and another CP antenna having S-band
                                    applications.</li>
                                <li>Indicated precisely the future scope of research.</li>
                            </ul>

                            <h5>M.TECH</h5>
                            <p><strong>Title of Thesis:</strong> Some studies on fractal based printed UWB antenna for bandwidth
                                enhancement and band-notch characteristics</p>
                            <ul>
                                <li>Theoretical and practical knowledge on Ultra Wideband spectrum and its specifications were
                                    developed.</li>
                                <li>Designed and analyzed a fractal based UWB monopole antenna.</li>
                                <li>Simulated and measured a dual band-notch UWB antenna using a perturbed Hilbert curve slot.
                                </li>
                                <li>Developed practical knowledge on operations and applications of function generators,
                                    spectrum analyzers, Vector network analyzers, basic microwave measuring devices.</li>
                            </ul>

                            <h5>B.TECH</h5>
                            <p><strong>Project Title:</strong> Waveform Generator</p>
                            <ul>
                                <li>Designed and developed a Waveform generator, capable of generating different types of
                                    standard waveforms like Square wave, Triangular or Ramp Wave, and Sinusoidal wave.</li>
                                <li>Practical realization of the circuits was performed.</li>
                                <li>Studied and implemented electronic devices and components, including centre-tapped step-down
                                    transformer, Si diode, capacitors, 741 OP AMP IC, 100K Potentiometer, resistors, IC 7815, IC
                                    7915, and others.</li>
                                <li>Generated output signals were analyzed and apprehended.</li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <ul>
                                <li>
                                    Working as an Assistant Professor in the Department of
                                    Electronics and Communication Engineering, MVJ College of
                                    Engineering (an Autonomous Institute), Bengaluru, India,
                                    since September 2021.
                                </li>
                                <li>
                                    Worked as a Teaching Assistant in the Department of
                                    Electronics and Communication Engineering, Tripura
                                    University (A Central University), Tripura, India, for 3 (three)
                                    years, from 1st December 2017 till 30th November 2020.
                                </li>
                                <li>
                                    Worked as a Faculty in the Science Department in The
                                    Bhawanipur Gujarati Education Society, Kolkata, from 2nd
                                    May 2014 till 31st October 2017.
                                </li>
                                <li>
                                    Proficient in working on Simulation
                                    softwares like CST MWS, ANSYS
                                    HFSS, ALTAIR FEKO, MATLAB,
                                    ORIGIN PRO.
                                </li>
                                <li>
                                    Sound knowledge and practical
                                    experience about hands on fabrication
                                    and
                                    physical
                                    parametric
                                    measurements of several planar and
                                    multi-layered antenna prototypes.
                                </li>
                                <li>
                                    Conducted
                                    an
                                    International
                                    Conference, “ICDI3C 2022, Design,
                                    Innovation for 3CS’s, Compute,
                                    Communicate, Control” as the
                                    organizing secretary at MVJ College
                                    of Engineering, Bangalore, India.
                                </li>
                                <li>
                                    Conducted an National Conference,
                                    “NCDI3C 2023, Design, Innovation
                                    for 3CS’s, Compute, Communicate,
                                    Control” as the organizing secretary
                                    at MVJ College of Engineering,
                                    Bangalore, India.
                                </li>
                                <li>
                                    Conducted a National Conference on
                                    “Recent Advances in Antenna
                                    Design and applications in RF &
                                    Microwave

                                </li>
                                <li>
                                    Communication Systems” as a member of the
                                    organizing committee at Tripura
                                    University (A Central University),
                                    Tripura, India.
                                </li>
                                <li>
                                    Maintain online channels providing
                                    tutorials on various technical aspects
                                    related
                                    to
                                    Antennas
                                    Electromagnetic Field Theory.
                                    and
                                    Member & Technical contributor of
                                    Kolkata Sky Watchers’ Association
                                    since 2007.
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}


                </div>
                {/* <!--  Fourth box ended --> */}


                {/* <!-- Fifth box --> */}
                <div class="row mt-5" id="Dr. Nalini kanta Sahoo" subject="Pharmaceutical Analysis">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 py-1 image text-center Card-1">
                        <img src={Kanta} alt="image-1" class="image mt-5 first_box" />
                        <h5 class="text-light mt-2 text-center">Dr. Nalini kanta Sahoo</h5>
                        <h6 class="text-light text-center">(Ph.D in Pharmaceutical Analysis)</h6>
                        <h6 class="text-light text-center">From: Jagtsinghpur </h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <h5>Professional Profile</h5>

                            <h6>Education:</h6>
                            <ul>
                                <li>Post-doctoral Research, Industrial University of Ho Chi Minh City, Vietnam, 2023 (Awarded)
                                </li>
                                <li>Ph.D. in Pharmaceutical Analysis, Siksha “O” Anusandhan University, Bhubaneswar, Orissa,
                                    2010 (Regd.), Awarded in 2015</li>
                                <li>M.Pharmacy in Pharmaceutical Analysis & Quality Assurance, College of Pharmaceutical
                                    Sciences, Berhampur, 2008 (CGPA: 9.26)</li>
                                <li>B. Pharmacy, College of Pharmaceutical Sciences, Berhampur, 2005 (76.6%)</li>
                            </ul>

                            <h6>Work Experience:</h6>
                            <ul>
                                <li>Director and Principal, MIT College of Pharmacy, Moradabad, UP, India (from 24/06/2023 to
                                    present)</li>
                                {/* <!-- Include relevant work experiences --> */}
                            </ul>

                            <h6>Awards and Achievements:</h6>
                            <ul>
                                <li>Best Teacher Award 2022 (IJMTST Excellence Award)</li>
                                <li>Gold Medal for Best Paper Presentation at SRM Institute of Science & Technology</li>
                                {/* <!-- Include other awards and recognitions --> */}
                            </ul>

                            <h6>Ph.D. Thesis:</h6>
                            <p>Title: "New Validated Liquid Chromatographic Methods for the Determination of Some Important
                                Drugs in Plasma Samples/Formulations"</p>
                            <p>Supervisors: Dr. P. Srinivasa Rao and Dr. Goutam Ghosh</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p><strong> QA Executive, Shilpa Medicare Pvt.Ltd. Raichur, Karnataka</strong></p>
                            <p>Duration: 01-11-2005 to 31-10-2006</p>

                            <p><strong> Asst. Professor, Yalamarty Pharmacy College, Visakhapatnam</strong></p>
                            <p>Department: Pharmaceutical Analysis & Quality Assurance</p>
                            <p>Duration: 01-07-2008 to 25/10/2014</p>

                            <p><strong> Assoc. Professor, MNR College of Pharmacy, Sangareddy, Telangana</strong></p>
                            <p>Department: Pharmaceutical Analysis</p>
                            <p>Duration: 27-10-2014 to 31-10-2017</p>

                            <p><strong> Professor & H.O.D, MLR Institute of Pharmacy, Dundigal, Rangareddy</strong></p>
                            <p>Department: Pharmaceutical Analysis & Quality Assurance</p>
                            <p>Duration: 01-11-2017 to 30/12/2020</p>

                            <p><strong> Professor and Principal, SRM Modinagar College of Pharmacy, SRM Institute of Science and
                                Technology (Deemed to be University), Delhi-NCR</strong></p>
                            <p>Duration: 04/01/2021 to 15/10/2022</p>

                            <p><strong> Professor and Head (R&D), Department of Pharmaceutical Technology, Meerut Institute of
                                Engineering and Technology (MIET), Dr. A.P.J. Abdul Kalam Technical University, Meerut, UP,
                                India</strong></p>
                            <p>Duration: 17/10/2022 to 22/06/2023</p>

                            <p><strong> Director and Principal, MIT College of Pharmacy, MIT Campus, Moradabad, UP,
                                India</strong></p>
                            <p>Affiliated to Dr. A.P.J. Abdul Kalam Technical University</p>
                            <p>Duration: 24/06/2023 to till date</p>

                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}


                </div>
                {/* <!--  fifth box ended --> */}



                {/* <!-- sixth box --> */}
                <div class="row mt-5" id="Dr. Nikhil Rastogi" subject="Physics">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 py-1 image text-center Card-1">
                        <img src={Nikhil_Rastogi} alt="image-1" class="image mt-5 first_box" />
                        <h5 class="text-light mt-2 text-center">Dr. Nikhil Rastogi</h5>
                        <h6 class="text-light text-center">(Ph.D in Physics)</h6>
                        <h6 class="text-light text-center">From: Moradabad </h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <h5>Professional Profile</h5>

                            <h6>Education:</h6>
                            <ul>
                                <li>
                                    <p> <strong>Ph.D. - Physics</strong></p>
                                    Year: 2002-03
                                    <p>University: M.J.P. Rohilkhand University, Bareilly</p>
                                </li>

                                <li>
                                    <p><strong>MBA</strong></p>
                                    <p>Year: 1999 University: Rohilkhand University</p>
                                </li>

                                <li>
                                    <p><strong>M.Sc. - Physics</strong></p>
                                    <p>Year: 1995 University: Rohilkhand University</p>
                                </li>

                                <li>
                                    <p><strong>B.Sc. - Physics</strong></p>
                                    <p>Year: 1993 University: Rohilkhand University</p>
                                </li>

                                <li>
                                    <p> <strong>12th</strong></p>
                                    <p>Year: 1990 Board: UP Board Moradabad Division: Ist Division</p>
                                </li>

                                <li>
                                    <p><strong>10th</strong></p>
                                    <p>Year: 1988 Board: UP Board Moradabad Division: Ist Division</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <h5>Professional Experience:</h5>

                            <section>
                                <h6>IIMT University, Meerut</h6>
                                <p><strong>Professor-Physics</strong></p>
                                <p>October 2021 - July 2022</p>
                                <p>Served as a Professor in the Physics department, contributing to teaching, research, and
                                    administrative responsibilities. Managed circulars, responses from government bodies, and
                                    oversaw 12B inspections and NAAC criteria.</p>
                            </section>

                            <section>
                                <h6>IFTM University, Moradabad</h6>
                                <p><strong>Professor-Physics, Deputy Registrar-Academics/Nodal Officer-Vidyanjali</strong></p>
                                <p>September 2002 - September 2021</p>
                                <p>Progressed from Lecturer to Professor, accumulating 19 years of teaching and research
                                    experience. Held various administrative roles, including Director of the Faculty of
                                    Sciences, Dean, and Head of the Department. Additional roles included Controller of
                                    Examination, Convener of Board of Studies, Chairman of Board of Studies in All Science
                                    Subjects, and more. Contributed significantly to the university's growth, serving on the
                                    Executive Council and Academic Council.</p>
                            </section>
                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}


                </div>
                {/* <!--  sixth box ended --> */}

                {/* <!-- seventh box --> */}
                <div class="row" id="Dr._Usha_Gupta" subject="English">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 py-1 image text-center Card-1">
                        <img src={Usha} alt="image-1" class="image mt-5 first_box" />
                        <h5 class="text-light mt-2 text-center">Dr. Usha Gupta</h5>
                        <h6 class="text-light text-center">(Ph.D in English)</h6>
                        <h6 class="text-light text-center">From: Bulandshahr </h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <ul class="mx-4 colour">
                            <li> Successfully registered by the University a research work (Ph.D. Thesis) on following topic;
                                “Trends of Popular Fiction in Chetan Bhagat and Preeti Shenoy” </li>
                            <li> Attended a National Workshop on ‘Understanding Academic Integrity and Practices to Avoid
                                Plagiarism held in D.N.College, Meerut.</li>
                            <li>Participated in a National Seminar on ‘Role of Education in
                                Environment Protection and Conservation’ held in K.M.G.G. PG. College, Badalpur (Greater
                                Noida). </li>
                            <li> Presented a paper in National Seminar entitled ‘Role of Research Guide in Research Works’ in
                                K.M.G.G.PG.College, Badalpur, (Greater Noida).</li>
                            <li> Presented a research paper in National Seminar entitled ‘Dr. Ambedkar as a Nation Builder’ in
                                K.M.G.G.PG.College, Badalpur.</li>
                            <li>Presented a research paper in National Seminar entitled ‘Swami Vivekanand; Resurgent of India’
                                in K.M.G.G.PG. College Badalpur.</li>
                            <li>Participated in National Webinar on ‘Literature and Journalism’ held on 6th Oct, 2020.</li>
                            <li>Presented a Research paper in Two Days National Seminar entitled ‘Role of Education, Family and
                                State in gender-equality’ in S.M.P.PG. College, Meerut.</li>
                            <li>Presented a Pesearch paper in Three Days National Seminar entitled ‘Adult Education and Lifelong
                                Learning’
                                by I.P.PG College,
                                Campus-2,
                                BSR.</li>
                            <li>Published a research paper In Multi-Disciplinary International Journal ‘Remarking an
                                Analisation’
                                entitled ‘A Critical Review of Chetan Bhagat as a Bestseller Author’ in April, 2019.</li>
                            <li>Published a Research paper in Multi-Disciplinary International Journal ‘Anthology the Research’
                                entitled ‘The Plight of Women in Preeti Shenoy’s Novel ‘The Secret Wish List’ in 2020.</li>
                            <li>Published a paper in ISBN book ‘National Education Policy 2020; Challenges and Opportunities’
                                entitled paper ‘Adult Education and Lifelong Learning’ proceeding by I.P.PG. College, Campus-2
                                BSR.</li>
                            <li>Published a paper in ISBN Book ‘Gender Discrimination at work place in India’ entitled paper
                                ‘Role of Education, Family and State in Gender-Equality’ proceeding by S.M.P. PG. College,
                                Meerut.</li>
                            <li>Participated as a Speaker in S.M.P. PG. College, Meerut on the topic Challenges and Relevance
                                of Research in present scenario on 16th Sep, 2023.</li>
                        </ul>
                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <ul class="mx-4 text-dark colour">
                            <li> Presently working as a permanent Assistant Professor in
                                S.V.M. Law College, Shikarpur BSR (Affiliated, to Ch.
                                Charan Singh University, Meerut).</li>
                            <li>Having 6+ years’ experience as regular Teaching faculty in
                                English.</li>
                            <li>Having 3+ years’ teaching experience in schools.</li>
                        </ul>
                    </div>
                    {/* <!-- Card-3 ended--> */}

                </div>
                {/* <!-- seventh box ended--> */}

                {/* <!-- eighth box --> */}
                <div class="row mt-5" id="Dr. Himani Grewal" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={HIMANI_GREWAL} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Himani Grewal</h5>
                        <h6 class="text-light text-center">(Ph.D in Management-Corporate Social Responsibility)</h6>
                        <h6 class="text-light text-center">From: Moradabad </h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <div class="credential">
                                <strong><span class="year">2022:</span> Ph.D (Management)</strong> - UNIVERSITY/BOARD
                            </div>

                            <div class="credential">
                                <strong> <span class="year">2012:</span> M.A. Economics</strong> - Dr. APJ Abdul Kalam Technical
                                University, Lucknow (64%)
                            </div>

                            <div class="credential">
                                <strong> <span class="year">2009:</span> M.B.A (HRM and Finance)</strong> - CCS University,
                                Meerut (76% - Honours)
                            </div>

                            <div class="credential">
                                <strong> <span class="year">2007:</span> B.Sc. (Biology)</strong> - U.P.T. University Lucknow
                                (74.8%)
                            </div>

                            <div class="credential">
                                <strong> <span class="year">2004:</span> Intermediate </strong> - M.J.P.R. University
                                Bareilly(U.P.) (64%)
                            </div>

                            <div class="credential">
                                <strong> <span class="year">2002:</span> High School</strong> - U.P. Board (59.6%)
                            </div>


                            <section>
                                <h6>NPTEL Courses</h6>
                                <ul>
                                    <li><strong>Marketing Management – I</strong> | IIT Kanpur | Aug-Sept 2023</li>
                                    <li><strong>Investment Management - 70% (Elite)</strong> | IIT Kharagpur | June 2023</li>
                                    <li><strong>Introduction to Marketing Essentials - 71% (Elite)</strong> | IIT Roorkee |
                                        September 2022</li>
                                    {/* <!-- Add more NPTEL courses as needed --> */}
                                </ul>
                            </section>


                            <section>
                                <h6>Workshop Attended</h6>
                                <ul>
                                    <li><strong>NEP-2020 Implementation Plan under the component research, innovation, Rankings
                                        and Integrated Higher Education System</strong> | UGC-Human resource Development
                                        centre, Savitribai Phule University, Pune 411007 | 30th July 2022</li>

                                </ul>
                            </section>

                            <section>
                                <h6>Faculty Development Program</h6>
                                <ul>
                                    <li><strong>Funded Research Projects</strong> | 16th Oct – 21st Oct 2023 | ATLAS SkillTech
                                        University, Mumbai</li>

                                </ul>

                            </section>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <h5>Assistant Professor in Chemistry and Environmental Science and Engineering</h5>
                            <p>Arba Minch University, Arba Minch, Ethiopia</p>
                            <p>April 2010 - Current</p>
                            <ul>
                                <li>Lectured courses including Environmental Chemistry and Toxicology, Industrial Pollution
                                    Control, Solid Waste Management, Research and Academic Writing Skills.</li>
                                <li>Advised graduate and undergraduate students on research projects.</li>
                                <li>Examined M.Sc. theses.</li>
                                <li>Conducted problem-solving and demand-driven research.</li>
                                <li>Active participation in volunteer works and community services.</li>
                            </ul>

                            {/* <!-- Add more professional experiences as needed --> */}
                            <section>
                                <h5>Peer-Reviewed International Journal Articles</h5>
                                <ol>
                                    <li>Hassan, M. K., Rabbani, M. R., Brodmann, J., Bashar, A., & Grewal, H. (2022).
                                        Bibliometric and Scientometric analysis on CSR practices in the banking sector. Review
                                        of Financial Economics. (Scopus Indexed, ABDC – B Category)</li>
                                    <li>Grewal, H. & Dutt, R. (2019). A comparative study of CSR practices & investments done by
                                        SBI and AXIS bank. International Journal of Research and Analytical Reviews, 6(2),
                                        854-860.</li>
                                    {/* <!-- Add more journal articles as needed --> */}
                                </ol>
                            </section>

                            <section>
                                <h5>Conference Proceedings</h5>
                                <ol>
                                    <li>Jreisat, A., Rabbani, M. R., Hatamleh, Z. M., & Grewal, H. (2021, December). COVID 19: A
                                        Closer Look at the MENA Banking Sector. In 2021 International Conference on Decision Aid
                                        Sciences and Application (DASA) (pp. 344-349). DOI- 10.1109/DASA53625.2021.9682244 IEEE
                                        Explore.</li>
                                    <li>Grewal, H & Yadav, S. (2022). Role of demographic factors in making customer aware
                                        regarding Banking CSR activities. BIZCRAFT Journal of Faculty of Management science.
                                        14(22)- 48-52.</li>
                                    {/* <!-- Add more conference proceedings as needed --> */}
                                </ol>
                            </section>

                            <section>
                                <h5>International Conference Presentations</h5>
                                <ul>
                                    <li><strong>The Impact of Artificial Intelligence on Society- A Detailed Study</strong> |
                                        IIRA 3.O, MIT, Moradabad | 26-27th July 2023 (Presented and published)</li>

                                </ul>
                            </section>

                            <section>
                                <h5>National Conference/Seminars Presentations</h5>
                                <ul>
                                    <li><strong>Study on Requirement of Good Human Resources in Rural Marketing</strong> | KIET
                                        School of Management sponsored by AICTE, New Delhi | 18th August 2012 (Presented and
                                        published)</li>

                                </ul>
                            </section>




                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>

                {/* <!-- nineth box --> */}
                <div class="row mt-5" id="Dr.M.Karpaka valli" subject="Pharmaceutical Analysis">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Karpakalli} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr.M.Karpaka valli
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Pharmaceutical Analysis)</h6>
                        <h6 class="text-light text-center">From: Tamil Nadu</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. M. Karpaka Valli is a distinguished academician and researcher with over 19 years of rich and diverse experience in the field of pharmaceutical sciences. Currently serving as the Professor & Head at Karpagam College of Pharmacy, Coimbatore, she has demonstrated exceptional leadership and expertise in academic administration and research. Her professional journey includes pivotal roles such as Associate Professor, Assistant Professor,
                                and Lecturer at esteemed institutions such as Karpagam College of Pharmacy and Cherraan’s College of Pharmacy.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Driven by a passion for research and innovation, Dr. M. Karpaka Valli has an impressive publication record with 52 publications, along with significant involvement in patent applications, research proposals, and book chapters. She has actively contributed to the academic community through her extensive engagement as a speaker, lead lecturer, co-chairperson, and evaluator in various national and international conferences and webinars. Her commitment to nurturing future talent is evident through her guidance of undergraduate and postgraduate projects, as well as her role as a coordinator for NPTEL courses. Dr. M. Karpaka Valli has been honored with several prestigious awards, including the “Best Academician Award” by AEDS & NABARD, the “Women Researcher Award” by INSO, and the “NPTEL STAR” awards for her exceptional contributions to education and research.</p>

                            <p>Her collaborative efforts are reflected in the signing of MoUs with renowned institutions and industry labs, further exemplifying her commitment to advancing research and innovation. Dr. M. Karpaka Valli’s extensive experience, coupled with her remarkable achievements and contributions, make her an invaluable asset to any advisory panel, offering profound insights and guidance in the realm of pharmaceutical sciences and academia.</p>




                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- tenth box --> */}
                <div class="row mt-5" id="Dr. Shaik Firoz" subject="Pharmaceutics">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Firoj} alt="image-1" class="image mt-3  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Shaik Firoz
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Pharmaceutics)</h6>
                        <h6 class="text-light text-center">From: Andhra Pradesh.</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Shaik Firoz brings over 16 years of invaluable experience to the realms of academia and the pharmaceutical industry. Beginning his journey as an Assistant Professor in 2008, he has ascended through key roles, including Associate Professor, Professor & HOD, culminating in his current position as the Principal at Sri Venkateswara Pharmacy College. Armed with a Ph.D.
                                in Pharmaceutics, Dr. Firoz has displayed an unwavering commitment to academic and research excellence.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>His prolific record includes publishing 25 papers, which consist of research and review articles published in esteemed journals. In addition to papers, he has also published two chapters and two patents. Dr. Firoz has evaluated projects for undergraduate students and successfully assessed 25 projects, showcasing his significant impact on the realms of academia and research concurrently. Furthermore, Dr. Firoz serves as a respected reviewer for reputable journals and holds a life membership in The Indian Pharmaceutical Association.</p>

                            <p>Proficient in formulation sciences and pharmaceutical research, Dr. Firoz gained practical insights through industrial training at Suven Pharmaceuticals. His exceptional communication skills, organizational proficiency, and dedication to excellence position him as a valuable asset for any advisory panel, embodying a distinguished presence in both academic and professional arenas.</p>





                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>


                {/* <!-- eleven box --> */}
                <div class="row mt-5" id="Dr. Shaik Jaffar Basha" subject="Agricultural">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Shaikh} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Shaik Jaffar Basha
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Agricultural)</h6>
                        <h6 class="text-light text-center">From: Guntur, Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Meet Dr. Shaik Jaffar Basha, a distinguished Research Scientist and Academic Mentor specializing in agriculture. With over two decades of experience, Dr. Jaffar Basha has contributed significantly to the field, holding roles such as Scientist (Agro) and Scientist (Agronomy). The academic journey includes a Ph.D.
                                at UAS, Dharwad, marked by excellence and recognized with honors like the Sanna Reddy Gopal Reddy Gold Medal.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>As a Technical Officer and In-Charge of various projects, Dr. Jaffar Basha has excelled in farm management, seed production, and land development activities. A reputable reviewer for scientific journals, ensuring the quality of agricultural research. Beyond accolades, Dr. Jaffar Basha has actively presented groundbreaking research in national and international symposiums, showcasing expertise in agronomy, tobacco science, and climate-resilient agriculture.</p>

                            <p>Join Dr. Jaffar Basha's advisory panel for a journey into cutting-edge research, academic mentorship, and a commitment to sustainable agricultural practices. Engage with a mentor whose passion for agriculture is not only reflected in academic achievements but also in practical, impactful contributions to the field.</p>



                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- twelve box --> */}
                <div class="row mt-5" id="Dr. Srinivas Thati" subject="Genetics and Plant Breeding">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Srinivas} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Srinivas Thati
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Genetics and Plant Breeding )</h6>
                        <h6 class="text-light text-center">  From: Andhra Pradesh.</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Srinivas, an eminent figure in agricultural research, currently serves as the Associate Director of Research (FAC) at the Regional Agricultural Research Station, Maruteru. With a career spanning over two decades, he has played pivotal roles, including Professor & Head at the Department of
                                Genetics & Plant Breeding, Agricultural College, Bapatla, and Principal Scientist (Breeding) at Maruteru.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>His journey showcases dynamic contributions, from developing resilient crop varieties like Sreekurma and Indra to receiving numerous accolades such as the 20th Century Young Agricultural Scientist award. Dr. Srinivas has also excelled in infrastructure and laboratory development, leaving an indelible mark on the modernization of rice breeding programs at ANGRAU.</p>

                            <p>As a seasoned scientist and academic leader, Dr. Srinivas brings a wealth of experience, pioneering research, and a commitment to advancing agricultural practices. His strategic initiatives and achievements make him an invaluable asset to any advisory panel, offering insights that bridge cutting-edge research with practical applications. Joining his advisory panel promises an enriching journey into the forefront of agricultural innovation and development.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>


                {/* <!-- thirteen box --> */}
                <div class="row mt-5" id="Dr. Harsh Kumar" subject="Computer Sciece">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Harsh} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Harsh Kumar
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Computer Sciece )</h6>
                        <h6 class="text-light text-center">  From: Amroha, UP</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Harsh Kumar, an accomplished academician and administrative leader, presently holds the esteemed position of Dean (Research/Computer Science & Applications) at Himalayan Garhwal University. With a career spanning over a decade, Dr. Kumar has exhibited unwavering commitment to academia, coupled with visionary leadership.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>His professional journey, marked by pivotal roles at institutions like Indus International University and Dr. KN Modi University, showcases a wealth of experience in both teaching and administrative capacities. As an Associate Professor and Dean, he contributed significantly to the academic growth and operational efficiency of these institutions.</p>

                            <p>Dr. Kumar’s research prowess is evident in his exploration of cutting-edge subjects, including data mining, cloud computing, and artificial intelligence. His prolific publications in renowned international journals underscore his dedication to advancing scholarly knowledge.</p>

                            <p>Beyond his academic contributions, Dr. Kumar actively shapes the research landscape at Himalayan Garhwal University, solidifying his reputation as a dynamic and forward-thinking leader. His multifaceted expertise makes him an invaluable asset to any academic advisory panel, ensuring a harmonious blend of scholarly excellence and administrative acumen.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- forteen box --> */}
                <div class="row mt-5" id="Dr. Preeti Verma" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Preeti} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Preeti Verma
                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Electronics)</h6>
                        <h6 class="text-light text-center">  From: Delhi</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Preeti Verma is an accomplished academic professional with 8.7 years of diverse experience in teaching and research. Currently serving as an Assistant Professor in the Department of Electronics & Communication Engineering at the National Institute of Technology Delhi, she holds the additional responsibilities of Associate Dean (Faculty Welfare), Training & Placement Officer, Chief Training Officer for the National Cadet Corps (NCC), and Warden of Yamuna Hostel.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Verma’s expertise lies in the realm of VLSI Design, with a Ph.D. from the National Institute of Electronics Engineering and a focus on Low Power CMOS circuits, Photonics Devices, Quantum, and AI in VLSI. Her technical skill set includes proficiency in Windows OS, Cadence Design tools, Xilinx, HSPICE (Synopsys), Mentor EDA, and Verilog HDL.</p>

                            <p>Dr. Verma has actively contributed to the academic community, guiding seven Ph.D. students and supervising numerous M.Tech. and B.Tech. research projects. As a respected reviewer and editor, she has lent her expertise to reputable journals like IEEE Access, Circuits, Devices and Systems, Analog Integrated Circuits and Signal Processing, American Journal of Computation, Communication and Control, and Frontiers of Mechatronical Engineering. Dr. Preeti Verma’s dedication to teaching, coupled with her prolific research contributions, makes her a valuable asset to the academic and technological landscape.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- fifteen box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Arvind} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Arvind Kumar Shukla
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Computer Science)</h6>
                        <h6 class="text-light text-center">  From: Moradabad</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">

                            <p>Dr. Arvind Kumar Shukla is an esteemed Associate Professor at IFTM University, specializing in Wireless Ad-Hoc Networks and Machine Learning. With a Ph.D. from Banasthali University, he boasts a rich academic background, including a Master’s in Computer Applications. Dr. Shukla has garnered extensive professional experience, serving in various capacities at IFTM University and in the industry as a Software Engineer and Programmer.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Shukla is a prolific researcher with a plethora of publications in reputable journals and books, showcasing his expertise in the field. He actively engages in research supervision, academic committees, and editorial boards, demonstrating his commitment to advancing knowledge in Computer Science. His dedication and contributions have been recognized through several awards and honors, highlighting his significant impact on the field. Dr. Arvind Kumar Shukla is deeply passionate about academia, research, and the continuous evolution of technology in the realm of Computer Science.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>








                {/* <!-- sixteen box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Lalit} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Lalit Johari
                        </h5>
                        <h6 class="text-light text-center">( Ph.D. in Computer Science and Applications)</h6>
                        <h6 class="text-light text-center">  From: Rampur</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Lalit Johari, an esteemed Assistant Professor at IFTM University, Moradabad, boasts a rich 17-year career in education. With a Ph.D. in Mobile Ad-hoc Networks from IFTM University and a robust academic foundation comprising a B.Sc. and MCA, he is a distinguished expert in Machine Learning using Python and C/C++ programming.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Johari’s influential research has been published in prestigious journals and conferences, covering diverse topics from stock market predictions to smart routing systems in wireless sensor networks. His innovative prowess extends to securing multiple patents, demonstrating his commitment to advancing technology.</p>

                            <p>Beyond research, Dr. Johari actively contributes to academia as the Convener of the Online Education & LMS Cell, playing a pivotal role in shaping the educational landscape. Honored with the Young Researcher Award in 2022, he continues to steer the trajectory of artificial intelligence as a respected member of the editorial board for IIP Proceedings. Dr. Johari’s dedication, expertise, and passion for continuous learning mark him as a trailblazer in the field of computer science and education.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- seventeen box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Umesh} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Umesh Chand
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in History)</h6>
                        {/* <h6 class="text-light text-center">  From: Delhi</h6> */}
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">

                            <p>Dr. Umesh Chand is a highly esteemed academician with a profound educational background, holding an MA in History, LL.B, and a Doctorate in History with a focus on “Prachin Bharat Main Vigyan Evam Takniki: Ek Etihasik Adhhyan,” a comprehensive exploration of science and technology in ancient India. His research, centered on the advancements in these fields during ancient Bharat, reflects a deep commitment to unraveling historical dimensions.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Chand’s scholarly contributions extend beyond the academic realm, with his research papers published in esteemed peer-reviewed journals and various college publications. A seasoned presenter, he has actively participated in numerous national seminars, offering valuable insights into the rich scientific and technological heritage of ancient Bharat.</p>

                            <p>His wealth of knowledge and dedication make Dr. Umesh Chand an invaluable addition to any advisory panel. His unique perspective and research prowess contribute significantly to academic discourse, offering a nuanced understanding of the cultural and technological tapestry of ancient India. Dr. Chand’s expertise and commitment make him a guiding force for those navigating the complex intersections of history, science, and technology.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>


                {/* <!-- eightteen box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Manju} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Manju Rani
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Education )</h6>
                        <h6 class="text-light text-center">   From: Agra</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">


                            <p>Dr. Manju Rani, a highly accomplished educator with a Ph.D. in Education from Agra, holds various professional qualifications, including NET in Education from UGC-Delhi. With over 12 years of teaching experience, she currently serves as an Assistant Professor in the Faculty of Teacher Education at SMPGGPG College, Meerut.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Manju Rani's dynamic role extends to being the Nodal Officer of the Anti-ragging Cell, a member of the Proctorial Board, and Co-convener of the Eco Club. Driven by a commitment to education, she has delivered impactful lectures, created 62 original e-content pieces, and actively utilized diverse digital tools for online classes.</p>

                            <p>As a prolific researcher, Dr. Manju Rani has presented papers, published in peer-reviewed journals, and guided Ph.D. scholars. Recognized with numerous awards, she is a dedicated participant in social activities, emphasizing environmental conservation and community engagement. Dr. Manju Rani brings a wealth of expertise and commitment to our advisory panel.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>





                {/* <!-- nineteen box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Madupalli} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr Madupalli Suresh Kumar
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in English )</h6>
                        <h6 class="text-light text-center">  From: Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Madupalli Suresh Kumar, an esteemed academic figure with a Ph.D. in English from Andhra Pradesh, possesses a robust background in English literature and language studies. He has demonstrated unwavering commitment to education, extending beyond conventional teaching to include syllabus development and participation in university boards.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>As a seasoned researcher, Dr. M. Suresh Kumar has significantly contributed to the academic landscape, particularly in literary and linguistic domains. With a penchant for innovative teaching methodologies, he has actively shaped the learning environment. Known for his dedication to student success, Dr. M. Suresh Kumar has been instrumental in guiding students through examinations and research projects.</p>

                            <p>The breadth of experience and scholarly achievements make Dr. M. Suresh Kumar an exemplary mentor. His presence on the advisory panel ensures a wealth of knowledge, mentorship, and a commitment to academic excellence. Students under his guidance benefit from a holistic and contemporary education, reflecting Dr. M. Suresh Kumar's deep understanding of academia and unwavering support for student development.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>






                {/* <!-- twenty box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Iyer} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Saraswati Raju Iyer
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Psychology )</h6>
                        <h6 class="text-light text-center">From: Guntur,Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Saraswati Raju Iyer, an accomplished Professor of Sociology & Social Work with a Ph.D. in Psychology from Guntur, Andhra Pradesh, brings a wealth of expertise to our advisory panel. With over 23 years of Postgraduate teaching experience, she holds a D.Litt. (U.S.A) and a gold medal from Osmania University.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Prof. Iyer has made significant contributions, chairing 80 seminars, presenting 135 papers globally, and participating in 96 seminars, conferences, and workshops. Specializing in Social Welfare and Social Development, she has an impressive academic portfolio that includes 19 books, 30 journal editorial board memberships, and the development of 20 lessons for SWAYAM MOOCs.</p>

                            <p>Recognized with 56 National and International Awards, Dr. Saraswati Raju Iyer has led 32 research and consultancy projects, focusing on areas such as Psychological Counseling, Gender issues, HIV/AIDS, Marginalized communities, Child rights, and Disability. Her vast experience and diverse contributions make her a valuable addition to our advisory panel.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>


                {/* <!-- twenty one box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Sandeep} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Sandeep Kumar



                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Electronics Engineering)</h6>
                        <h6 class="text-light text-center"> From: Delhi</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Sandeep Kumar is currently serving as an Assistant Professor in the Department of Electronics & Communication Engineering at the National Institute of Technology, Delhi. He holds a Ph.D. in Electronics Engineering with a specialization in Signal Processing from IIT (ISM) Dhanbad. Dr. Kumar completed his M.Tech in Electronics & Communication Engineering and his B.Tech in Electronics & Instrumentation Engineering from the same institute.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>With a strong background in Signal Processing, Communication, and Instrumentation, Dr. Sandeep Kumar’s research interests include Signal Processing, Speech processing, Bio signal processing, Image Processing, Optical signal processing, and Real-time Signal Processing. He has a keen teaching interest in subjects like Digital Signal Processing, Signals & Systems, Digital Electronics, Electromagnetic Field Theory, Control System, and Speech Processing.</p>

                            <p>Apart from his academic achievements, Dr. Sandeep Kumar has been actively involved in various administrative roles within the institute, including Deputy Controller of Examination, Associate Chief Warden, and Associate Dean Student Welfare. He has also published numerous research papers in reputable journals and conferences, showcasing his expertise in the field of Electronics and Communication Engineering.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- twenty two box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5 pb-0 image text-center Card-1">
                        <img src={Jagan} alt="image-1" class="image mt-3  image-box" />
                        <h5 class="text-light text-center">Dr. B. Jagan Mohan Reddy

                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Chemistry)</h6>
                        <h6 class="text-light text-center">From: Guntur,Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Prof. Dr. B. Jagan Mohan Reddy is a standout scholar renowned for his expertise in Chemistry. Showcasing a Ph.D. in Chemistry and a prestigious Post-Doctoral Fellowship at POSTECH, South Korea, Prof. Reddy’s academic journey is nothing short of impressive. With nearly 28 years dedicated to academia, including a significant tenure at NANNAYA University, he has solidified his reputation as a leading authority in Synthetic Organic Chemistry.</p>
                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Throughout his career, Prof. Reddy has received numerous awards and recognitions for his contributions to the field. These accolades include the Young Scientist Award from IUPAC, the Best Teacher Award from the Andhra Pradesh State Government, and the Outstanding Faculty in Chemistry Award from VIFA International Organization. His research interests primarily focus on the synthesis of various compounds and their applications in different domains.</p>

                            <p>Apart from his academic achievements, Prof. Reddy has actively engaged in administrative roles at Adikavi Nannaya University, where he has served in various capacities, including as the Head of the Chemistry Department and as an Executive Council Member. His collaborations with esteemed scientists and memberships in prestigious organizations underscore his commitment to advancing the field of Chemistry. Prof. Reddy’s extensive publication record, which includes international journals and patents, further solidifies his reputation as a leading figure in the realm of Chemistry.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- twenty three box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Bandari} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Bandari Srilatha

                        </h5>
                        <h6 class="text-light text-center">(Ph.D in Education)</h6>
                        <h6 class="text-light text-center">From: Guntur,Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. B. Srilatha, a seasoned educator, brings over two decades of experience in the realm of education. Armed with a Ph.D., MA, M.Ed., and technical qualifications in computer applications and software engineering, she has left an indelible mark in the academic arena. Dr. Srilatha’s research prowess is evident through her ongoing and awarded projects, including guiding numerous M.Ed. projects. An active participant in seminars and conferences, she has presented 40 papers and publications, showcasing her dedication to knowledge dissemination.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>As an esteemed member of NCTE and a respected figure in academic circles, Dr. Srilatha has served as the Chairperson of the Board of Studies at Andhra Kesari University, demonstrating her leadership acumen. Her literary contributions are noteworthy, with 10 authored books and editorial roles in esteemed publications. Recognized with accolades such as the Dr. Abdul Kalam Excellence Award and the Best Teacher Award, Dr. Srilatha’s commitment to education is unwavering. Her impact extends beyond the classroom, as she engages in academic extension activities and plays a pivotal role in shaping the educational landscape of India.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- twenty four box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Laxami} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. V. Vijay Lakshmi

                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Intellectual Property Rights) </h6>
                        <h6 class="text-light text-center">From: Visakhapatnam, Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. V. Vijay Lakshmi is a distinguished legal scholar and educator, currently serving as the Principal of Dr. B.R. Ambedkar College of Law at Andhra University. With over 30 years of teaching experience, she holds a Ph.D. in Intellectual Property Rights with a focus on Copyright Laws. Prof. Vijay Lakshmi is a recipient of prestigious awards such as the Dr. Sarvepalli Radhakrishnan Award for the ‘Best Academician of the Year’ and the Peri Narayana Gold Medal Award for Best thesis at Andhra University.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>In addition to her academic achievements, Prof. Vijay Lakshmi has demonstrated exemplary leadership as the Chairperson of the Board of Studies in Law at Andhra University and Krishna University. She has held various administrative roles, including Assistant Principal, Director of the Centre for Alluri Sitaramaraju History and Tribal Studies, and Convener of Women Grievance Cell at Andhra University. She actively contributes to the academic community through her involvement in research, with numerous papers published and presented at national and international conferences.</p>

                            <h2>Areas of Expertise:</h2>
                            <p>Prof. Vijay Lakshmi’s expertise lies in areas such as Intellectual Property Laws, Corporate Finance, Law of Contracts, and Company Law. She has guided numerous Ph.D. and LL.M. students, published a book on Arbitration, and actively participated in workshops, seminars, and conferences as a keynote speaker and resource person. Her dedication to legal education and commitment to academic excellence make her a valuable asset to the field of law and academia.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- twenty five box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Thana} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light text-center">Dr. S. Thanalakshmi
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Physical Science) </h6>
                        <h6 class="text-light text-center">From: Tamil Nadu</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. S. Thanalakshmi is a distinguished Associate Professor in the Department of Physical Science Education at Govt. College of Education, Vellore. Possessing a diverse academic background with degrees such as M.Sc., M.Ed., M.Phil., Ph.D., and PGDWS, SLET, she has 19 years of teaching experience and 6 years of research expertise in areas like Physical Science, Psychology, and Research Methodology.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Her professional journey is characterized by notable accomplishments, including involvement in 63 symposiums/seminars/conferences, 9 peer-reviewed publications, and contributions to 9 chapters in editorial books. Dr. Thanalakshmi has participated in 21 Faculty Development Programs and played pivotal roles in organizing various academic events. She has received esteemed accolades such as the Gnana Guru Award 2020 and the Sir C.V. Raman Scientists International Award 2023.</p>

                            <h2>Inspirational Journey and Advocacy:</h2>
                            <p>In addition to her academic pursuits, Dr. Thanalakshmi’s personal story showcases a resilient spirit forged in her early days in a village with limited educational opportunities. She underscores the transformative influence of education in molding one’s life and prioritizes hard work and perseverance. Her steadfast commitment to success, along with her advocacy for universal education, stands as a source of inspiration for aspiring individuals.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- twenty six box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Anita} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Anita Goswami
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Asceticism in Ancient India and Ancient Greece) </h6>
                        <h6 class="text-light text-center">From: Meerut</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Anita Goswami, a distinguished academician hailing from Meerut, holds a Ph.D. from M.J.P. Rohilkhand University and is a Gold Medalist in both MA and BA. She has established herself as a prominent figure in the field of education. Her research topic, “Asceticism in Ancient India and Ancient Greece: A Comparative Study,” conducted under the guidance of Prof. Udai Prakash Arora from JNU, showcases her scholarly prowess.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Goswami has a rich teaching experience, serving as a Professor at SMP Govt. PG College, Meerut, and making significant contributions to the academic community through publications and awards. She actively participates in community welfare initiatives, providing free education, scholarships, and organizing enlightening webinars for students. Prof. Goswami’s dedication to education is evident through her innovative contributions, extensive work in implementing new education policies, and mentoring numerous Ph.D. scholars with both awarded and submitted theses.</p>

                            <h2>Commitment to Academia and Social Welfare:</h2>
                            <p>Dr. Anita Goswami’s multifaceted approach towards academia and social welfare reflects her commitment to fostering knowledge and empowerment. Her active involvement in various educational and community initiatives demonstrates her passion for making a positive impact through education and social welfare.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- twenty seven box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Bonthu} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Bonthu Kotaiah



                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Information Technology)</h6>
                        <h6 class="text-light text-center"> From: Vizianagaram</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Bonthu Kotaiah is a dynamic Associate Professor at the Central Tribal University of Andhra Pradesh, with a rich background in computer science and information technology. With almost a decade of experience in teaching and research, he is a visionary in web application design and development, specializing in performance tuning and software for the healthcare sector. Dr. Kotaiah holds an M. Phil. in Computer Science, focusing on risk-taking behavior in IT platform migration decisions.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Renowned for his academic prowess, Dr. Kotaiah has presented groundbreaking research papers at prestigious international conferences, earning accolades such as the Best Paper Award at the International Conference on Soft Computing and Software Engineering. His expertise extends to specialized training as a Flex Developer and successful projects in information security education awareness.</p>

                            <h2>Contributions to Education and Academia:</h2>
                            <p>A dedicated educator, Dr. Kotaiah has made a lasting impact as an Assistant Professor at Maulana Azad National Urdu University and currently serves as an Associate Professor at CTUAP, Vizianagaram. With a vision to revolutionize academia-industry collaborations, student placements, and research publications, Dr. Kotaiah’s commitment to excellence and passion for teaching make him a standout figure in the academic realm.</p>

                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- twenty eight box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Parul} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Parul Malik
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Education) </h6>
                        <h6 class="text-light text-center">From: Meerut</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Parul Malik currently serves as an Assistant Professor in the B.Ed. Department at Shaheed Mangal Pandey Govt. Girls P.G. College in Meerut, Uttar Pradesh. She holds a Ph.D. in Education from Ch. Charan Singh University, Meerut. Dr. Malik has a strong academic background with qualifications including a JRF and NET. Her areas of specialization include Educational Measurement and Evaluation, as well as Research Methodology.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>With over a decade of teaching experience, Dr. Malik has held various positions such as Principal at Shivalik Institute of Education in Saharanpur and HOD in the B.Ed. Department at the same institute. She has actively participated in orientation programs, refresher courses, and workshops at reputed institutions like Jamia Millia Islamia and Aligarh Muslim University.</p>

                            <h2>Research and Academic Achievements:</h2>
                            <p>Dr. Malik has an impressive publication record with numerous papers in national and international journals, as well as contributions to edited books. She has supervised several Ph.D. theses and has been recognized for her academic achievements, including being the highest scorer in M.A. Economics at Ch. Charan Singh University. Dr. Malik’s dedication to academia and research is evident through her active participation in conferences, seminars, and workshops both as a participant and as an organizer.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- twenty nine box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Krishna} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. S. Lokeswara Bala Krishna
                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Biotechnology)

                        </h6>
                        <h6 class="text-light text-center">From: Kasaragod, Kerala</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. S. Lokeswara Bala Krishna is an accomplished Assistant Professor in the Department of Biochemistry and Molecular Biology at the Central University of Kerala. He holds a Ph.D. in Biotechnology from the University of Hyderabad and has a strong academic background with degrees in Botany, Zoology & Chemistry (B.Sc.) from Kakatiya University and Genetics (M.Sc.) from Osmania University.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Bala Krishna has made significant contributions to the field of molecular biology and biochemistry through his research and publications. His work focuses on areas such as Sickle cell disease, cervical cancer, HIV-1 replication, and human papillomavirus (HPV) genotypes. He has been involved in various research projects, including those funded by ICMR and MPCoST, aimed at understanding and addressing health challenges in vulnerable populations.</p>

                            <h2>Research Papers and Expertise:</h2>
                            <p>With a keen interest in infectious diseases and genetic mechanisms, Dr. Bala Krishna has authored several research papers in reputable journals, showcasing his expertise and dedication to advancing scientific knowledge. His research on topics like HIV-1 reverse transcription, topoisomerase II gene delivery, and cell surface protein expression has been well-received in the scientific community.</p>

                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>






                {/* <!-- thirty --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Anirudh} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Anirudh Kumar
                        </h5>
                        <h6 class="text-light text-center">( Ph.D. in Plant Sciences) </h6>
                        <h6 class="text-light text-center">From: Hyderabad</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Anirudh Kumar is an accomplished researcher and educator in the field of plant sciences, with a diverse background in academia and research. Currently serving as an Associate Professor at the Central Tribal University of Andhra Pradesh in Vizianagaram, he has a rich work experience that includes roles as an Assistant Professor at the Indira Gandhi National Tribal University in Amarkantak, Madhya Pradesh. Dr. Anirudh has also held prestigious research positions such as a PBC-Postdoc Fellow at the Agriculture Research Organization in Israel, a DBT Research Associate at the CSIR-Centre for Cellular and Molecular Biology in Hyderabad, and a Research Associate at the International Crops Research Institute for the Semi-Arid Tropics in India.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Anirudh’s educational journey includes a Ph.D. in Plant Sciences from the University of Hyderabad and a Master’s degree in the same field from the same institution. He also holds a Bachelor’s degree in Science from Magadh University in Bihar. Dr. Anirudh has contributed significantly to the scientific community through his research work, evident from his numerous book chapters on topics ranging from plant defense systems to genome editing technologies for plant improvement. His expertise in plant metabolomics, stress management, and crop improvement has been acknowledged through his publications and research collaborations, making him a valuable asset in the field of agricultural biotechnology and plant sciences.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- thirty one box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Shivom} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Shivom Sharma

                        </h5>
                        <h6 class="text-light text-center">( Ph.D. in Mathematics)</h6>
                        <h6 class="text-light text-center"> From: Rampur, Uttar Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Shivom Sharma is an Assistant Professor of Mathematics at S.G.T.B.S. Govt. P.G. College in Bilaspur, Rampur (U.P.). He holds a Ph.D. in Mathematics and has extensive teaching experience at both undergraduate and postgraduate levels. Dr. Sharma is a subject expert in the National Research Journal of Humanities & Social Sciences, with several papers published in prestigious journals and edited books.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Apart from his academic achievements, Dr. Sharma has actively participated in various seminars and conferences focusing on topics such as women empowerment, higher education, environmental sensitivity, and economic analysis. He has also co-written published books on trigonometry, modern algebra, vector geometry, and calculus. Dr. Sharma has a strong commitment to professional development, as evidenced by his attendance at numerous orientation programs, refresher courses, and webinars.</p>

                            <h2>Additional Responsibilities and Contributions:</h2>
                            <p>Dr. Shivom Sharma has taken on additional responsibilities as the Chief Proctor and N.S.S. Programme Officer at Govt. P.G. College Bilaspur (Rampur). Overall, he is a dedicated educator, researcher, and academic professional who has made significant contributions to the field of mathematics and higher education in India.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>


                {/* <!-- thirty two box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Kumari} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. M.S. Chaitanya Kumari


                        </h5>
                        <h6 class="text-light text-center">( Ph.D. in Extension Education (Home Science))</h6>
                        <h6 class="text-light text-center">  From: Guntur ,Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. M.S. Chaitanya Kumari holds a Ph.D. in Extension Education (Home Science) from Guntur, Andhra Pradesh. She is currently serving as the Associate Dean at the College of Community Science, ANGRAU in Guntur, Andhra Pradesh. With 23 years of experience in teaching, research, and extension activities, Dr. Kumari specializes in e-Science Communication, Indigenous Technical Knowledge, and Entrepreneurship Development.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Kumari has received notable honors such as the State Meritorious Teacher award and the Indo Asian – David Berlo Distinguished Scientist Award. She has made significant contributions in various projects, publications, and training programs, focusing on knowledge management, multimedia production, and value addition in agriculture. Her expertise extends to project leadership, with initiatives on farm family database development, tribal livelihood empowerment, and integrated farming systems.</p>

                            <h2>Professional Associations and Publications:</h2>
                            <p>As a member of prestigious professional associations and with a strong publication record, Dr. M.S. Chaitanya Kumari continues to make a lasting impact in the fields of extension education and community science. She has played key roles in designing syllabi at both undergraduate and postgraduate levels, as well as coordinating institutional programs and student initiatives.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>



                {/* <!-- thirty three box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Ipilli} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Tarakeswara Rao Ippili


                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Journalism and Mass Communication) </h6>
                        <h6 class="text-light text-center"> From: Vizianagaram, Andhra Pradesh</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Tarakeswara Rao Ippili is a distinguished Assistant Professor at the Central Tribal University of Andhra Pradesh with expertise in Journalism and Mass Communication. He holds a Ph.D. from Acharya Nagarjuna University and his scholarly pursuits encompass Contemporary Journalism, Development Communication, and Global Media Research.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Known for his prolific contributions to academia, Dr. Ippili has a rich tapestry of research publications, seminars, and workshops that showcase his profound understanding of media studies. His dynamic presence in the academic sphere is characterized by a relentless pursuit of knowledge and a commitment to excellence.</p>

                            <h2>Professional Engagements and Associations:</h2>
                            <p>As a founding member of the Department of Journalism and Mass Communication at esteemed institutions, including the Central Tribal University of Andhra Pradesh, Dr. Ippili has played a pivotal role in shaping the academic landscape. His membership in prestigious associations underscores his dedication to advancing the field of media communication.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>





                {/* <!-- thirty four box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Syed} alt="image-1" class="image mt-3  image-box" />
                        <h5 class="text-light mt-1 text-center">Dr. Syed Raashid Andrabi

                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Anesthesia) </h6>
                        <h6 class="text-light text-center">From: Ratnipora Pulwama in Jammu & Kashmir</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Syed Raashid Andrabi is a dedicated professional with a strong background in the field of anesthesia and critical care. He completed his Bachelor’s degree in Operation Theater Technology from Maharishi Markandeshwar University, laying a solid foundation for his future career. He then pursued a Master’s degree in Science (Anesthesia) from NIMS University Jaipur, further deepening his expertise in the field.</p>
                            <p>With a passion for research and academia, Dr. Syed embarked on a PhD in Allied Health Science with a specialization in Anesthesia and Critical Care from NIMS University Jaipur. His research interests include comparing the efficacy of different induction agents in maintaining hemodynamic stability during elective surgery under general anesthesia. He has actively participated in various projects and publications, presenting his work at national and international conferences, showcasing his growing expertise.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <p>Dr. Syed’s leadership skills are evident through his role as an Assistant Professor and Head of the Department of Anesthesia at Centurion University of Technology and Management. In this capacity, he has supervised numerous students during their internships, guiding them through their practical training and academic growth. His efforts have been instrumental in obtaining council approval for Allied Health Science programs, reflecting his commitment to enhancing educational standards.</p>
                            <p>Recognized for his contributions to the field, Dr. Syed was selected for the InSc Young Researcher Award in 2021. He is also an active member of the Society for Technology in Anesthesia, staying abreast of the latest advancements and innovations in his field. Dr. Syed’s commitment to excellence, innovative thinking, and service-focused attitude make him a valuable asset in healthcare and academia.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>




                {/* <!-- thirty five box --> */}
                <div class="row mt-5" id="Dr. Reddy" subject="Management-Corporate Social Responsibility">

                    {/* <!-- Card-1 --> */}
                    <div class="col-sm-4 mt-5  image text-center Card-1">
                        <img src={Garg} alt="image-1" class="image mt-5  image-box" />
                        <h5 class="text-light mt-2 text-center">Dr. Ratnavali Garg


                        </h5>
                        <h6 class="text-light text-center">(Ph.D. in Sociology) </h6>
                        <h6 class="text-light text-center">From: Bareilly</h6>
                    </div>
                    {/* <!-- Card-1 ended --> */}

                    {/* <!-- Card-2 --> */}
                    <div class="col-sm-4 mt-5 py-4 image card2 second_box scroll">
                        <h4 class="mt-3 mx-4 colour"><strong>Early Career: </strong></h4>
                        <div class="mx-4 colour">
                            <p>Dr. Ratnavali Garg, an esteemed academician, is a beacon of knowledge and inspiration in the realm of Sociology. With a Ph.D. in Sociology from MJP RU Bareilly, she embarked on her scholarly journey, specializing in the introduction of Sociology. Dr. Ratnavali’s academic prowess is underscored by her extensive research contributions, delving into diverse topics such as women empowerment, societal dynamics, and cultural influences.</p>

                        </div>

                    </div>
                    {/* <!-- Card-2 ended--> */}

                    {/* <!-- Card-3 --> */}
                    <div class="col-sm-4 mt-5 py-4 image third_box scroll">
                        <h4 class="mt-3 mx-4 text-dark colour"><strong>Experience:</strong></h4>
                        <div class="mx-4 text-dark colour">
                            <h2>Experience</h2>
                            <p>As an Assistant Professor at Rama Jain Kanya Mahavidyalaya, Najibabad, Dr. Ratnavali has not only imparted wisdom to countless students but has also actively participated in seminars and conferences on pressing societal issues. Her insightful presentations on topics like entrepreneurship, media impact, and environmental conservation have garnered acclaim on national platforms.</p>
                            <p>Beyond her academic endeavors, Dr. Ratnavali is a compassionate individual dedicated to societal welfare. Her commitment to social causes is reflected in her research on juvenile delinquency, family dynamics, and economic disparities. Dr. Ratnavali’s multifaceted approach to academia, coupled with her unwavering dedication to social change, positions her as a trailblazer in the academic community.</p>
                            <p>In essence, Dr. Ratnavali Garg epitomizes intellectual excellence, social consciousness, and academic leadership, making her a formidable asset to any advisory panel seeking profound insights and innovative perspectives.</p>


                        </div>
                    </div>
                    {/* <!-- Card-3 ended--> */}







                </div>










            </div>
            
</section>

        </>
    );
}

export default Advisor;
{/* <!--  eighth box ended --> */ }

{/* <!-- nineth Box --> */ }
{/* <!-- <div class="row mt-5" id="Dr. Santosh Kumar" subject="Antenna Design and Wireless Communication"> --> */ }

{/* <!-- Card-1 --> */ }





{/* </div>  */ }
{/* <!-- Boxes ended --> */ }
