import React from "react"
import Back from "../comon/back/Back"
import ResourceCard from "../resource/ResourceCard"
import "./resource.css"
import Faq from "./Faq" 

import { FaWhatsapp } from "react-icons/fa"
import { Link } from "react-router-dom"




const Resource = () => {

 
  return (
    <>
   
    

   
            <section className='back'>
            <h1>Resources</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style= {{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
            <div className='margin'></div>

      <section className='resource padding'>
        <div className='container mb-5'>
          <ResourceCard />
        </div>
        <Faq/>
      </section>
   
    
    </>
  )
}

export default Resource
