import React from "react"
import "./services.css"
import { online } from "../dummydata"
import Heading from "../comon/heading/Heading"
import Assignment from "../../../../src/privateImages/assignment.png"
import Research from "../../../../src/privateImages/research.png"
import Resume from "../../../../src/privateImages/resume.png"
import Bookpublish from "../../../../src/privateImages/bookpublish.png"
import Content from "../../../../src/privateImages/content.png"
import Etutorial from "../../../../src/privateImages/e-tutorial.png"
import Journal from "../../../../src/privateImages/journal.png"
import Webdesign from "../../../../src/privateImages/web-design.png"
import Smm from "../../../../src/privateImages/smm.png"
import Sop from "../../../../src/privateImages/sop.png"
import Turtin from "../../../../src/privateImages/turtin.png"
import Video from "../../../../src/privateImages/video.png"
import { Link } from "react-router-dom";
import GoogleAnalytics from "../GoggleAnalytics"

const OnlineServices = () => {
  return (
    <>
    <GoogleAnalytics/>
      <section className='online'>
        <div className='container' style={{ marginTop: "10%" }}>
          <Heading subtitle='Unlocking Possibilities:' title='Explore the Depths of Our Comprehensive Offerings' />
          <div className='content grid3 pb-5'>
            <div className='box'>
              <div className='img'>
                <img src={Assignment} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/aws' style={{textDecoration:"none"}}><h1>Assignment help (for Graduation & Post-Graduation)</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Research} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/research' style={{textDecoration:"none"}}><h1>Research writing help (For Ph.D students)</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Resume} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/resume' style={{textDecoration:"none"}}><h1>Resume Writing</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Sop} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/sop' style={{textDecoration:"none"}}><h1>Cover page and SOP writing</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Webdesign} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/web' style={{textDecoration:"none"}}><h1>Website Designing & Maintenance</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Smm} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/smm' style={{textDecoration:"none"}}><h1>Social Media Marketing</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Video} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/videolecture' style={{textDecoration:"none"}}><h1>Video Lectures (E-Tutorials service)</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Turtin} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/turtin' style={{textDecoration:"none"}}><h1>Turnitin Plagiarism Report Service</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Journal} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/journal' style={{textDecoration:"none"}}><h1>Journal Publication</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Bookpublish} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/book' style={{textDecoration:"none"}}><h1>Book writing & Publication</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Content} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/content' style={{textDecoration:"none"}}><h1>Content Writting</h1></Link>
            </div>
            <div className='box'>
              <div className='img'>
                <img src={Video} alt="image" height='100%' width='100%' />
              </div>
              <Link to='/videoblog' style={{textDecoration:"none"}}><h1>Video and blog podcast (Interview)</h1></Link>
            </div>


          </div>
        </div>
      </section>
    </>
  )
}

export default OnlineServices
