import React from "react";
import Team1 from "../../../privateImages/Team/TEAM1.png"
import Team2 from "../../../privateImages/Team/TEAM2.png"
import Team3 from "../../../privateImages/Team/TEAM3.png"
import Team4 from "../../../privateImages/Team/TEAM4.png"
import Team5 from "../../../privateImages/Team/TEAM5.png"
import Team7 from "../../../privateImages/Team/TEAM7.png"
import Team11 from "../../../privateImages/Team/TEAM11.png"
import Team12 from "../../../privateImages/Team/TEAM12.png"
import Header from "../comon/header/Header";
import Harsirat from "../../../privateImages/Team/harsirat.png"
import Pratibha from "../../../privateImages/Team/pratibha.png"
import Mugdha from "../../../privateImages/Team/mugdha.png"

import Shubham from "../../../privateImages/Team/shubham.png"
import Ankit from "../../../privateImages/Team/ankit.png"
import Divya from "../../../privateImages/Team/divya.png"
import Vikas from "../../../privateImages/Team/vikas.png"
import Himanshu from "../../../privateImages/Team/himanshu.png"
import Bhavna from "../../../privateImages/Team/bhavna.png"
import './Team.css'
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

import  { lazy, Suspense } from 'react';


const Team = () => {
 

    return (
        <>
   

  
   
            <section className='back'>
            <h1>"Our Team: the Creative Force"</h1>
            <h4>At DfreeNovelish, our team comprises dedicated professionals with expertise in education, web development, and social media marketing. United by a commitment to your success, our educational specialists offer personalized learning strategies, while our digital marketing experts and web developers create innovative campaigns and cutting-edge web solutions to enhance your online presence and drive growth.</h4>
            </section>
              <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin'></div>
<div className="container team-contain">
            <div class="main">


                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team2} />
                    </div>
                    <div class="caption">
                        <h3>Akshay Koushik</h3>
                        <p>Advisor</p>

                    </div>
                </div>

                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team5} />
                    </div>
                    <div class="caption">
                        <h3>Mukul Bansal</h3>
                        <p>Company Secretary</p>

                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team11} />
                    </div>
                    <div class="caption">
                        <h3>Ashish Sharma</h3>
                        <p>Chartered Accountant</p>
                    </div>
                </div>


                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team7} />
                    </div>
                    <div class="caption">
                        <h3>Anushka Agrawal</h3>
                        <p>Web Developer</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Pratibha} />
                    </div>
                    <div class="caption">
                        <h3>Pratibha</h3>
                        <p>HR </p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Harsirat} />
                    </div>
                    <div class="caption">
                        <h3>Harsirat Kaur</h3>
                        <p>HR</p>
                    </div>
                </div>

              
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Bhavna} />
                    </div>
                    <div class="caption">
                        <h3>Bhavna</h3>
                        <p>Web Developer</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team12} />
                    </div>
                    <div class="caption">
                        <h3>Swapndeep Nandi</h3>
                        <p>Web Developer</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team3} />
                    </div>
                    <div class="caption">
                        <h3>Megha Agarwal</h3>
                        <p>Research Associate</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Team4} />
                    </div>
                    <div class="caption">
                        <h3>Kritika</h3>
                        <p>Research Associate</p>
                    </div>
                </div>
                
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Mugdha} />
                    </div>
                    <div class="caption">
                        <h3>Mugdha</h3>
                        <p>Web Developer</p>
                    </div>
                </div>

                <div class="profile-card">
                    <div class="team-img">
                        <img src={Shubham} />
                    </div>
                    <div class="caption ">
                        <h3>Shubham Singh</h3>
                        <p>Research Associate</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Himanshu} />
                    </div>
                    <div class="caption">
                        <h3>Himanshu Sharma</h3>
                        <p>BDE</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Ankit} />
                    </div>
                    <div class="caption">
                        <h3>Ankit Jaiswal</h3>
                        <p>Research Associate</p>
                    </div>
                </div>
           
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Divya} />
                    </div>
                    <div class="caption">
                        <h3>Divya</h3>
                        <p>BDE</p>
                    </div>
                </div>
                <div class="profile-card">
                    <div class="team-img">
                        <img src={Vikas} />
                    </div>
                    <div class="caption">
                        <h3>Vikas</h3>
                        <p>Research Associate</p>
                    </div>
                </div>

            </div>
 </div>

        </>
    )
}

export default Team;