import React from 'react';
// import './service.css'; 
import "../AWS/service.css"
import Marquee from "react-fast-marquee";


  const TurtinList = () => {
    return (
      <div className="flatlist-container awsassistpara">
        <h1 className='AWSListHead'>Our Range of Services</h1>
        <Marquee>
        • Plagiarism Detection&nbsp;&nbsp;&nbsp;&nbsp;
 • Originality Check&nbsp;&nbsp;&nbsp;&nbsp;
 • Quality Assurance&nbsp;&nbsp;&nbsp;&nbsp;
 • Timely Delivery&nbsp;&nbsp;&nbsp;&nbsp;
 • Confidentiality&nbsp;&nbsp;&nbsp;&nbsp;
 • Plagiarism Detection&nbsp;&nbsp;&nbsp;&nbsp;
 • Originality Check&nbsp;&nbsp;&nbsp;&nbsp;
 • Quality Assurance&nbsp;&nbsp;&nbsp;&nbsp;
 • Timely Delivery&nbsp;&nbsp;&nbsp;&nbsp;
 • Confidentiality&nbsp;&nbsp;&nbsp;&nbsp;
 </Marquee>
        </div>
    
    );
  }


export default TurtinList;