import React, { useState } from "react";
import './research.css'
import Back from "../../comon/back/Back"
import ResearchWrittingList from "./ResearchWrittingList";
import ResearchImage from "../../../../privateImages/service-file/research.png"

import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
// import AboutList from './AboutList';
import MyModal from "../../../Form/SoForm";




const ResearchWritting = () => {


    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
    return (
      setShowModal (false)
    )
  }
    return (
        <>
     



  
            <section className='back'>
            <h1 className="mt-5 text-center">Research Writing Help for Ph.D Students</h1>
            </section>
            <Link to="https://wa.me/917302344754/?text=Hello,%20got%20the%20info%20from%20your%20website,%20I%20have%20some%20query.%20 " className="fixed"
             style={{textDecoration:"none", position:"fixed", backgroundColor:"#25D366", paddingRight:"10px", paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", borderRadius:"50%", marginTop:"20%", float:"right"}}>  
            <FaWhatsapp name="whatsapp" size={40} color="white" className="fixed-icon" /></Link>
    
      <div className='margin'></div>
            <div className="container-fluid our-container mt-5">
               
                <div>
                    <h1 className="awshead text-light mt-5">Research Writing Help for PhD Students</h1>
                    <p className="awspara text-light">At DfreeNovelish, we understand the unique challenges faced by PhD students when it comes to conducting original research and writing a dissertation.
                        That's why we offer specialized research writing help tailored to meet the specific requirements of PhD-level studies.</p>
                </div>
                <div className="assist">

                    <div className="awsassistpara">

                        <h1 className="awsassist">How We Can Assist You</h1>
                        <p>Our team includes PhD-qualified researchers and writers with extensive experience in conducting original research and writing dissertations. Whether you need assistance with formulating research questions,
                            designing research methodologies, analyzing data, or writing up your findings, we have the expertise to help.</p>
                    </div>
                    <div>
                        <img src={ResearchImage} className="awsimage awsassistpara" alt="Image" height="100%" width="100%" />
                    </div>
                </div>



                {/* ----------------------------------------------------What Sets Us Apar--------------------------------------- */}
                <section>
                    <div>
                    <h1 className="awssets mb-5 text-light">What Sets Us Apart</h1>
                    <div className="researchcards text-light">

                        <div class="researchcard mt-2">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>Expertise in Research Writing</h3>
                                <p>Our team has a deep understanding of the research process and is
                                    committed to delivering high-quality work that meets the highest academic standards.</p>
                            </div>
                        </div>
                        <div class="researchcard mt-2">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>Tailored Support:</h3>
                                <p>We take a personalized approach to each project,
                                    working closely with you to understand your specific research interests and requirements.</p>
                            </div>
                        </div>
                        <div class="researchcard mt-2">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>Quality Assurance</h3>
                                <p>Every research project is conducted with meticulous
                                    attention to detail, ensuring accuracy, coherence, and originality throughout.</p>
                            </div>
                        </div>
                    </div>

                    <div className="researchcards secondresearch ">

                        <div class="researchcard">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>Plagiarism Check</h3>
                                <p>To ensure the authenticity of your work, we provide
                                    Turnitin plagiarism and AI reports, guaranteeing originality and integrity.</p>
                            </div>
                        </div>
                        <div class="researchcard mt-2">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>Timely Delivery</h3>
                                <p>We prioritize PhD deadlines, aiming for timely research completion to meet academic milestones and advance towards your degree efficiently.
                                </p>
                            </div>
                        </div>
                        <div class="researchcard mt-2">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="researchcard-inner text-light">
                                <h3>24/7 Support</h3>
                                <p>Our support team is available 24/7 to assist with orders, writer communication, and research progress tracking, ensuring all needs are met.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </section>
                {/* -----------------------------------------------What Sets Us Apar ENDED----------------------------------- */}
                <div>
                    <ResearchWrittingList />
                </div>
                <div>
                    <h1 className="awssets text-light">Get Started Today</h1>
                    <p className="awspara text-light">Don't let the challenges of PhD-level research hold you back from achieving your academic goals. Our specialized research writing help is designed to support you every step of the way.
                        Contact us today to discuss your requirements and take the first step towards academic success.</p>
                        <button className="h-50 w-25 fs-2 mb-5 get-started"  style={{marginLeft:"37%"}}   onClick={() => setShowModal(true)}>
                      Get Started
              </button>          {showModal && <MyModal closeModal={closeModal} />}
                </div>
            </div>

        </>

    )
}
export default ResearchWritting;