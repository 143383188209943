import React,  { useState } from "react"
import Heading from "../comon/heading/Heading"
import ProjectCard from "../allServices/ServicesCard"
import Image from "../../../privateImages/home-footer.png"
import MyModal from "../../Form/SoForm";
import { FaAlignJustify } from "react-icons/fa";



const Hpricing = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
  return (
    setShowModal (false)
  )
}
  return (
    
    <>
      <section className='hprice padding'>
        <Heading subtitle='Ready to Get Started?' title='Request Your Quote Today!' />
        <div className='price container price-background d-flex flex-column justify-center'>
   
       <button onClick={() => setShowModal(true)} className="primary-btn localbutton  fs-5 mx-auto  text-center justify-center -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)  " style={{position:"relative"}}  >Find Your Fit</button>
                           {showModal && <MyModal closeModal={closeModal}/>}
    
      
       <div>
       <img src={Image} className="home-image"/>
       </div>
      
        </div>
      </section>
    </>
  )
}

export default Hpricing;