// import React from "react";
// import "./form.css";
// import { useEffect } from "react";

// const ResourceForm = ({ closeModal }) => {
//     useEffect(() => {
//         document.body.style.overflow = "hidden";
//         return () => {
//             document.body.style.overflow = "scroll";
//         }
//     }, []);
//     return (
//         <>
//             <div className="modal-wrapper" onClick={closeModal}></div>
//             <div className="modal-container">
//                 <h1>Register Now</h1>
//                 <form>
//                     <div className="row">
//                         <div className="col-sm-6">
//                             <label>Name</label>
//                             <input type="text" />
//                         </div>
//                         <div className="col-sm-6">
//                             <label>Your email</label>
//                             <input type="email"></input>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-sm-6">
//                             <label>Phone</label>
//                             <input type="number" className="py-2" />
//                         </div>
//                         <div className="col-sm-6">
//                             <label>Subject</label>
//                             <input type="text" />
//                         </div>
//                     </div>
                
//                 </form>
//                 <button className="modal-btn" onClick={closeModal}>Send</button>
//             </div>
//         </>
//     )
// }
// export default ResourceForm;






import React, { useState } from "react";
import "./form.css";
import { useEffect } from "react";

const ResourceForm = ({ closeModal }) => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can add your form submission logic

        // Show alert message
        alert("Thanks for registration!");
        setFormSubmitted(true);
    }

    return (
        <>
            <div className="modal-wrapper" onClick={closeModal}></div>
            <div className="modal-container">
           
                {formSubmitted ? (
                    
                    <p>Thanks for your response. We will share 5 research topic on your email within
                        24 hours or soon!</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                        <h1>Register Now</h1>
                            <div className="col-sm-6">
                                <label>Name</label>
                                <input type="text" />
                            </div>
                            <div className="col-sm-6">
                                <label>Your email</label>
                                <input type="email"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Phone</label>
                                <input type="text" ></input>
                            </div>
                            <div className="col-sm-6">
                                <label>Subject</label>
                                <input type="text" />
                            </div>
                        </div>
                        <button className="modal-btn" type="submit">Send</button>
                    </form>
                )}
            </div>
        </>
    )
}
export default ResourceForm;